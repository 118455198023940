<script setup>
import { computed } from 'vue'

import { useStore } from 'vuex'
import FormRadio from '../Atoms/FormRadio.vue';
import IconBase from '../icon/IconBase.vue';
import IconInfoSmall from '../icon/IconInfoSmall.vue';

const props = defineProps({
})

const store = useStore()

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])
const hasPlayoutAdvancedSettings = computed(() => store.getters['User/hasPlayoutAdvancedSettings'])
const hasPlayoutSettingsFps = computed(() => store.getters['User/hasPlayoutSettingsFps'])
const hasPlayoutSettingsResolution = computed(() => store.getters['User/hasPlayoutSettingsResolution'])

const fpsComputed = computed({
  get() {
    return playoutConfig.value.settings.fps
      ? playoutConfig.value.settings.fps
      : null
  },
  set(fps) {
    store.dispatch('Playout/setAdvancedSettings', { fps })
  }
})

const resolutionComputed = computed({
  get() {
    return playoutConfig.value.settings.resolution
      ? playoutConfig.value.settings.resolution
      : null
  },
  set(resolution) {
    console.log(resolution);
    store.dispatch('Playout/setAdvancedSettings', { resolution })
  }
})

</script>

<template>
  <section v-if="hasPlayoutAdvancedSettings">
    <h4 class="text-sm text-surface-8" :class="{ 'opacity-50': !hasPlayoutSettingsFps }">FPS</h4>
    <div class="flex gap-x-4 mb-2" :class="{ 'opacity-50': !hasPlayoutSettingsFps }">
      <FormRadio class="m-0" :disabled="!hasPlayoutSettingsFps" label="25" v-model="fpsComputed" :inputValue="25" />
      <FormRadio class="m-0" :disabled="!hasPlayoutSettingsFps" label="30" v-model="fpsComputed" :inputValue="30" />
      <FormRadio class="m-0" :disabled="!hasPlayoutSettingsFps" label="50" v-model="fpsComputed" :inputValue="50" />
      <FormRadio class="m-0" :disabled="!hasPlayoutSettingsFps" label="60" v-model="fpsComputed" :inputValue="60" />
    </div>
    <div v-if="!hasPlayoutSettingsFps" class="flex rounded-md bg-surface-2 text-surface-8 text-tiny p-2 mb-2">
      <icon-base class="mr-2 shrink-0 mt-px" fill="none">
        <icon-info-small />
      </icon-base>
      <p class="mb-0"><a target="_blank" class="text-white underline" href="/app/subscribe">Upgrade</a> plan to enable FPS.
      </p>
    </div>
    <h4 class="text-sm text-surface-8" :class="{ 'opacity-50': !hasPlayoutSettingsResolution }">Resolution</h4>
    <div class="flex gap-x-4" :class="{ 'opacity-50': !hasPlayoutSettingsResolution }">
      <FormRadio class="m-0" :disabled="!hasPlayoutSettingsResolution" label="1920x1080" v-model="resolutionComputed" inputValue="fullhd" />
      <FormRadio class="m-0" :disabled="!hasPlayoutSettingsResolution" label="1280x720" v-model="resolutionComputed" inputValue="hd" />
    </div>
    <div v-if="!hasPlayoutSettingsResolution" class="flex rounded-md bg-surface-2 text-surface-8 text-tiny p-2">
      <icon-base class="mr-2 shrink-0 mt-px" fill="none">
        <icon-info-small />
      </icon-base>
      <p class="mb-0"><a target="_blank" class="text-white underline" href="/app/subscribe">Upgrade</a> plan to enable Resolution.
      </p>
    </div>
  </section>
  <section v-else>
    <div class="flex rounded-md bg-surface-2 text-surface-8 text-tiny p-2">
      <icon-base class="mr-2 shrink-0 mt-px" fill="none">
        <icon-info-small />
      </icon-base>
      <p class="mb-0">Please <a target="_blank" class="text-white underline" href="/app/subscribe">upgrade</a> to
        <strong>Ultra</strong> plan to use this function. You can change the resolution and FPS of the outgoing stream.
      </p>
    </div>
  </section>

</template>

<style scoped></style>
