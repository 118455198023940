<script setup>
import { ref, computed } from 'vue'
import _ from 'lodash';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import IconBase from '@/components/icon/IconBase.vue';
import IconCross from '@/components/icon/IconCross.vue';
import IconChevronLeft from '../icon/IconChevronLeft.vue';
import IconLivestream from '@/components/icon/IconLivestream.vue';
import IconMultistream from '@/components/icon/IconMultistream.vue';
import SubscriptionService from '@/services/SubscriptionService';
import StreamRegion from '../ui/StreamRegion.vue';
import FormInput from '../Atoms/FormInput.vue';
import FormButton from '../Atoms/FormButton.vue';

const props = defineProps({
	placement: {
		type: String,
		default: 'right'
	},
	stage: {
		type: Number
	},
	isMultiStage: {
		type: Boolean,
		default: false,
	}
})

const emit = defineEmits({
	'closed': (value) => { return value },
	'opened': (value) => { return value },
	'goToStage': (stage) => { return stage },
})

const goToStage = (stage) => emit('goToStage', stage)

const isVisible = ref(false)
const toggleSidebar = () => {
	isVisible.value = !isVisible.value
	isVisible.value ? emit('opened', true) : emit('closed', true)
}

defineExpose({ toggleSidebar })

const computedClassStageOne = computed(() => {
	const baseClass = 'bg-surface-2 fixed bottom-0 md:top-0 w-full md:h-screen md:w-[480px] z-30 sidebar-container'
	const statusCLass = isVisible.value ? 'sidebar-open' : 'sidebar-closed'
	const placement = props.placement === 'right' ? 'right-0' : 'left-0'
	const result = []
	result.push(baseClass, statusCLass, placement)
	return result.join(' ')
})
const computedClassStageTwo = computed(() => {
	const baseClass = 'bg-surface-2 fixed bottom-0 md:top-0 w-full md:h-screen md:w-[480px] z-40 md:z-20 sidebar-container'
	const statusCLass = isVisible.value ? 'sidebar-open' : 'sidebar-closed'
	const placement = props.placement === 'right' ? 'md:right-[480px]' : 'left-0'
	const result = []
	result.push(baseClass, statusCLass, placement)
	return result.join(' ')
})
</script>

<template>
	<Teleport to="body">
		<Transition :name="'sidebar-' + props.placement">
			<aside v-if="isVisible" class="rounded-t-3xl md:rounded-t-none" :class="computedClassStageOne">
				<header
					class="flex items-center rounded-t-3xl md:rounded-t-none w-full md:mt-24 !px-4 relative bg-surface-3 md:bg-transparent !py-3 md:!py-0 border-b border-surface-5 md:border-none">
					<router-link :to="{ path: '/dashboard' }" tag="a" class="modal-logo relative !hidden md:!block">
						<img height="32px" class="logo-icon" src="@/assets/castr.svg" alt="Streaming Dashboard - Castr" />
						<div class="logo-icon-dot" />
					</router-link>
					<div
						class="ml-auto text-right rounded-full bg-surface-4 md:bg-transparent flex md:block items-center justify-center relative z-[60]">
						<form-button class="" type="link" isIcon @click="toggleSidebar">
							<icon-base iconColor="none" class="">
								<icon-cross />
							</icon-base>
						</form-button>
					</div>
				</header>
				<section class="stage-one z-30 relative md:mt-0" :class="isMultiStage ? ' -mt-[90px]' : ' -mt-10 h-[540px]'">
					<slot name="stage-one" />
				</section>
				<teleport v-if="isMultiStage" to='body'>
					<Transition :name="'sidebar-' + props.placement + '-stage-two'">
						<section class="stage-two z-20 md:pt-44 rounded-t-3xl md:rounded-t-none  h-[540px]" v-if="stage === 2" :class="computedClassStageTwo">
							<div class="flex md:hidden w-full rounded-t-3xl md:rounded-t-none bg-surface-3 md:bg-transparent !py-3 md:!py-0 border-b border-surface-5 md:border-none !px-4">
								<form-button v-if="isMultiStage" class="mr-auto" type="link" isIcon @click="goToStage(1)">
									<icon-base iconColor="none" class="">
										<icon-chevron-left />
									</icon-base>
								</form-button>
								<div
									class="ml-auto text-right rounded-full bg-surface-4 md:bg-transparent flex md:block items-center justify-center">
									<form-button class="" type="link" isIcon @click="toggleSidebar">
										<icon-base iconColor="none" class="">
											<icon-cross />
										</icon-base>
									</form-button>
								</div>
							</div>

							<slot name="stage-two" />
						</section>
					</Transition>
				</teleport>
			</aside>
		</Transition>
		<div v-if="isVisible" @click="toggleSidebar"
			class="sidebar-overlay absolute top-0 size-full bg-black/30 z-10 flex items-center justify-center">
		</div>
	</Teleport>
</template>

<style scoped>
.sidebar-enter-from {
	opacity: 0;
}

.sidebar-leave-to {
	opacity: 0;
}

.sidebar-enter-from .sidebar-container,
.sidebar-leave-to .sidebar-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}


.sidebar-container {
	@apply transition-all duration-300 shadow-md
}

.sidebar-right-enter-from {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.sidebar-right-enter-to {
	@apply translate-y-0 md:translate-x-0
}

.sidebar-right-leave-from {
	@apply translate-y-0 md:translate-x-0
}

.sidebar-right-leave-active {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.sidebar-right-leave-to:not(.sidebar-right-leave-active) {
	@apply !hidden
}

.sidebar-right-leave-active .stage-two {
	@apply translate-y-0 md:translate-x-0
}

.sidebar-right-leave-from .stage-two {
	@apply translate-y-0 md:translate-x-0
}

.sidebar-right-leave-to .stage-two {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.sidebar-right-stage-two-enter-from {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.sidebar-right-stage-two-enter-to {
	@apply translate-y-0 md:translate-x-0
}

.sidebar-right-stage-two-leave-from {
	@apply translate-y-0 md:translate-x-0
}

.sidebar-right-stage-two-leave-active {
	@apply translate-y-full md:translate-x-full md:translate-y-0
}

.sidebar-right-stage-two-leave-to:not(.sidebar-right-stage-two-leave-active) {
	@apply !hidden
}

.sidebar-right-leave-active .sidebar-right-stage-two {
	@apply !hidden
}

.sidebar-left-enter-from {
	@apply md:-translate-x-full
}

.sidebar-left-enter-to {
	@apply md:translate-x-0
}

.sidebar-left-leave-from {
	@apply md:translate-x-0
}

.sidebar-left-leave-active {
	@apply md:-translate-x-full
}

.sidebar-left-leave-to:not(.sidebar-left-leave-active) {
	@apply !hidden
}
</style>
