import Vue from "vue";
// import Router from "vue-router";
import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";

import SignIn from "@/views/SignIn";
import Dashboard from "@/views/Dashboard";
import StreamManage from "@/views/StreamsDash/StreamsManage";
import VODStreamManage from "@/views/VodDash/VODStreamManage";
import VODFolderManage from "@/views/VodDash/VODFolderManage";
import StreamManageDashboard from "@/views/StreamsDash/StreamsManageDashboard";
import StreamsManageSettings from "@/views/StreamsDash/StreamsManageSettings";
import StreamsManageSettingsPlayerInterface from "@/views/StreamsDash/StreamsManageSettingsPlayerInterface";
import StreamsManageSettingsPlayerFunctions from "@/views/StreamsDash/StreamsManageSettingsPlayerFunctions";
import StreamsManageSettingsAdaptiveBitrate from "@/views/StreamsDash/StreamsManageSettingsAdaptiveBitrate";
import StreamsManageSettingsBackup from "@/views/StreamsDash/StreamsManageSettingsBackup";
import VODBucketPlayerSettings from "@/views/StreamsDash/VODBucketPlayerSettings";
import StreamsManageAnalytics from "@/views/StreamsDash/StreamsManageAnalytics.vue";
import StreamsManageAnalyticsInputHealthCharts from "@/views/StreamsDash/StreamsManageAnalyticsInputHealthCharts.vue";
import StreamsManageAnalyticsLiveInputHealthCharts from "@/views/StreamsDash/StreamsManageAnalyticsLiveInputHealthCharts.vue";
import StreamsManageSecuritySettings from "@/views/StreamsDash/StreamsManageSecuritySettings";
import StreamManageVodEpisodes from "@/views/StreamsDash/StreamsManageVodEpisodes";
import StreamManageClipEpisodes from "@/views/StreamsDash/StreamsManageClipEpisodes";
import StreamsManageAlerts from "@/views/StreamsDash/StreamsManageAlerts";
import StreamsManageNotifications from "@/views/StreamsDash/StreamsManageNotifications";
import StreamsManageWebhooks from "@/views/StreamsDash/StreamsManageWebhooks";
import VODBucket from "@/views/VodDash/VODBucket";
import VODTagsManage from "@/views/VodDash/VODTagsManage";
import Scheduler from "@/views/SchedulerDash/SchedulerDashboard";

import DynamicDashboard from "@/views/DynamicDash/DynamicDashboard";
import DynamicManage from "@/views/DynamicDash/DynamicManage";

import PlayoutDashboard from "@/views/TVPlayoutDash/PlayoutDashboard";
import PlayoutView from "@/views/TVPlayoutDash/PlayoutView";

import ManageHighlights from "@/views/Highlights/ManageHighlights";
import StreamManageHighlights from "@/views/Highlights/StreamManageHighlights";

import DestinationDashboard from "@/views/Destination/DestinationDashboard";

import StreamsManageShowcase from "@/views/Showcase/StreamsManageShowcase";
import ManagePlaylists from "@/views/Playlists/ManagePlaylists";
import StreamsManagePaywall from "@/views/StreamsDash/StreamsManagePaywall";
import AnalyticsDashboard from "@/views/AnalyticsDash/AnalyticsDashboard";
import StreamsManageAdvertising from "@/views/StreamsDash/StreamsManageAdvertising";
import Paywall from "@/views/Paywall/PaywallDash";
import OriginManage from "@/views/Origin/OriginManage";

import AppManage from "@/views/AppManage/AppManage";
import ManageAccount from "@/views/AppManage/AppManageAccount";
import ManageTeam from "@/views/AppManage/AppManageTeam";
import ManagePasswordChange from "@/views/AppManage/AppManagePasswordChange";
import ManageSubAccounts from "@/views/AppManage/AppManageSubAccounts";
import ManageInviteFriends from "@/views/AppManage/AppManageInviteFriends";
import ManageBilling from "@/views/AppManage/AppManageBilling";
import ManageDeveloperSettings from "@/views/AppManage/AppManageDeveloperSettings";
import ManageApi from "@/views/AppManage/AppManageApi";

import PlanSubscribe from "@/views/PlanSubscribe";
import SubscribeSubAccount from "@/views/SubscribeSubAccount";

import SubSecondDashboard from "@/views/SubSecondStreaming/SubSecondDashboard";
import SubSecondManage from "@/views/SubSecondStreaming/SubSecondManage";
import SubSecondManageDashboard from "@/views/SubSecondStreaming/SubSecondManageDashboard";
import SubSecondSettings from "@/views/SubSecondStreaming/SubSecondSettings";
import SubSecondSecuritySettings from "@/views/SubSecondStreaming/SubSecondSecuritySettings";

import SignUp from "@/views/SignUp";
import RequestPasswordReset from "@/views/RequestPasswordReset";
import ValidatePasswordReset from "@/views/ValidatePasswordReset";

import TranscoderDashboard from "@/views/TranscoderDash/TranscoderDashboard";
import TranscoderManage from "@/views/TranscoderDash/TranscoderManage";

import PaywallManage from "@/views/PaywallDash/PaywallManage";

import UserService from "@/services/UserService";

/* eslint-disable-next-line no-unused-vars */
function isLoggedIn(to, from, next) {
  if (store.state.Auth.isAuthenticated) next();
  else next("/login-redirect");
}

const restrictforTeam = async (to, from, next) => {
  let ownerData = localStorage.getItem("owneruser");
  let user = localStorage.getItem("user");

  if (user) {
    user = JSON.parse(user);
  }

  if (ownerData) {
    ownerData = JSON.parse(ownerData);
  }

  if (ownerData && Object.keys(ownerData).length != 0) {
    let tid = ownerData.id;
    let mid = user._id;
    const permission = await UserService.getMemberpermission(tid, mid);
    if (permission && permission.role != "admin") {
      return next({ path: "/" });
    }
  }
  return next();
};

/* eslint-disable-next-line no-unused-vars */
function loggedInRedirect(to, from, next) {
  if (store.state.Auth.isAuthenticated) next("/dashboard");
  else next();
}

// Vue.use(Router);

const routesLivestreams = {
  path: "/livestreams/:streamId",
  name: "LivestreamManage",
  component: StreamManage,
  redirect: {
    name: "LivestreamManageDashboard",
  },
  children: [
    {
      path: "dashboard",
      name: "LivestreamManageDashboard",
      component: StreamManageDashboard,
      props: true,
    },
    {
      path: "vodepisodes",
      name: "LivestreamManageVodEpisodes",
      component: StreamManageVodEpisodes,
      props: true,
    },
    {
      path: "clipepisodes",
      name: "LivestreamManageClipEpisodes",
      component: StreamManageClipEpisodes,
      props: true,
    },
    {
      path: "alerts",
      name: "StreamsManageAlerts",
      component: StreamsManageAlerts,
      props: true,
      redirect: {
        name: "StreamsManageNotifications",
      },
      children: [
        {
          path: "notifications",
          name: "StreamsManageNotifications",
          component: StreamsManageNotifications,
          props: true,
        },
        {
          path: "webhooks",
          name: "StreamsManageWebhooks",
          component: StreamsManageWebhooks,
          props: true,
        },
      ],
    },
    {
      path: "paywall",
      name: "LivestreamManagePaywall",
      component: StreamsManagePaywall,
      props: true,
    },
    {
      path: "advertising",
      name: "StreamsManageAdvertising",
      component: StreamsManageAdvertising,
      props: true,
    },
    {
      path: "settings",
      name: "LivestreamManageSettings",
      component: StreamsManageSettings,
      props: true,
      redirect: {
        name: "StreamsManageSettingsPlayerInterface",
      },
      children: [
        {
          path: "player-interface",
          name: "StreamsManageSettingsPlayerInterface",
          component: StreamsManageSettingsPlayerInterface,
          props: true,
        },
        {
          path: "player-functions",
          name: "StreamsManageSettingsPlayerFunctions",
          component: StreamsManageSettingsPlayerFunctions,
          props: true,
        },
        {
          path: "security",
          name: "LivestreamManageSecuritySettings",
          component: StreamsManageSecuritySettings,
          props: true,
        },
        {
          path: "adaptive-bitrate",
          name: "StreamsManageSettingsAdaptiveBitrate",
          component: StreamsManageSettingsAdaptiveBitrate,
          props: true,
        },
      ],
    },
    {
        path: "backup",
        name: "StreamsManageSettingsBackup",
        component: StreamsManageSettingsBackup,
        props: true,
    },
    {
      path: "analytics",
      name: "LivestreamManageAnalytics",
      props: true,
      component: StreamsManageAnalytics,
      redirect: {
        name: "StreamsManageAnalyticsInputHealthCharts",
      },
      children: [
        {
          path: "player",
          name: "LivestreamManageAnalyticsPlayer",
          // component: StreamsManageVideoSettings,
          component: () =>
            import(
              /* webpackChunkName: 'viewsLivestreams' */ "@/views/StreamsDash/StreamsManageAnalyticsPlayer.vue"
            ),
          props: true,
        },
        {
          path: "advertising",
          name: "LivestreamManageAnalyticsAdvertising",
          // component: StreamsManageSecuritySettings,
          component: () =>
            import(
              /* webpackChunkName: 'viewsLivestreams' */ "@/views/StreamsDash/StreamsManageAnalyticsAdvertising.vue"
            ),
          props: true,
        },
        {
          path: "input-health",
          name: "StreamsManageAnalyticsInputHealthCharts",
          component: StreamsManageAnalyticsInputHealthCharts,
          redirect: {
            name: "StreamsManageAnalyticsLiveInputHealthCharts",
          },
          children: [
            {
              path: "",
              name: "StreamsManageAnalyticsLiveInputHealthCharts",
              component: StreamsManageAnalyticsLiveInputHealthCharts,
              props: true,
            },
            {
              path: "sessions",
              name: "StreamsManageAnalyticsStreamSessions",
              component: () =>
                import(
                  /* webpackChunkName: 'viewsLivestreams' */ "@/views/StreamsDash/StreamsManageAnalyticsStreamSessions.vue"
                ),
              props: true,
            },
            {
              path: "session-chart/:streamKey/:sessionId",
              name: "StreamSessionsChart",
              component: () =>
                import(
                  /* webpackChunkName: 'viewsLivestreams' */ "@/views/StreamsDash/StreamSessionsChart.vue"
                ),
              props: true,
            },
          ],
        },
      ],
    },
  ],
};

const routesSimulcasts = {
  path: "/multistreams/:streamId",
  name: "SimulcastManage",
  component: StreamManage,
  redirect: {
    name: "SimulcastManageDashboard",
  },
  children: [
    {
      path: "dashboard",
      name: "SimulcastManageDashboard",
      component: StreamManageDashboard,
      props: true,
    },
    {
      path: "chat",
      name: "SimulcastManageChat",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: 'viewsSimulcasts' */ "@/views/StreamsDash/StreamsManageChat.vue"
        ),
    },
    {
      path: "vodepisodes",
      name: "SimulcastManageVodEpisodes",
      component: StreamManageVodEpisodes,
      props: true,
    },
  ],
};

const routesIpcams = {
  path: "/ipcams/:streamId",
  name: "CamstreamManage",
  component: StreamManage,
  redirect: {
    name: "CamstreamManageDashboard",
  },
  children: [
    {
      path: "dashboard",
      name: "CamstreamManageDashboard",
      component: StreamManageDashboard,
      props: true,
    },
    {
      path: "vodepisodes",
      name: "CamstreamManageVodEpisodes",
      component: StreamManageVodEpisodes,
      props: true,
    },
  ],
};

const routesScheduled = {
  path: "/scheduled/:streamId",
  name: "ScheduledStreamManage",
  component: StreamManage,
  redirect: {
    name: "ScheduledStreamManageDashboard",
  },
  children: [
    {
      path: "dashboard",
      name: "ScheduledStreamManageDashboard",
      component: StreamManageDashboard,
      props: true,
    },
  ],
};

const routesHighlight = {
  path: "/highlight/:highlightId",
  name: "HighlightSingleManage",
  component: StreamManageHighlights,
  props: true,
};

const routesVods = {
  path: "/vods/:streamId",
  name: "VODFolderManage",
  component: VODFolderManage,
  redirect: {
    name: "VODBucketVideos",
  },
  children: [
    {
      path: "videos",
      name: "VODBucketVideos",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: 'viewsVods' */ "@/views/VodDash/VODManageVideoList.vue"
        ),
    },
    {
      path: "advertising",
      name: "StreamsManageVodAdvertising",
      component: StreamsManageAdvertising,
      props: true,
    },
    {
      path: "settings",
      name: "VODBucketSettings",
      component: StreamsManageSettings,
      props: true,
      redirect: {
        name: "VODBucketPlayerSettings",
      },
      children: [
        {
          path: "player-interface",
          name: "VODBucketPlayerSettings",
          component: VODBucketPlayerSettings,
          props: true,
        },
        {
          path: "security",
          name: "VODBucketSecuritySettings",
          component: StreamsManageSecuritySettings,
          props: true,
        },
      ],
    },
    {
      path: "paywall",
      name: "VODstreamManagePaywall",
      component: StreamsManagePaywall,
      props: true,
    },
    {
      path: ":videoId/video",
      name: "VODStreamManage",
      component: VODStreamManage,
      props: true,
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    alias: ['/signin'],
    name: "Signin",
    component: SignIn,
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/dashboard",
    name: "ChannelList",
    component: Dashboard,
  },
  {
    path: "/login-redirect",
    name: "Login",
    redirect: () => {
      window.location.href = "/app/login";
    },
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/logout-redirect",
    name: "Logout",
    redirect: () => {
      window.location.href = "/app/logout";
    },
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/subscribe",
    name: "Payments",
    component: PlanSubscribe,
    beforeEnter: restrictforTeam,
  },
  {
    path: "/signup",
    name: "Signup",
    component: SignUp,
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/forgot-password",
    name: "RequestPasswordReset",
    component: RequestPasswordReset,
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/forgot-password/reset",
    name: "ValidatePasswordReset",
    component: ValidatePasswordReset,
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/member",
    name: "Member",
    redirect: "/subscribe",
  },
  {
    path: "/vods",
    name: "VodBucket",
    props: true,
    component: VODBucket,
  },
  {
    path: "/vods/tags",
    name: "VODTagsManage",
    props: true,
    component: VODTagsManage,
  },
  {
    path: "/scheduler",
    name: "Scheduler",
    props: true,
    component: Scheduler,
  },
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "ChannelList",
    component: Dashboard,
  },
  {
    path: "/login-redirect",
    name: "Login",
    redirect: () => {
      window.location.href = "/app/login";
    },
    meta: {
      fullScreen: true,
    },
  },
  {
    path: "/manage",
    name: "AppManage",
    // redirect: '/manage/account',
    component: AppManage,
    children: [
      {
        path: "account",
        meta: {
          title: "Account",
        },
        component: ManageAccount,
      },
      {
        path: "team",
        name: "Team",
        meta: {
          title: "Team",
        },
        component: ManageTeam,
      },
      {
        path: "change-password",
        meta: {
          title: "Change Password",
        },
        component: ManagePasswordChange,
      },
      {
        path: "billing",
        name: "Billing",
        meta: {
          title: "Billing",
        },
        component: ManageBilling,
      },
      {
        path: "sub-accounts",
        meta: {
          title: "Sub Accounts",
        },
        name: "SubAccounts",
        component: ManageSubAccounts,
        props: true,
      },
      {
        path: "sub-accounts/subscribe/:subAccountId",
        name: "SubscribeSubAccount",
        component: SubscribeSubAccount,
        props: true,
      },
      {
        path: "developers",
        meta: {
          title: "Developer Settings",
        },
        component: ManageDeveloperSettings,
      },
      {
        path: "api",
        meta: {
          title: "API",
        },
        component: ManageApi,
      },
      {
        path: "refer-and-earn",
        meta: {
          title: "Refer and Earn",
        },
        component: ManageInviteFriends,
      },
    ],
  },
  {
    path: "/subscribe",
    name: "Payments",
    component: PlanSubscribe,
    beforeEnter: restrictforTeam,
  },
  {
    path: "/member",
    name: "Member",
    redirect: "/subscribe",
  },
  {
    path: "/scheduler",
    name: "Scheduler",
    props: true,
    component: Scheduler,
  },
  {
    path: "/paywall",
    name: "Paywall",
    props: true,
    component: Paywall,
  },
  {
    path: "/playlists",
    name: "Playlists",
    props: true,
    component: ManagePlaylists,
  },
  {
    path: "/playlists/:playlistId",
    name: "Playlist",
    props: true,
    component: ManagePlaylists,
  },
  {
    path: "/showcase",
    name: "Showcase",
    props: true,
    component: StreamsManageShowcase,
    redirect: {
      name: "ShowcaseContent",
    },
    children: [
      {
        path: "content",
        name: "ShowcaseContent",
        props: true,
        component: () => import("@/views/Showcase/ShowcaseContent.vue"),
      },
      {
        path: "settings",
        name: "ShowcaseSettings",
        props: true,
        component: () => import("@/views/Showcase/ShowcaseSettings.vue"),
      },
    ],
  },
  {
    path: "/paywall",
    name: "Paywall",
    // redirect: '/paywall/subscriptions',
    // component: () => import('@/views/Billing'),
    props: true,
    component: Paywall,
    children: [
      {
        path: "transactions",
        name: "Transactions",
        component: () => import("@/views/Paywall/PaywallTransactions.vue"),
      },
      {
        path: "purchasers",
        name: "All Purchasers",
        component: () => import("@/views/Paywall/PaywallPurchasers.vue"),
      },
      {
        path: "streams",
        name: "All Streams With Paywall",
        component: () => import("@/views/Paywall/PaywallStreams.vue"),
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/Paywall/PaywallUsers.vue"),
      },
      {
        path: "subscriptions",
        name: "Subscriptions",
        component: () => import("@/views/Paywall/PaywallSubscriptions.vue"),
      },
      {
        path: "payment",
        name: "Payment Method",
        component: () => import("@/views/Paywall/PaywallPayment.vue"),
      },
      {
        path: "settings",
        name: "Paywall Settings",
        component: () => import("@/views/Paywall/PaywallSettings.vue"),
      },
    ],
  },
  {
    path: "/paywall/:id/subscription",
    name: "Subscription",
    component: () => import("@/views/Paywall/PaywallSubscription.vue"),
  },
  {
    path: "/highlight",
    name: "Highlight",
    props: true,
    component: ManageHighlights,
  },
  routesLivestreams,
  routesSimulcasts,
  routesIpcams,
  routesScheduled,
  routesHighlight,
  routesVods,
  {
    path: "/dynamic",
    name: "DynamicDashboard",
    component: DynamicDashboard,
    // redirect: {
    // 	name: 'DynamicDashboard'
    // },
    children: [],
  },
  {
    path: "/dynamic/:playerId",
    name: "DynamicManage",
    component: DynamicManage,
    props: true,
  },
  {
    path: "/analytics/:videoId",
    name: "AnalyticsDashboardWithRoute",
    component: AnalyticsDashboard,
    children: [],
  },
  {
    path: "/analytics",
    name: "AnalyticsDashboard",
    component: AnalyticsDashboard,
    children: [],
  },
  {
    path: "/tv-playout",
    name: "TVPlayoutDashboard",
    component: PlayoutDashboard,
    children: [],
    meta: {
      title: "TV playouts",
    },
  },
  {
    path: "/tv-playout/:tvId",
    name: "PlayoutView",
    component: PlayoutView,
    children: [],
    meta: {
      title: "TV playout",
    },
  },
  {
    path: "/sub-second",
    name: "SubSecondDashboard",
    component: SubSecondDashboard,
    children: [],
    meta: {
      title: "Sub Second Streaming",
    },
  },
  {
    path: "/sub-second/:streamId",
    name: "SubSecondManage",
    component: SubSecondManage,
    redirect: {
      name: "SubSecondManageDashboard",
    },
    children: [
      {
        path: "dashboard",
        name: "SubSecondManageDashboard",
        component: SubSecondManageDashboard,
        props: true,
      },
      {
        path: "analytics",
        name: "SubSecondManageAnalytics",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: 'viewsLivestreams' */ "@/views/StreamsDash/StreamsManageAnalytics.vue"
          ),
        redirect: {
          name: "SubSecondManageAnalyticsInputHealthCharts",
        },
        children: [
          {
            path: "input-health",
            name: "SubSecondManageAnalyticsInputHealthCharts",
            component: StreamsManageAnalyticsInputHealthCharts,
            redirect: {
              name: "SubSecondManageAnalyticsLiveInputHealthCharts",
            },
            children: [
              {
                path: "",
                name: "SubSecondManageAnalyticsLiveInputHealthCharts",
                component: StreamsManageAnalyticsLiveInputHealthCharts,
                props: true,
              },
              {
                path: "sessions",
                name: "SubSecondManageAnalyticsStreamSessions",
                component: () =>
                  import(
                    /* webpackChunkName: 'viewsLivestreams' */ "@/views/StreamsDash/StreamsManageAnalyticsStreamSessions.vue"
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "settings",
        name: "SubSecondSettings",
        component: SubSecondSettings,
        props: true,
        redirect: {
          name: "SubSecondSecuritySettings",
        },
        children: [
          {
            path: "security",
            name: "SubSecondSecuritySettings",
            component: SubSecondSecuritySettings,
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/origin",
    name: "Origin",
    component: OriginManage,
  },
  {
    path: "/transcoder",
    name: "TranscoderDashboard",
    component: TranscoderDashboard,
    children: [],
  },
  {
    path: "/transcoder/:transcoderId",
    name: "TranscoderManage",
    component: TranscoderManage,
    props: true,
  },
  {
    path: "/paywall/v2",
    name: "PaywallManage",
    props: true,
    component: PaywallManage,
  },
  {
    path: "/paywall/v2/:paywallId",
    name: "PaywallItem",
    props: true,
    component: PaywallManage,
  },
  {
    path: "/destination",
    name: "DestinationDashboard",
    component: DestinationDashboard,
    children: [],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: async () => await import('@/views/404.vue')
  },
];

const router = createRouter({
  base: "/app/",
  history: createWebHistory(),
  routes,
});

const GuestPages = [
  "Signin",
  "Signup",
  "RequestPasswordReset",
  "ValidatePasswordReset",
  "signin",
  "login",
  "notfound",
];
router.beforeEach((to, from, cb) => {
  // we can't use here vuex state here, router called before state loaded
  // TODO: check if vuex state loaded, and if not loaded load
  const loggedin = UserService.authenticated();
  if (GuestPages.indexOf(to.name) > -1) {
    if (loggedin) {
      // eslint-disable-next-line standard/no-callback-literal
      return cb({ name: "Home" });
    }
  } else {
    if (!loggedin && GuestPages.indexOf(to.name) === -1) {
      console.log("auth redirect: router");
      // window.location.href =
      //   process.env.VUE_APP_API_ENV === "prod" ? "/signin" : "/app/login";
      return;
    }
  }
  cb();
});

export default router;
