import store from ".."
import _ from 'lodash'
import UserService from "../../services/UserService";
import SubscriptionService from "../../services/SubscriptionService";
import Vue from 'vue';

const defaultState = () => ({
	id: null,
	name: '',
	email: '',
	contacts: [],
	username: '',
	joinDate: '',
	cmsEntityId: '',
	isOtt: null,
	apiKey: null,
	aggregatedDefinition: {},
	subscriptions: [],
	memberDetails: [],
	ownerDetails: [],
	baseSub: [],
	addonSub: [],
	products: [],
	deploymentRegions: [],
	guestData: [],
	activatedTeam: '',
	ownermember: [],
	memberDeleted: false,
	memberDisabled: false,
	memberPermission: null,
	userloaded: false,
	isOwner: true,
	viewShowcase: false,
	editShowcase: false,
	parentAccount: null,
	subscriptionMeta: {},
	maxVodItems: 0,
	maxVodStorage: 0,
	maxVodBandwidth: 0,
	maxTvPlayoutLoopDate: 0,
	tvPlayoutLineUpMode: false,
	tvPlayoutDailyMode: false,
	tvPlayoutWeeklyMode: false,
	maxSubSecondStreams: 0,
	tvPlayoutLineUpDuration: 0,
	reusableDestinations: null,
	reusableDestinationLimit: 0,
	teamOwnerInfoDetailed: null,
});

const state = defaultState();

const mutations = {
	LOAD_USER(state, user) {
		state = Object.assign(state, user);
		if (user.activeTeam) {
			state.isOwner = false
		} else state.isOwner = true
		state.userloaded = true
	},
	LOAD_API_KEY(state, apiKey) {
		state.apiKey = apiKey;
	},
	CHECK_OTT(state, isott) {
		state.isOtt = isott;
	},
	MEMBER_DELETED(state, memberDeleted) {
		state.memberDeleted = true;
	},
	MEMBER_DISABLED(state, memberDeleted) {
		state.memberDisabled = true;
	},
	UPDATE_MEMBERPERMISSION(state, info) {
		state.memberPermission = info;
	},
	UPDATE_TEAMINFOMEMBER(state, info) {
		state.memberDetails = info;
	},
	UPDATE_GUEST(state, info) {
		state.guestData = info;
		if(info?.paywall) {
			state.paywall = info.paywall;
		}
	},
	UPDATE_TEAMINFOOWNER(state, info) {
		state.ownerDetails = info;
	},
	UPDATE_OWNER(state, info) {
		state.ownermember = info;
	},
	UPDATE_USER(state, user) {
		state.userDetails = user;
	},
	UPDATE_ACTIVETEAM(state, team) {
		state.activatedTeam = team;
	},
	/* eslint-disable-next-line no-unused-vars */
	UNLOAD_USER(state) {
		state = defaultState();
	},
	SET_DEFAULT_USER() {
		let currentState = this.state.User;
		let allowedState = Object.keys(defaultState());
		const filteredState = Object.keys(currentState)
		.filter(key => allowedState.includes(key))
		.reduce((obj, key) => {
			obj[key] = currentState[key];
			return obj;
		}, {});
		this.state.User = filteredState;
	},
	SET_CONTACTS(state, contacts) {
		state.contacts = contacts
	},
	UNLOAD_PAYWALL(state) {
		Vue.delete(state, 'paywall');
	}
};

const actions = {
	async loadUser({ commit }) {
		try {
			let userProfile = await UserService.getUserInfo();
			let userOwnerProfile = null;
			let userteam = userProfile.activeTeam;
			let userSub = await SubscriptionService.getUserSubscriptions(true);
			if(userteam){
				commit('LOAD_USER', {
					...userProfile,
					parentAccount: userProfile.parentAccount
				});
				try{
				const permission = await UserService.getMemberpermission(userteam,userProfile._id);
				commit('UPDATE_MEMBERPERMISSION', permission);
				if(!permission.isEnabled) commit('MEMBER_DISABLED');
				}catch(err){
					console.log(err);
					if(this.state.User.memberPermission && !this.state.User.memberPermission.isEnabled){
						commit('MEMBER_DISABLED');
					}else{
						commit('MEMBER_DELETED');
					}
				}
				if(!this.state.User.memberDeleted){
					try{
						const owner = await UserService.getTeamOwner(userteam, false, true);
						commit('UPDATE_GUEST', owner);
					}catch(err){
						console.log(err);
					}
				}
				const userSubdata = await UserService.getTeamOwner(userteam)
				userSub = userSubdata.subscription;
				userOwnerProfile = userSubdata.owner;
				userOwnerProfile.activeTeam = userProfile.activeTeam;
				userOwnerProfile._id = userProfile._id;
				userProfile = userOwnerProfile;
				UserService.setOwnerSession(userSubdata);
				
			}else{
				UserService.setOwnerSession(null);
			}
			
			const userPlans = _.concat(userSub.addonSubscriptions,
				userSub.subscription ? [userSub.subscription] : []);
			
			// base sub info
			const subscriptionMeta = {}
			const live = userPlans.find((sub) => sub.category && sub.category.includes('live'));
			if (live) {
				subscriptionMeta.live = { trial: live.package.baseCharge === 0, enabled: live.enabled }
			}

			const vod = userPlans.find((sub) => sub.category && sub.category.includes('vod'));
			if (vod) {
				subscriptionMeta.vod = { trial: vod.package.baseCharge === 0, enabled: vod.enabled }
			}

			const aggregatedDefinition = {
				live: livePlanFeatures(userSub.addonSubscriptions),
				vod: vodPlanFeatures(userSub.addonSubscriptions),
				scheduled: schedulePlanFeatures(userSub.addonSubscriptions),
				subsecond: livePlanFeatures(userSub.addonSubscriptions),
			};
			commit('LOAD_USER', {
				...userProfile,
				subscriptionMeta: subscriptionMeta,
				aggregatedDefinition: aggregatedDefinition,
				subscriptions: userPlans,
				addonSub: userSub.addonSubscriptions,
				baseSub: userSub.subscription,
				deploymentRegions: userSub.deploymentRegions || [],
				maxVodItems: userSub.maxVodItems,
				maxVodStorage: userSub.maxVodStorage,
				maxVodBandwidth: userSub.maxVodBandwidth,
				activatedTeam: userProfile.activeTeam,
				tvPlayoutLineUpMode: userSub.tvPlayoutLineUpMode,
				tvPlayoutDailyMode: userSub.tvPlayoutDailyMode,
				tvPlayoutWeeklyMode: userSub.tvPlayoutWeeklyMode,
				maxTvPlayoutItems: userSub.maxTvPlayoutItems,
				maxSubSecondStreams: userSub.maxSubSecondStreams,
				maxSubSecondStreamViewers: userSub.maxSubSecondStreamViewers,
				maxTvPlayoutLoopDate: userSub.maxTvPlayoutLoopDate,
				reusableDestinations: userSub.reusableDestinations,
				reusableDestinationLimit: userSub.reusableDestinationLimit,
				allowSubSecondStreams: userSub.allowSubSecondStreams,
				v2ApiKey: userSub.v2ApiKey,
				advancedTvPlayoutSettingsFps: userSub.advancedTvPlayoutSettingsFps,
				advancedTvPlayoutSettingsResolution: userSub.advancedTvPlayoutSettingsResolution,
				tvPlayoutLineUpDuration: userSub.tvPlayoutLineUpDuration,
				tvPlayoutLoopMode: userSub.tvPlayoutLoopMode,
				tvPlayoutScheduleMode: userSub.tvPlayoutScheduleMode,
				// viewShowcase: userProfile.viewShowcase,
				// editShowcase: userProfile.editShowcase,
			});
			console.log(subscriptionMeta);
		} catch (error) {
			console.log(error);
		}
	},
	async setupUserContacts({ commit }) {
		try {
			const contacts = await UserService.getUserContacts('phone')
			commit('SET_CONTACTS', contacts);
		} catch(err) {
			console.log(err)
		}
	},
	async unloadUser({ commit }) {
		commit('UNLOAD_USER');
	},
	async setdefaultUserstate({ commit }) {
		commit('SET_DEFAULT_USER');
	},
	async loadApiKey({ commit }) {
		try {
			const { data } =  await UserService.getUserApiKey();
			commit('LOAD_API_KEY', data);
		} catch (error) {
			console.log(error);
		}
	},
	async getinfoMember({ commit }) {
		const result = await UserService.teamInfoMember();
		commit('UPDATE_TEAMINFOMEMBER', result)
	},
	async getactiveTeam({ commit }) {
		let userProfile = await UserService.getUserInfo();
		let userteam = userProfile.activeTeam;
		commit('UPDATE_ACTIVETEAM', userteam)
	},
	async getinfoOwner({ commit }) {
		try {
			let userProfile = await UserService.getUserInfo();
			let userteam = userProfile.activeTeam;
			if(userteam){
				try {
				const teamDetails = await UserService.getTeamOwner(userProfile.activeTeam);
				if(userProfile.activeTeam && teamDetails['members'].length){
					commit('UPDATE_OWNER', teamDetails);
					commit('UPDATE_TEAMINFOOWNER', []);
					let userSub = teamDetails.subscription;
					let userOwnerProfile = teamDetails.owner;
					const userPlans = _.concat(userSub.addonSubscriptions,
						userSub.subscription ? [userSub.subscription] : []);
					const aggregatedDefinition = {
						live: livePlanFeatures(userSub.addonSubscriptions),
						vod: vodPlanFeatures(userSub.addonSubscriptions),
						scheduled: schedulePlanFeatures(userSub.addonSubscriptions)
					};
				}
				} catch (error) {
					console.log(error);
				}
				
				
				}else{
					const result = await UserService.teaminfoOwner()
					commit('UPDATE_TEAMINFOOWNER', result);
					commit('UPDATE_OWNER', []);
				}
		} catch (error) {
			console.log(error);
		}
	},
	async checkOtt({ commit }) {
		try {
			const result =  await UserService.getUserApiKey();	
			commit('CHECK_OTT', (result.keyType == 'ott_app'));
		} catch (error) {
			console.log(error);
		}
	},
	unloadPaywall({ commit }) {
		commit('UNLOAD_PAYWALL')
	}
};

const getters = {
	userId: (state) => state._id,
	userEmail: (state) => state.email,
	userName: (state) => state.name,
	initials: (state) => state.name.length
		? state.name.split(' ').map(s => s[0].toUpperCase()).join('')
		: '',
	maxScheduledStreams: (state) => {
		let scheduledSubscription = state.addonSub.find(subscription => subscription.category === 'scheduled')
		if (scheduledSubscription && scheduledSubscription.definitionOverride && scheduledSubscription.definitionOverride.streams) {
			return parseInt(scheduledSubscription.definitionOverride.streams, 10)
		} else if (scheduledSubscription && scheduledSubscription.package && scheduledSubscription.package.definition) {
			return parseInt(scheduledSubscription.package.definition.streams, 10)
		} else return 0
	},
	userPermissions: (state) => state.memberPermission,
	permissionSubAccountCreateView: (state) => {
		if (state.memberPermission && state.memberPermission.role === 'admin' || state.memberPermission && state.memberPermission.role === 'moderator' && state.memberPermission.subAccountInfoCreate && state.memberPermission.subAccountInfoView || state.memberPermission === null && !state.activatedTeam ) {
			return true
		} else return false
	},
	permissionSubAccountStreamsView: (state) => {
		if (state.memberPermission && state.memberPermission.role === 'admin' || state.memberPermission && state.memberPermission.role === 'moderator' && state.memberPermission.subAccountStreamView || state.memberPermission === null ) {
			return true
		} else return false
	},
	baseSubscription: (state) => {
		return state.baseSub
	},
	addonSubscriptions: (state) => {
		return state.addonSub
	},
	showcaseView: (state) => {
		return state.viewShowcase
	},
	showcaseEdit: (state) => {
		return state.editShowcase
	},
	hasSubAccountSubscription: (state) => {
		if(state.addonSub.find((subscription) => subscription.category === 'sub-accounts')) {
			return true
		} else return false
	},
	canEncodeVideos: (state) => {
		const encodingUnits = _.get(
			state.baseSub,
			"package.definition.encodingUnits"
		);
		return encodingUnits > 0;
	},
	isOwner: (state) => {
		return state.isOwner
	},
	isTeamAdmin: (state) => {
		if (state.memberPermission && state.memberPermission.role === 'admin') {
			return true
		} else return false
	},
	isTeamModerator: (state) => {
		if (state.memberPermission && state.memberPermission.role === 'moderator') {
			return true
		} else return false
	},
	isSubAccount: (state) => {
		if (state.parentAccount) {
			return true
		} else return false
	},
	parentAccount: (state) => {
		if (state.parentAccount) {
			return state.parentAccount
		} else return null
	},
	teamOwner: (state) => {
		if (state.ownermember && state.ownermember.owner) {
			return state.ownermember && state.ownermember.owner
		} else return null
	},
	userContacts: (state) => {
		return state.contacts
	},
	maxVodFolders: (state) => {
		return state.maxVodItems
	},
	maxVodStorage: (state) => {
		return state.maxVodStorage
	},
	maxVodBandwidth: (state) => {
		return state.maxVodBandwidth
	},
	tvPlayoutLineUpMode: (state) => {
		return state.tvPlayoutLineUpMode
	},
	tvPlayoutDailyMode: (state) => {
		return state.tvPlayoutDailyMode
	},
	tvPlayoutWeeklyMode: (state) => {
		return state.tvPlayoutWeeklyMode
	},
	maxTvPlayoutLoopDate: (state) => {
		return state.maxTvPlayoutLoopDate
	},
	maxSubSecondStreams: (state) => {
		return state.maxSubSecondStreams
	},
	maxSubSecondStreamViewers: (state) => {
		return state.maxSubSecondStreamViewers
	},
	subscriptions: (state) => {
		return state.subscriptions
	},
	tvPlayoutLineUpDuration: (state) => {
		return state.tvPlayoutLineUpDuration
	},
	canDownloadSource: (state) => {
		const unlimitedDownload = state.addonSub.find(subscription => subscription.category === 'live')?.package?.definition?.unlimitedVodDownload || state?.unlimitedVodDownload;
		return unlimitedDownload
	},
	hasMultistreamSubscription: (state) => state.baseSub?.package?.category === 'restream' && state.baseSub?.enabled,
	hasLiveSubscription: (state) => {
		if (state.subscriptionMeta.live) {
			return state.subscriptionMeta.live
		} else return false
	},
	hasVodSubscription: (state) => {
		if (state.subscriptionMeta.vod) {
			return state.subscriptionMeta.vod
		} else return false
	},
	isLiveSubscriptionExpired: (state) => {
		if (state.subscriptionMeta.live && !state.subscriptionMeta.live.enabled) {
			return true
		} else return false
	},
	dynamicPlayerFeatures: (state) => {
		const liveSubscription = state.addonSub.find(subscription => subscription.category === 'live')
		const vodSubscription = state.addonSub.find(subscription => subscription.category === 'vod')
		if (liveSubscription) {
			const features = {
				'count': liveSubscription?.package?.definition?.['dp:count'] ? liveSubscription?.package?.definition?.['dp:count'] : false,
				'domain-whitelist': liveSubscription?.package?.definition?.['dp:domain-whitelist'] ? liveSubscription?.package?.definition?.['dp:domain-whitelist'] : false,
				'geo-block': liveSubscription?.package?.definition?.['dp:geo-block'] ? liveSubscription?.package?.definition?.['dp:geo-block'] : false,
				'geo-whitelist': liveSubscription?.package?.definition?.['dp:geo-whitelist'] ? liveSubscription?.package?.definition?.['dp:geo-whitelist'] : false,
				'player-functions': liveSubscription?.package?.definition?.['dp:player-functions'] ? liveSubscription?.package?.definition?.['dp:player-functions'] : false
			}
			return features
		} else if (vodSubscription) {
			const features = {
				'count': liveSubscription?.package?.definition?.['dp:count'] ? liveSubscription?.package?.definition?.['dp:count'] : false,
				'domain-whitelist': liveSubscription?.package?.definition?.['dp:domain-whitelist'] ? liveSubscription?.package?.definition?.['dp:domain-whitelist'] : false,
				'geo-block': liveSubscription?.package?.definition?.['dp:geo-block'] ? liveSubscription?.package?.definition?.['dp:geo-block'] : false,
				'geo-whitelist': liveSubscription?.package?.definition?.['dp:geo-whitelist'] ? liveSubscription?.package?.definition?.['dp:geo-whitelist'] : false,
				'player-functions': liveSubscription?.package?.definition?.['dp:player-functions'] ? liveSubscription?.package?.definition?.['dp:player-functions'] : false
			}
			return features
		} else return false
	},
	maxTranscoders: (state) => {
		const liveSub = state.addonSub.find(subscription => subscription.category === 'live')
		if (!liveSub) {
			return
		}
		
		const maxTranscodersSub = liveSub?.package?.definition?.maxTranscoders ?? 0
		const maxTranscodersOverride = liveSub?.definitionOverride?.maxTranscoders

		return maxTranscodersOverride !== undefined ? maxTranscodersOverride : maxTranscodersSub
	},
	transcoderFlag: (state) => {
		const liveSub = state.addonSub.find(subscription => subscription.category === 'live')
		if (!liveSub) {
			return
		}
		
		const transcodersSub = liveSub?.package?.definition?.transcoder
		const transcodersOverride = liveSub?.definitionOverride?.transcoder

		return transcodersOverride !== undefined ? transcodersOverride : transcodersSub
	},
	userPaywall: (state) => {
		return state.paywall	
	},
	userPaywallActived: (state) => {
		return state?.paywall?.linked ?? state.guestData?.paywall?.linked ?? false
	},
	hasDestinations: (state) => {
		return state.reusableDestinations
	},
	hasDestinationLimit: (state) => {
		return state.reusableDestinationLimit
	},
	maxPaywalls: (state) => {
		const liveSub = state.addonSub.find(subscription => subscription.category === 'live')
		if (!liveSub) {
			return
		}
		
		const maxPaywallsSub = liveSub?.package?.definition?.maxPaywalls ?? 0
		const maxPaywallsOverride = liveSub?.definitionOverride?.maxPaywalls

		return maxPaywallsOverride !== undefined ? maxPaywallsOverride : maxPaywallsSub
	},
	paywallStripeCommission: (state) => {
		const liveSub = state.addonSub.find(subscription => subscription.category === 'live')
		if (!liveSub) {
			return
		}
		
		const commissionSub = liveSub?.package?.definition?.paywallStripeCommission ?? null
		const commissionSubOverride = liveSub?.definitionOverride?.paywallStripeCommission

		return commissionSubOverride !== undefined ? commissionSubOverride : commissionSub
	},
	bitradeLadderFlag: (state) => {
		const liveSub = state.addonSub.find(subscription => subscription.category === 'live')
		if (!liveSub) {
			return
		}
		
		const bitrateLadderSub = liveSub?.package?.definition?.bitrateLadder
		const bitrateLadderOverride = liveSub?.definitionOverride?.bitrateLadder

		return bitrateLadderOverride !== undefined ? bitrateLadderOverride : bitrateLadderSub
	},
	v2ApiKey: (state) => {
		return state.v2ApiKey
	},
	hasPlayoutAdvancedSettings: (state) => {
		return state.advancedTvPlayoutSettingsFps || state.advancedTvPlayoutSettingsResolution
	},
	hasPlayoutSettingsFps: (state) => {
		return state.advancedTvPlayoutSettingsFps
	},
	hasPlayoutSettingsResolution: (state) => {
		return state.advancedTvPlayoutSettingsResolution
	},
	hasPlayoutLoopMode: (state) => {
		return state.tvPlayoutLoopMode
	},
	hasPlayoutScheduleMode: (state) => {
		return state.tvPlayoutScheduleMode
	},
	playoutMaxDurationLimit: (state) => state.tvPlayoutLineUpDuration,
	userLoaded: (state) => state.userloaded
};

function livePlanFeatures(addonSub) {
	const sub = _.find(addonSub, { category: 'live' });
	if (sub) {
		if (sub.package.name.includes('All-In-One')) {
			const features = [
				['password-auth', 'securityFeaturesPasswordAuth'],
				['geo-block', 'securityFeaturesGeoBlock'],
				['geo-whitelist', 'securityFeaturesGeoWhitelist'],
				['domain-whitelist', 'securityFeaturesDomainWhitelist']
			];

			// all blocked by default
			const result = {
				securityFeaturesPasswordAuth: true,
				securityFeaturesGeoBlock: true,
				securityFeaturesGeoWhitelist: true,
				securityFeaturesDomainWhitelist: true
			};

			_.forEach(features, ([dbName, newName]) => {
				const hasFeature = _.get(sub, `package.definition.security:${dbName}`) === true;
				result[newName] = !hasFeature;
			});

			return result
		}

		const planFeatures = {
			paywall: _.get(sub, 'package.definition.paywall') === true,
			varyingRewind: _.get(sub, 'package.definition.varyingRewind') === true,
			delayingStream: _.get(sub, 'package.definition.delayingStream') === true,
			llHls: _.get(sub, 'package.definition.llHls') === true
		}
		const bw = _.get(sub, 'package.definition.bandwidth')
		if (bw < 1000) {
			// disable domain whitelisting for this package
			planFeatures['securityFeaturesDomainWhitelist'] = true
		}

		return planFeatures
	}
	return {};
}

function vodPlanFeatures(addonSub) {
	const features = [
		['password-auth', 'securityFeaturesPasswordAuth'],
		['geo-block', 'securityFeaturesGeoBlock'],
		['geo-whitelist', 'securityFeaturesGeoWhitelist'],
		['domain-whitelist', 'securityFeaturesDomainWhitelist']];

	let result = {
		securityFeaturesPasswordAuth: true,
		securityFeaturesGeoBlock: true,
		securityFeaturesGeoWhitelist: true,
		securityFeaturesDomainWhitelist: true,
		paywall: true
	}
	_.forEach(addonSub, (sub) => {
		if (sub.category === 'vod' || (sub.package.definition.maxVodItems && parseInt(sub.package.definition.maxVodItems, 10) > 0)) {
			// explicity check if paywall feature in sub, by default paywall for VOD active
			if (_.has(sub, 'package.definition.paywall')) {
				result.paywall = _.get(sub, 'package.definition.paywall')
			}

			_.forEach(features, ([dbName, newName]) => {
				const hasFeature = _.get(sub, `package.definition.security:${dbName}`) === true;
				// Now vod is a feature in other category (live, restream)
				// We define 2 security for main category & for vod, so need to check the security:vod:featureName
				const hasFeatureVod = _.get(sub, `package.definition.security:vod:${dbName}`) === true;
				if (hasFeature || hasFeatureVod) {
				result[newName] = false
				}
			})
		}
	})
  return result
}

function schedulePlanFeatures(addonSub) {
	const sub = _.find(addonSub, { category: 'scheduled' }) || [];
	const features = [
		['loopingFeature', 'loopingFeature'],
		['multifiles', 'multifiles']
	];

	const featureList = _.reduce(features, (acc, [dbName, newName]) => {
		const hasFeature = _.get(sub, `package.definition.${dbName}`) === true;
		acc[newName] = false;
		if (!hasFeature) {
			acc[newName] = true;
		}
		return acc;
	}, {});

	return featureList;
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
