<template>
		<div class="main-wrapper d-flex">
			<app-navbar v-if="!this.$route.meta.fullScreen" />
			<div class="flex flex-wrap justify-center mt-6 mt-lg-0 w-100" :class="[{'main-content-wrapper': isLoggedIn}, {'pt-8': shouldShowMeetBar}]">
				<div v-if="shouldShowMeetBar" class="container !px-0 md:px-4 md:fixed top-0 left-0 md:left-auto z-[1010]">
					<MeetingBar />
				</div>
				<router-view :subscriptions="user && user.subscriptions" :products-features="user && user.aggregatedDefinition"
					@updateTitle="changeWinTitle" />
				<app-footer />
			</div>
		</div>
		<notifications group="error" classes="vue-notification error" style="top:10px;right:10px" />
		<notifications group="success" classes="vue-notification success" style="top:10px;right:10px" />
		<notifications group="info" classes="vue-notification info" style="top:10px;right:10px" />
		<div id="thumb-temp-loader" />
		<confirm-modal :modal-id="'billing-prompt'" message="Please settle pending subscription invoices before continue."
			ok-text="See Billing" cancel-text="Close" @modal-confirm="navigateToBilling()" />
</template>

<script>
import { mapGetters } from 'vuex';
import { activateIntercom } from '@/lib/analytics';
import AppNavbar from '@/components/layout/AppNavbar';
import AppFooter from '@/components/layout/AppFooter';
import ConfirmModal from '@/components/modals/ConfirmModal';
import UserService from '@/services/UserService';
import _ from 'lodash';
import { clearAllPreviousCache } from './services/CacheManager';
import MeetingBar from './components/ui/MeetingBar.vue';

export default {
	name: 'App',
	components: {
		AppNavbar,
		AppFooter,
		ConfirmModal,
		MeetingBar
	},
	data() {
		return {
			winHeight: null
		};
	},
	computed: {
		user() {
			return this.$store?.state?.User;
		},
		...mapGetters({
      isLoggedIn: "Auth/isLoggedIn",
			hasLiveSubscription: "User/hasLiveSubscription",
			hasVodSubscription: "User/hasVodSubscription",
			hasMultistreamSubscription: "User/hasMultistreamSubscription",
			userLoaded: "User/userLoaded"
		}),
		shouldShowMeetBar() {
			if (!this.$route.meta.fullScreen && this.userLoaded && !this.hasLiveSubscription && !this.hasVodSubscription && !this.hasMultistreamSubscription) return true
			if (!this.$route.meta.fullScreen && this.userLoaded && this.hasLiveSubscription?.trial) return true
			return false
		}
	},
	watch: {
		async $route() {
			setTimeout(this.computeWinHeight, 0);
			if (!this.user || !this.user._id) {
				await this.$store.dispatch('Auth/login');
			}
		}
	},
	async created() {
		clearAllPreviousCache()
		if (!this.user || !this.user._id) {
			await this.$store.dispatch('Auth/login');
			activateIntercom(this.user);
		}
	},
	mounted() {
		this.computeWinHeight();
		this.enableFullStory();
		this.enableORIBI();
		this.enableHeap();
	},
	methods: {
		isGuestPage() {
			const path = window.location.pathname;
			const GuestPages = ['login', 'signup', 'forgot-password', 'forgot-password/reset'].map(el => `/app/${el}`);
			return GuestPages.indexOf(`${path}`) > - 1;
		},
		authenticated() {
			return UserService.authenticated();
		},
		navigateToBilling() {
			window.location.href = '/app/manage/billing';
		},
		changeWinTitle(viewTitle) {
			let updatedTitle = 'Castr';
			if (viewTitle) {
				updatedTitle = viewTitle + ' - ' + updatedTitle;
			}
			// else
			//   updatedTitle = 'Castr | '  +  updatedTitle

			document.title = updatedTitle.trim();
		},
		computeWinHeight() {
			this.winHeight = window.innerHeight - 50 + 'px';
		},
		enableFullStory() {
			setTimeout(() => {
				if (this.authenticated()) {
					const { name, cmsEntityId: amemberUserId, email } = UserService.getUser();
					!_.isEmpty(window.FS) && window.FS.identify(amemberUserId, {
						displayName: name,
						email: email,
						dashboard: 'v2'
					});
				}
			}, 2000);
		},
		enableORIBI() {
			setTimeout(() => {
				if (this.authenticated() && window.ORIBI) {
					const { email } = UserService.getUser();
					window.ORIBI.api('setUserEmail', email);
				}
			}, 1000);
		},
		enableHeap() {
			setTimeout(() => {
				if (this.authenticated() && window.heap) {
					const { email, name } = UserService.getUser();
					window.heap.identify(email);
					window.heap.addUserProperties({ Name: name || 'Noname' });
				}
			}, 1000);
		}
	}
};
</script>

<style>
#app {
	background-color: var(--c-dark-1);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}
</style>
