<template>
	<div class="container mt-12 md:mt-4">
		<Spinner v-if="processing" text="Retrieving data..." classes="text-dark-8 mh-60" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />
		<div class="main-content flex flex-wrap lg:grid grid-cols-[calc(100%-420px-1rem)_420px] grid-rows-[min-content_1fr] auto-cols-max gap-x-4" v-else :class="streamType + '_wrap'">
			<div class="col-start-1 row-start-1 w-full w-full">
				<div class="flex tems-start w-full">
					<router-link to="/scheduler"
						class="d-none d-md-flex mt-3 h-4 w-4 items-center justify-center text-surface-8 hover:text-white mr-2"
						v-if="streamType === 'scheduled'">
						<icon-base class="" fill="none">
							<icon-chevron-left />
						</icon-base>
					</router-link>
					<router-link to="/dashboard"
						class="d-none d-md-flex mt-3 h-4 w-4 items-center justify-center text-surface-8 hover:text-white mr-2"
						v-else>
						<icon-base class="" fill="none">
							<icon-chevron-left />
						</icon-base>
					</router-link>
					<textarea v-model="streamName" ref="streamTitle" rows="1" autocomplete="off" autocorrect="off"
						autocapitalize="off" spellcheck="false" title="Edit stream name"
						class="page-title page-title_input uppercase ml-n2 pl-2 overflow-hidden form-control form-control-sm"
						@keydown.enter.prevent="preventEnter" @change="onStreamNameChange" @focus="resizeTextarea"
						@keyup="resizeTextarea"></textarea>
					<div class="d-none d-md-block mt-2" :class="this.stream.type + '_doc'" v-if="streamType != 'subsecond'">
						<a class="text-sm text-surface-6 hover:text-white hover:no-underline inline-flex items-center text-nowrap"
							target="_blank" :href="this.doc[this.stream.type].link">
							<icon-base class="mr-2 shrink-0" fill="none">
								<icon-book-open />
							</icon-base>
							{{ this.doc[this.stream.type].text }}
						</a>
						<FormButton type="link" customClasses="text-surface-6 hover:text-white text-sm flex items-center"
							v-b-modal.modal_tutorial>
							<icon-base class="mr-2" fill="none">
								<icon-play />
							</icon-base>
							Watch a tutorial
						</FormButton>
					</div>
					<drop-down-menu isIcon position="right" type="secondary" no-border class="md:hidden">
						<template #toggle-button>
							<img src="@/assets/icons/settings.svg" alt="Settings">
						</template>
						<template #menu-items>
							<ejs-tooltip v-if="this.activeTeam && this.checkTogglePermission()" :content="tooltipText">
								<drop-down-menu-item>
									<span v-if="stream.enabled">Disable</span>
									<span v-else>Enable</span>
								</drop-down-menu-item>
							</ejs-tooltip>
							<drop-down-menu-item v-else @click="showStreamToggleModal">
								<span v-if="stream.enabled">Disable</span>
								<span v-else>Enable</span>
							</drop-down-menu-item>
							<ejs-tooltip v-if="this.activeTeam && this.checkTogglePermission()" :content="tooltipText">
								<drop-down-menu-item>
									Delete
								</drop-down-menu-item>
							</ejs-tooltip>
							<drop-down-menu-item v-else @click="requestStreamDelete">
								Delete
							</drop-down-menu-item>
						</template>
					</drop-down-menu>
					<b-tooltip :target="'member-toggle___' + stream._id" placement="top" v-if="memberPermission">
						<span class="text-dark-8 text-small">
							You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
								:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
					</b-tooltip>
					<b-tooltip :target="'member-del___' + stream._id" placement="top" v-if="memberPermission">
						<span class="text-dark-8 text-small">
							You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
								:href="'mailto:' + userEmail">{{ userEmail }}</a> to gain access.</span>
					</b-tooltip>
				</div>
				<div class="d-block d-md-none" :class="this.stream.type + '_doc'" v-if="streamType != 'subsecond'">
					<a class="text-sm text-surface-6 hover:text-white hover:no-underline inline-flex items-center text-nowrap"
						target="_blank" :href="this.doc[this.stream.type].link">
						<icon-base class="mr-2 shrink-0" fill="none">
							<icon-book-open />
						</icon-base>
						{{ this.doc[this.stream.type].text }}
					</a>
					<FormButton type="link" customClasses="text-surface-6 hover:text-white text-sm flex items-center"
						v-b-modal.modal_tutorial>
						<icon-base class="mr-2" fill="none">
							<icon-play />
						</icon-base>
						Watch a tutorial
					</FormButton>
				</div>
			</div>
			<main class="order-1 md:order-none col-start-1 row-start-2 w-full">
				<stream-overview-stats :stream="stream" :media-pulse="mediaPulse" :subscription="baseSubscription"
					:video-files="videoFiles" />
				<div class="">
					<div class="flex text-tiny space-x-4 border-b border-surface-7 overflow-x-auto max-w-full my-6" :class="streamType === 'vod' ? 'vod-nav' : ''">
						<router-link v-for="(viewItem, $index) in childViews" :key="$index"
							:to="viewItem.viewName ? { name: viewItem.viewName } : {}" class="capitalize text-surface-8 hover:text-white hover:no-underline py-1 border-b-2 border-transparent flex items-center text-nowrap"
							:class="viewItem.className || null" tag="li" active-class="!border-surface-9 text-white">
							<span v-if="viewItem.name">
								{{ viewItem.name }}
							</span>
							<span v-else-if="viewItem.nameHtml" v-html="viewItem.nameHtml" /><Beta class="ml-1" title="Off" v-if="viewItem.viewName === 'LivestreamManageClipEpisodes' && !streamDvrEnabled" />
						</router-link>
					</div>
					<div class="tab-content relative mt-4">
						<p v-if="trialSubscription && $route.name !== 'LivestreamManagePaywall'" class="trial-prompt-message">
							<span>{{ trialSubscriptionPromptMessage }}</span>
							Please
							<router-link v-if="stream.type === 'scheduled'" to="/subscribe?category=restream">
								Upgrade
							</router-link>
							<router-link v-else-if="stream.type != 'scheduled'" :to="`/subscribe?category=${stream.type}`">
								Upgrade
							</router-link>
							to extend your service.
						</p>
						<router-view v-slot="{ Component }">
							<component :is="Component" :stream="stream" :primary-stream-alive="primaryStreamAlive" :backup-stream-alive="backupStreamAlive" :stream-alive="streamAlive" :media-pulse="mediaPulse"
								:video-files="videFilesForCurrentPage" :is-vod-trial="isVodTrial"
								:trial-subscription="trialSubscription" :vod-sub-expired-days="vodTrialExpiredIn"
								@stream-updated="onStreamUpdates" @refresh-stream="fetchStreamDetails" @video-update="onVideoFileUpdate"
								@video-removed="onVideoFileRemoved" @set-live-stream-countdown="liveStreamCountdownSelected"
								@live-stream-abr-changed="liveStreamAbrChanged" @dvr-recording-toggle="dvrRecordingToggle" @backup-toggle="onBackupToggle" />
						</router-view>
						<Spinner v-if="isVideoListLoading" text="Retrieving data..." classes="text-dark-8 my-3"
							spinner-color="var(--c-dark-8)" spinner-size="15px" />
					</div>

				</div>

			</main>
			<aside class="order-none md:order-1 col-start-2 row-start-1 row-span-2 max-w-full" :class="`sidebar ${streamType}_sidebar`">
				<div v-if="streamType === 'scheduled'">
					<stream-scheduling-configurator :stream="stream" :stream-alive="streamAlive" :media-pulse="mediaPulse"
						:video-files="videoFiles" @scheduler-settings-updated="onSchedulerSettingsUpdated"
						@refresh-stream="fetchStreamDetails" />
				</div>
				<schedule-stream-preview-pane v-if="streamType === 'scheduled' && schedulerSettings" :stream="stream"
					:stream-alive="streamAlive" :media-pulse="mediaPulse" :scheduler-settings="schedulerSettings"
					:toggle-processing="toggleProcessing" :scheduledStorageLimit="scheduledStorageLimit"
					@video-files="onVideoFileList" />
				<StreamPreviewPaneNew v-if="streamType !== 'scheduled'" :stream="stream" ref="sidebarStreamPreviewPane"
					:stream-alive="streamAlive" :backup-stream-alive="backupStreamAlive" :primary-stream-alive="primaryStreamAlive" :media-pulse="mediaPulse"
					:isRtmpPullEnabled="isRtmpPullEnabled" @stream-updated="onStreamUpdates" class="mb-4" />
				<div :class="streamType + '_action'" class="stream-action-area mt-4">
					<div v-if="statusProcessing">
						<i class="fas fa-spinner fa-spin" />
					</div>
					<div v-else>
						<div class="d-none d-md-flex justify-content-end" :class="{ 'pe-none': !userloaded }">
							<div class="flex" v-if="memberPermission">
								<div v-if="this.activeTeam && this.checkTogglePermission()">
									<ejs-tooltip :content="tooltipText">
										<FormButton type="secondary" :label="stream.enabled ? 'Disable' : 'Enable'"  :disabled="toggleProcessing" class="mr-2" />
									</ejs-tooltip>
								</div>
								<div v-else>
									<FormButton type="secondary" :label="stream.enabled ? 'Disable' : 'Enable'"  :disabled="toggleProcessing" class="mr-2" @click="showStreamToggleModal" />
								</div>
								<div v-if="this.activeTeam && this.checkDelPermission()">
									<ejs-tooltip :content="tooltipText">
										<form-button isIcon type="danger"> 
											<icon-base fill="none">
												<icon-bin />
											</icon-base>
										</form-button>
									</ejs-tooltip>
								</div>
								<div v-else>
									<form-button isIcon type="danger" @click="requestStreamDelete"> 
										<icon-base fill="none">
											<icon-bin />
										</icon-base>
									</form-button>
								</div>
							</div>
							<div class="flex" v-else>
								<FormButton type="secondary" :label="stream.enabled ? 'Disable' : 'Enable'"  :disabled="toggleProcessing" class="mr-2" @click="showStreamToggleModal" />
								<form-button isIcon type="danger" @click="requestStreamDelete"> 
									<icon-base fill="none">
										<icon-bin />
									</icon-base>
								</form-button>
							</div>
						</div>
					</div>
				</div>
			</aside>
		</div>
		<add-stream-modal @new-channel="onNewStream" />
		<confirm-modal message="Would you like to delete this stream and all of its content?" modal-type="danger"
			@modal-confirm="onStreamDeleteConfirm" />

		<confirm-modal message="Please update your subscription to get more concurrent stream." modal-id="increase-limit"
			modal-type="danger" ok-text="Yes" cancel-text="Cancel" @modal-confirm="gotoUpgrade" />
		<confirm-modal
			message="Attention: Multistream has been discontinued. Set up a new All-In-One Stream today for uninterrupted streaming with enhanced features, at no extra cost."
			modal-id="create-aio" ok-text="Create All in One" cancel-text="Cancel" @modal-confirm="openCreatestream" />
		<confirm-modal modalId="delete-vod-stream" message="Permanently delete all files in this folder?"
			modal-type="danger" body="Yours files in VOD section inside the stream will also be deleted."
			@modal-confirm="onStreamDeleteConfirm" />
		<confirm-modal modalId="delete-vod-stream-showcase" message="Permanently delete all files in this folder?"
			modal-type="danger"
			body="This folder contains video(s) which are selected in the showcase. Deleting the folder will remove videos from the showcase as well."
			@modal-confirm="onStreamDeleteConfirm" />

		<confirm-modal modal-size="sm" modal-id="scheduled-status-warning" modal-type="danger"
			message="Stream is already scheduled" ok-text="Disable anyway" cancel-text="Cancel"
			body="If you disable the stream, it won’t start at the scheduled time."
			@modal-confirm="(scheduledAlert = false); toggleStatus()" />

		<alert-modal modal-id="alert-chat-down"
			message="We have encountered unusual behaviour in our chat app and would appreciate your patience while our team get things back online."
			ok-text="I Understand" class="text-center" />

		<b-modal modal-class="modal_tutorial" id="modal_tutorial" centered
			v-if="streamType != 'vod' && streamType != 'subsecond'">
			<iframe :src="this.doc[this.stream.type].iframe" title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
				v-if="this.stream"></iframe>
		</b-modal>

		<toggle-stream-modal v-if="stream" :stream="stream" @modal-toggle="toggleStatus"
			@toggle-processing="onToggleProcessing" @toggle-done="onToggleDone"/>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from "@/components/ui/Spinner.vue";
import StreamStatsService from '@/services/StreamStatsService';
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService';
import UserService from '@/services/UserService';
import StreamSchedulingConfigurator from '@/views/SchedulerDash/StreamSchedulingMode.vue';
import StreamOverviewStats from '@/views/StreamsDash/StreamOverviewStats.vue';
import StreamPreviewPaneNew from '@/views/StreamsDash/StreamPreviewPaneNew.vue';
import ScheduleStreamPreviewPane from '@/views/StreamsDash/ScheduleStreamPreviewPane.vue';
import VodStreamPreviewPane from '@/views/StreamsDash/VODStreamPreviewPane.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import utils from '@/utils';
import PaywallService from '@/services/PaywallService';
import ToggleStreamModal from '@/components/modals/ToggleStreamModal.vue';
import AddStreamModal from '@/components/modals/AddStreamModal.vue';
import IconBase from '../../components/icon/IconBase.vue';
import IconChevronLeft from '../../components/icon/IconChevronLeft.vue';
import IconBookOpen from '../../components/icon/IconBookOpen.vue';
import IconPlay from '../../components/icon/IconPlay.vue';
import FormButton from '@/components/Atoms/FormButton.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import IconBin from '../../components/icon/IconBin.vue';
import { TooltipComponent } from "@syncfusion/ej2-vue-popups"

export default {
	name: 'StreamManage',
	components: {
		Spinner,
		StreamOverviewStats,
		StreamSchedulingConfigurator,
		StreamPreviewPaneNew,
		VodStreamPreviewPane,
		ScheduleStreamPreviewPane,
		AlertModal,
		AddStreamModal,
		ConfirmModal,
		ToggleStreamModal,
		IconBase,
		IconChevronLeft,
		IconBookOpen,
		IconPlay,
		IconBin,
		FormButton,
		DropDownMenu,
		DropDownMenuItem,
		"ejs-tooltip": TooltipComponent
	},
	data() {
		return {
			notAllowed: false,
			currentPage: 1,
			perPage: 20,
			isVideoListLoading: false,
			videoFileSearchText: '',
			selectedFilteringTags: [],
			scopeAlive: true,
			statusProcessing: false,
			nameEdit: false,
			trialSubscription: null,
			processing: true,
			processingMessage: null,
			stream: null,
			streamAlive: false,
			streamId: null,
			streamName: null,
			streamFps: null,
			mediaPulse: null,
			backupMediaPulse: null,
			backupStreamAlive: false,
			primaryMediaPulse: null,
			primaryStreamAlive: false,
			mediaPulseTimeoutCtrl: null,
			windowHeight: 0,
			videoFiles: [],
			userSubscription: [],
			isVodPaid: false,
			isVodTrial: false,
			emptyVodSub: true,
			vodTrialExpiredIn: 0, // days
			schedulerSettings: null,
			scheduledAlert: true,
			isRtmpPullEnabled: null,
			scheduledAlert: true,
			streamDvrEnabled: null,
			scheduledStorageLimit: null,
			aioStorageLimit: null,
			childViewList: [
				// simulcast navigations
				{
					streamType: 'restream',
					name: 'destinations',
					viewName: 'SimulcastManageDashboard'
				},
				{
					streamType: 'restream',
					name: 'Analytics',
					viewName: 'LivestreamManageAnalytics'
				},
				{
					streamType: 'restream',
					name: 'recordings',
					viewName: 'SimulcastManageVodEpisodes'
					// validate(s) { return s.enabled }
				},
				{
					streamType: 'restream',
					name: 'Alerts',
					viewName: 'StreamsManageAlerts'
				},
				// {
				// 	streamType: 'restream',
				// 	nameHtml:
				// 		'<i class="fa fa-comment-alt"></i>&nbsp;&nbsp;chat overlay',
				// 	title: 'please enable stream to access stream recordings',
				// 	viewName: 'SimulcastManageChat'
				// },

				// livestream navigations
				{
					streamType: 'live',
					name: 'destinations',
					viewName: 'LivestreamManageDashboard'
				},
				{
					streamType: 'live',
					name: 'Analytics',
					// nameHtml: 'analytics <span class="beta-btn2">Improved<span/>',
					viewName: 'LivestreamManageAnalytics'
				},
				{
					streamType: 'live',
					nameHtml: `Live to VOD`,
					viewName: 'LivestreamManageClipEpisodes'
				},
				{
					streamType: 'live',
					nameHtml: 'paywall <span class="badge badge-dark-3 text-lowercase ml-2 mr-auto"></span>',
					viewName: 'LivestreamManagePaywall'
				},
				{
					streamType: 'live',
					name: 'Alerts',
					viewName: 'StreamsManageAlerts'
				},
				{
					streamType: 'live',
					nameHtml: 'Advertising',
					viewName: 'StreamsManageAdvertising'
				},
				{
					streamType: 'live',
					name: 'Settings',
					viewName: 'LivestreamManageSettings'
				},
				{
					streamType: 'live',
					name: 'Backup',
					viewName: 'StreamsManageSettingsBackup'
				},
				{
					streamType: 'ipcam',
					name: 'dashboard',
					viewName: 'CamstreamManageDashboard'
				},
				{
					streamType: 'ipcam',
					name: 'vod episodes',
					viewName: 'CamstreamManageVodEpisodes'
				},

				// scheduled stream navigations
				{
					streamType: 'scheduled',
					name: 'destinations',
					viewName: 'ScheduledStreamManageDashboard'
				},

				// vod stream navigations
				{
					streamType: 'vod',
					name: 'videos',
					viewName: 'VODBucketVideos'
				},
				{
					streamType: 'vod',
					name: 'settings',
					viewName: 'VODBucketSettings'
				},
				{
					streamType: 'vod',
					nameHtml: 'Advertising <span class="badge badge-dark-3 badge_beta ml-2 mr-auto">beta</span>',
					viewName: 'StreamsManageVodAdvertising'

				},
				{
					streamType: 'vod',
					nameHtml: 'paywall <span class="badge badge-dark-3 text-lowercase ml-2 mr-auto"></span>',
					viewName: 'VODstreamManagePaywall'
				},
			],
			doc: {
				restream: {
					text: 'How Multistream Works',
					link: 'https://docs.castr.com/en/articles/4354481-how-to-start-multistreaming-on-castr',
					iframe: 'https://www.youtube.com/embed/vmq22PzN-HI'
				},
				live: {
					text: 'How All in One Stream works',
					link: 'https://docs.castr.com/en/articles/4348730-how-to-start-an-all-in-one-stream-in-castr',
					iframe: 'https://www.youtube.com/embed/Txy2k4IAIWI'
				},
				scheduled: {
					text: 'How Pre-recorded Stream works',
					link: 'https://docs.castr.com/en/articles/2995226-how-to-start-streaming-pre-recorded-videos',
					iframe: 'https://www.youtube.com/embed/zhe67f3iHwU'
				}
			},
			toggleProcessing: false,
		};
	},
	computed: {
		...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
		userEmail() {
			return this.$store.state.User.email;
		},
		userloaded() {
			return this.$store.state.User.userloaded;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		memberPermission() {
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		videFilesForCurrentPage() {
			const start = (this.currentPage - 1) * this.perPage
			return this.filteredVideoFileList.slice(start, start + this.perPage)
		},
		/** returns the list of video files filtered by tags and search text @returns {[]} */
		filteredVideoFileList() {
			const search = this.videoFileSearchText.trim().toLowerCase()
			return this.videoFiles.filter(file => {
				const searchResult = !search || file.fileName?.toLowerCase().includes(search)
				const tagsResult = !this.selectedFilteringTags.length || _.intersection(file?.tags, this.selectedFilteringTags).length === this.selectedFilteringTags.length
				return searchResult && tagsResult
			})
		},
		paywallFeature() {
			const user = UserService.getUser(this.activeTeam);
			if (user.paywallFeature) {
				return true;
			}

			const baseSub = this.baseSubscription;
			if (this.stream.type === 'live' && baseSub) {
				return _.get(baseSub, ['package', 'definition', 'paywall'], false);
			}

			return false;
		},
		advertisingFeature() {
			const userList = ['5f210874c8c7dc3c2fd283c1', '5f50620cff77767dd15db55c', '5b2e1c65f1e043163435d4e9', '5e94b43350920d5a9fe838f9'];
			// return userList.includes(UserService.getUserId());
			return true;
		},
		subscriptions() {
			return this.$store.state.User.subscriptions.map(sub => _.assign({ category: 'restream' }, sub));
		},
		productsFeatures() {
			return this.$store.state.User.aggregatedDefinition;
		},
		baseSubscription() {
			return this.subscriptions.find(sub => sub.category && sub.category.includes(this.stream.type));
		},
		streamType() {
			if (this.stream) {
				return this.stream.type;
			}
		},
		canEncodeVideos() {
			const encodingUnits = _.get(this.baseSubscription, 'package.definition.encodingUnits')
			return encodingUnits > 0
		},
		childViews() {
			return (this.stream && this.childViewList.filter(v => {
				if (v.viewName === 'StreamsManageAdvertising' && !this.advertisingFeature) {
					return false;
				}
				if (v.name === 'paywall' && !this.paywallFeature) {
					return false;
				}

				if (v.viewName === 'LivestreamManageClipEpisodes' && !this.streamDvrEnabled) {
					v.nameHtml = 'Live to VOD <span class="badge badge-dark-3 badge_beta ml-2 mr-auto">Off</span>'
				} else if (v.viewName === 'LivestreamManageClipEpisodes' && this.streamDvrEnabled) {
					v.nameHtml = 'Live to VOD'
				}

				// if (v.viewName === 'VODstreamManagePaywall' && this.stream.type === 'vod' && this.stream.liveParent) {
				// 	return false;
				// }

				const isIncluded = v.streamType === this.stream.type;
				return isIncluded && v.validate ? v.validate(this.stream) : isIncluded;
			}));
		},
		streamResolution() {
			const { streamAlive, mediaPulse } = this;
			return (streamAlive && mediaPulse && mediaPulse.width) ? `${mediaPulse.width} x ${mediaPulse.height}` : null;
		},
		trialSubscriptionPromptMessage() {
			if ((this.stream.type == 'vod') && (this.userSubscription.maxVodItems > 0)) {
				this.trialSubscription = null
				return
			}
			var baseSub = this.baseSubscription;
			let remainingDays = 7;
			if (baseSub) {
				const subEndTime = new Date(baseSub.cend);
				let diff = subEndTime - new Date();
				if (_.isNumber(diff)) {
					diff /= 24 * 3600 * 1000;
					diff = Math.floor(diff);
					remainingDays = diff;
				}
			}
			let msg = `Your trial plan will end in ${remainingDays || 7} days.`;
			if (remainingDays <= 0) {
				msg = `Your trial period has expired.`;
			}

			return msg;
		},
		tooltipText() {
			return `
				<span class="text-dark-8 text-small">
					You don’t have permission. Contact the team owner at <a class="text-dark-8 text-small"
					:href="'mailto:${this.userEmail}'">${this.userEmail}</a> to gain access.
				</span>`
		} 
	},
	updated() {
		this.$nextTick(() => {
			let area = this.$refs.streamTitle;
			if (area) {
				area.style.height = area.scrollHeight + 'px';
			}
		})
	},
	async mounted() {

		await this.$store.dispatch('User/getactiveTeam', null, { root: true });
		await this.$store.dispatch('Streams/getStreams');
		this.windowHeight = window.innerHeight - 200;
		this.streamId = this.$route.params.streamId;

		// get stream details
		await this.setupStream();

		if (!this.stream) return;
		if (localStorage.getItem('notAllowed' + this.stream.type)) {
			this.notAllowed = (localStorage.getItem('notAllowed' + this.stream.type) == 'true');
		}
		if ((this.streamType === 'scheduled') && localStorage.getItem('notAllowedscheduled')) {
			this.notAllowed = (localStorage.getItem('notAllowedscheduled') == 'true');
		}
		// Get storage usage
		if (this.stream.type === 'scheduled') {
			let maxSize = null;
			let storage = null;
			const subscriptions = this.$store.state.User.subscriptions;
			const activeScheduledSubs = _.filter(_.cloneDeep(subscriptions), (sub) => sub.category === 'scheduled' && sub.enabled === true)
			console.log(activeScheduledSubs);
			activeScheduledSubs.forEach(sub => {
				try {
					if (sub.definitionOverride && sub.definitionOverride.schedulerMaxVideoSize) {
						sub.package.definition.schedulerMaxVideoSize = parseFloat(sub.definitionOverride.schedulerMaxVideoSize)
					}
					if (maxSize) {
						const tmpMaxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						if (tmpMaxSize > maxSize) {
							maxSize = tmpMaxSize;
							this.activeScheduledSub = sub
						}
					} else {
						maxSize = parseFloat(sub.package.definition.schedulerMaxVideoSize);
						this.activeScheduledSub = sub
					}
				} catch (error) {
					console.error(error);
				}
			})
			const res = activeScheduledSubs.length > 0 ? await MetricsService.getSubscriptionStorage(UserService.getUserId(this.activeTeam), this.activeScheduledSub.package._id) : {}
			this.scheduledStorageLimit = res ? res : {}
		}

		if (this.stream.type === 'vod') {
			const subscriptions = this.$store.state.User.subscriptions;
			this.activeAIOSub = _.find(subscriptions, (sub) => sub.category === 'live' && sub.enabled === true)
			if (this.activeAIOSub) {
				const res = await MetricsService.getSubscriptionStorage(UserService.getUserId(this.activeTeam), this.activeAIOSub.package._id)
				this.aioStorageLimit = res ? res : {}
			}
		}

		this.streamDvrEnabled = this.stream.metadata.dvrArchive;

		// we can't just update region to new one due HLS binded to region
		// we do hack with multiple regions: region & uiRegion assigned for single stream
		if (!this.stream.uiRegion) {
			this.stream.uiRegion = this.stream.region;
		}

		await this.loadVOD();
		// event tracking
		window.trackEvent(this.stream.name + ' - Stream Page', this.stream);

		// update win title
		this.$emit(
			'updateTitle',
			_.toUpper(this.stream.name) + ' - Castr Restreams'
		);

		if (this.baseSubscription) {
			this.trialSubscription = /trial/gi.test(this.baseSubscription.package.name);
		}

		if (this.streamType === 'vod' || this.streamType === 'scheduled') {
			await this.fetchStreamVideoFileList();
		}
		this.processing = false;

	},
	destroyed() {
		this.scopeAlive = false;
		this.unsubscribeMediaPulse();
	},
	methods: {
		onBackupToggle(obj) {
			this.$refs.sidebarStreamPreviewPane.failoverIngestToggleState = obj.status
		},
		async getStreamnumber(number = true) {
			if (this.userloaded) {
				let userSubscription = { subscription: await this.baseSubscription, addonSubscriptions: await this.addonSubscriptions };
				let scheduledPackage = userSubscription && userSubscription.addonSubscriptions.filter(function (single) {
					return single.package.category == 'scheduled'
				});
				let streamnumber = null
				let streamCountarray = [];
				if (scheduledPackage && (scheduledPackage.length > 1)) {
					const allowedStream = _.map(scheduledPackage, function (single) {
						if (single.enabled) {
							let streamCountoverride = _.get(single, ['definitionOverride', 'streams']);
							if (streamCountoverride) {
								streamCountarray.push(streamCountoverride);
							} else {
								streamnumber = _.get(single, ['package', 'definition', 'streams']);
								streamCountarray.push(streamnumber);
							}
						}
					});

				} else {
					if (scheduledPackage && (scheduledPackage.length > 0) && scheduledPackage[0].enabled) {
						let streamCountoverride = _.get(scheduledPackage[0], ['definitionOverride', 'streams']);
						if (streamCountoverride) {
							streamCountarray.push(streamCountoverride);
						} else {
							streamnumber = _.get(scheduledPackage[0], ['package', 'definition', 'streams']);
							streamCountarray.push(streamnumber);
						}
					}
				}

				if (streamCountarray.length) {
					if (number) {
						return (streamCountarray.length > 1 ? Math.max.apply(Math, streamCountarray) : streamCountarray[0]);
					} else {
						return '/' + (streamCountarray.length > 1 ? Math.max.apply(Math, streamCountarray) : streamCountarray[0]);
					}
				}
			}
		},
		dvrRecordingToggle(status) {
			this.streamDvrEnabled = status
			this.childViewList[6].nameHtml = 'Live to VOD'
			if (!status) {
				this.childViewList[6].nameHtml += '<span class="badge badge-dark-3 badge_beta ml-2 mr-auto">Off</span>'
			}

			this.$set(this.childViewList, 6, this.childViewList[6])
		},
		checkDelPermission() {
			let permission = this.memberPermission && (
				((this.stream.type === 'live') && !this.memberPermission.liveStreamDelete) ||
				((this.stream.type === 'restream') && !this.memberPermission.recordedStreamDelete) ||
				((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamDelete)
			);
			return permission;
		},
		checkTogglePermission() {
			let permission = this.memberPermission && (
				((this.stream.type === 'live') && !this.memberPermission.liveStreamToggle) ||
				((this.stream.type === 'restream') && !this.memberPermission.recordedStreamToggle) ||
				((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamToggle));
			return permission;
		},
		async loadVOD() {
			if (!this.stream) return
			const userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions };
			this.getRmtpPullPermission(userSubscription)

			if (this.stream && this.stream.type !== 'vod') {
				await this.subscribeMediaPulse();
			} else {
				this.userSubscription = userSubscription
				this.getVodSubExpiredDays()
			}
		},
		onSearchTextChanged(searchText) {
			this.isVideoListLoading = true
			this.debouncedSearch(searchText)
		},
		debouncedSearch: _.debounce(function (searchText) {
			this.videoFileSearchText = searchText
			this.isVideoListLoading = false
		}, 300),
		onTagSelected(filteredTags) {
			this.selectedFilteringTags = filteredTags.map(tag => tag.id)
		},
		getRmtpPullPermission(userSubscription) {
			try {
				// select the correct package from base + additional packages
				const packages = []

				// base subscription
				if (userSubscription?.subscription) {
					packages.push(userSubscription.subscription.package)
				}
				// add addon packages
				if (userSubscription?.addonSubscriptions?.length) {
					packages.push(...userSubscription.addonSubscriptions.map(sub => sub.package))
				}

				const packageForCurrentStream = packages.find(pack => pack.category === this.stream.type)

				// set the default value to 'true'
				// if the user has the correct package with correct permission, but the `enableRtmp` is not defined
				const userSubDefinition = packageForCurrentStream.definition
				this.isRtmpPullEnabled = 'enableRtmp' in userSubDefinition ? userSubDefinition.enableRtmp : true
			} catch {
				// under any other cicumstance, disable RTMP pull
				this.isRtmpPullEnabled = false
			}
		},
		liveStreamCountdownSelected(d) {
			let val = d;
			if (d) {
				val = Math.max(new Date(d) - Date.now(), 0);
			}
			this.$refs.sidebarStreamPreviewPane.streamCountdownSecLeft = val;
			this.$refs.sidebarStreamPreviewPane.countdownDate = d
		},
		liveStreamAbrChanged(d) {
			this.$refs.sidebarStreamPreviewPane.streamAbrToggleState = d;
		},
		requestChatStatus() {
			this.$root.$emit('bv::show::modal', 'alert-chat-down');
		},
		openCreatestream() {
			this.$root.$emit('bv::show::modal', 'modal-add-stream');
		},
		onStreamUpdates(updates) {
			this.stream = _.assign({}, this.stream, updates);
		},
		async setupStream() {
			// get stream details
			try {
				const stream = await this.fetchStreamDetails();
				const meta = await StreamService.getStreamMetadata(stream._id);
				stream.metadata = meta;
				this.streamName = stream.name;
			} catch (err) {
				// redirect to stream list
				console.log(err);
				this.$router.push({ name: 'ChannelList' });
				this.$notify({
					group: 'error',
					title: err.error,
					text: err.message
				});
			}
		},
		async onNewStream(stream, regionDetails) {
			this.$notify({ group: 'success', text: 'Stream deployed successfully' });

			// setTimeout(() => {
			let redirectPath = '/multistreams/';
			if (stream.type === 'live') {
				redirectPath = '/livestreams/';
			} else if (stream.type === 'ipcam') {
				redirectPath = '/ipcams/';
			} else if (stream.type === 'scheduled') {
				redirectPath = '/scheduled/';
			} else if (stream.type === 'vod') {
				redirectPath = '/vods/';
			}

			redirectPath += stream._id;

			await this.$router.push({ path: redirectPath });
			window.location.reload()
		},
		async fetchStreamDetails(cb) {
			const stream = await StreamService.getStream(this.streamId);
			this.stream = stream;
			if (cb && cb.constructor === Function) {
				cb();
			}
			return stream;
		},
		async onStreamNameChange() {
			if (!this.streamName) this.streamName = this.stream.name;

			if (this.stream.name === this.streamName) return;

			// try changing stream name
			try {
				await StreamService.setStreamName(this.streamId, this.streamName);
				PaywallService.updateStreamPaywall(this.streamId, { title: this.streamName }).catch(err => {
					console.error('update paywall stream name failed: ', err)
				});

				// track event
				window.trackEvent(
					`Updated stream name ${this.stream.name} -> ${this.streamName}`
				);
			} catch (err) {
				this.streamName = this.stream.name;
				this.$notify({
					group: 'error',
					title: "Couldn't change stream name",
					text: err.message
				});
			}
		},
		subscribeMediaPulse() {
			const streamKey = this.stream.key;
			const self = this;
			(async function loop() {
				if (!self.scopeAlive) return;

				let timeoutInterval = 5000;
				let shouldUpdatePulse = self.stream.enabled;
				if (document.hidden) {
					shouldUpdatePulse = false;
					timeoutInterval = 6000;
				}

				if (shouldUpdatePulse) {
					if (!document.hasFocus()) {
						timeoutInterval = 14000;
					}
				}

				if (shouldUpdatePulse) {
					try {
						const pulseObject = await StreamStatsService.getStreamMediaPulse(
							streamKey
						);
						self.onMediaPulse(pulseObject);

						// console.log('mediPulse stream', self.stream)
						if (self.stream.failoverIngest) {
							const backupPulseObject = await StreamStatsService.getStreamMediaPulse(
								`${streamKey}_backup`
							);
							self.onBackupMediaPulse(backupPulseObject);

							const primaryPulseObject = await StreamStatsService.getStreamMediaPulse(
								`${streamKey}_primary`
							);
							self.onPrimaryMediaPulse(primaryPulseObject);
						}
					} catch (e) { }
				}

				if (self.mediaPulse && self.mediaPulse.withoutPushStats) {
					try {
						const pushIds = [];
						self.stream.platforms.forEach(p => {
							if (p.metaId && p.enabled) pushIds.push(p.metaId);
						});

						if (pushIds.length) {
							const pushStats = await MetricsService.getPublishPlatformsPulse(
								pushIds,
								self.stream.region._id,
								!!self.stream.pullUrl
							);
							self.onPublishPlatformsPulse(pushStats);
						}
					} catch (e) { }
				}

				// reschedule
				self.mediaPulseTimeoutCtrl = setTimeout(loop, timeoutInterval);
			})();
		},
		onMediaPulse(pulseObject = {}) {
			if (this.mediaPulse && this.mediaPulse.withoutPushStats) {
				const oldPushStats = this.mediaPulse.push_stats;
				pulseObject.push_stats = oldPushStats;
			}

			this.mediaPulse = pulseObject;

			this.streamAlive =
				this.stream.enabled && this.mediaPulse && this.mediaPulse.alive;
			this.streamFps = this.mediaPulse.fps;
		},
		onBackupMediaPulse(pulseObject = {}) {
			// console.log('onBackupMediaPulse', pulseObject)
			this.backupMediaPulse = pulseObject;
			this.backupStreamAlive =
				this.stream.enabled && this.backupMediaPulse && this.backupMediaPulse.alive;
		},
		onPrimaryMediaPulse(pulseObject = {}) {
			// console.log('onBackupMediaPulse', pulseObject)
			this.primaryMediaPulse = pulseObject;
			this.primaryStreamAlive =
				this.stream.enabled && this.primaryMediaPulse && this.primaryMediaPulse.alive;
		},
		onPublishPlatformsPulse(pushStats = {}) {
			const push_stats = {};
			this.stream.platforms.forEach(p => {
				const mid = p.metaId;
				if (pushStats[mid]) {
					const pushUrl = composePlatformPushUrl(p);
					push_stats[pushUrl] = pushStats[mid].alive ? 1 : 0;
				}
			});

			this.mediaPulse = { ...this.mediaPulse, push_stats };
		},
		unsubscribeMediaPulse() {
			clearTimeout(this.mediaPulseTimeoutCtrl);
		},
		async toggleStatus(ev, newStatus) {
			ev && ev.preventDefault();
			ev && ev.stopPropagation();
			if (this.stream.type === 'scheduled' && this.stream.enabled && this.schedulerSettings.mode == 'datetime') {
				let offset = this.schedulerSettings.datetime - Date.now()
				offset /= 1000;
				if (this.scheduledAlert && !(offset <= 0)) {
					this.$root.$emit('bv::show::modal', 'scheduled-status-warning');
					return;
				}
			}

			const oldStatus = this.stream.enabled;
			newStatus = newStatus === undefined ? !oldStatus : newStatus;

			// this.statusProcessing = true;
			this.stream.enabled = newStatus;
			if (!newStatus) {
				this.scheduledAlert = true
			}
			try {
				await StreamService.toggleStream(this.stream._id);
				// this.stream.enabled = newStatus;

				window.trackEvent(
					`${newStatus ? 'Enabled' : 'Disabled'} stream: ${this.stream.name
					}`,
					this.stream
				);
			} catch (err) {
				this.stream.enabled = oldStatus;

				this.$notify({
					group: 'error',
					title: "Couldn't toggle stream status",
					text: err.message
				});

				if (err.message && err.message.indexOf('upgrade') > -1) {
					this.$root.$emit('bv::show::modal', 'billing-prompt');
				}
			}

			this.statusProcessing = false;
		},
		onToggleProcessing(status) {
			this.toggleProcessing = status
		},
		onToggleDone(){
			this.fetchStreamDetails();
		},
		requestStreamDelete() {
			if (this.activeTeam && this.checkDelPermission()) return;
			if (!this.activeTeam || !(((this.stream.type === 'live') && this.memberPermission && !this.memberPermission.liveStreamDelete) || ((this.stream.type === 'restream') && this.memberPermission && !this.memberPermission.recordedStreamDelete))) {
				this.$root.$emit('bv::show::modal', 'modal-confirm');
			}
		},
		async onStreamDeleteConfirm() {
			this.processing = true;
			this.processingMessage = 'Deleting stream';

			let streamId = this.streamId;
			const pseudoVodStream = this.stream.liveParent ? true : false;
			if (pseudoVodStream) {
				streamId = this.stream.liveParent;
			}

			// Try to delete its dvr
			let vodEpisodes
			let permanentDvrArchives
			try {
				vodEpisodes = await StreamService.getStreamDvrArchives(streamId);
			} catch (e) {
				if (!pseudoVodStream) {
					this.$notify({
						group: 'error',
						text: 'could not retreive saved vod(s)'
					});
				}
			}

			vodEpisodes = _.map(vodEpisodes, vodEpisode => {
				const ep = _.assign({}, vodEpisode, {
					startTimeDate: new Date(vodEpisode.startTime),
					endTimeDate: new Date(vodEpisode.endTime)
				});

				ep.fileName = `${moment(new Date(String(ep.startTimeDate))).fromNow()}`
				ep.fileName = ep.fileName.replace('a few seconds ago', 'a seconds ago');
				ep.creationTime = ep.startTimeDate;

				ep.live = false;
				ep.expanded = false;
				ep.streamType = this.stream.type;
				ep.blocking = false;
				ep.size = ep.size ? this.$filters.bytes(ep.size, false, 2) : '--';

				ep.exportProcessing = false;
				ep.exportStatusTimer = null;

				return ep;
			});

			permanentDvrArchives = _.filter(vodEpisodes, { exportStatus: 'EXPORTED' }) || []
			permanentDvrArchives.forEach(async (video) => {
				const q = {
					streamId: streamId,
					from: video.from,
					duration: video.duration,
					abr: video.abr,
					namedHls: video.namedHls
				};
				if (pseudoVodStream || !video.pseudoVod) {
					await StreamService.deleteStreamDvrArchive(q);
				}
			});

			// try deleting stream
			try {
				await StreamService.deleteStream(this.stream._id);
				// track event
				window.trackEvent(
					`Deleted stream ${this.stream.name}`,
					this.stream
				);
				if (this.stream.type == 'vod') {
					this.$router.push({ name: 'VodBucket' });
				} else if (this.stream.type == 'scheduled') {
					this.$router.push({ name: 'Scheduler' });
				} else {
					this.$router.push({ name: 'ChannelList' });
				}

			} catch (err) {
				console.log(err);
				// redirect to stream list
				this.$notify({
					group: 'error',
					title: err.error,
					text: err.message
				});
			}

			this.processing = false;
		},
		async fetchStreamVideoFileList() {
			let list = [];
			try {
				list = await StreamService.getStreamPlaylist(this.stream._id);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not retreive playlist videos'
				});
			}

			list = _.compact(list).map(this.prepareVideoFileItem);

			this.videoFiles = list;
			this.playlistProcessing = false;
		},
		prepareVideoFileItem(videoFile) {
			videoFile.expanded = false;
			videoFile.removing = false;
			videoFile.statusProcessing = false;
			videoFile.uploadTime = new Date(videoFile.uploadTime);
			return videoFile;
		},
		onVideoFileList(videoFileList) {
			const list = _.compact(videoFileList).map(this.prepareVideoFileItem);
			this.videoFiles = list;
		},
		onVideoFileAdded(videoItem) {
			videoItem = this.prepareVideoFileItem(videoItem);
			this.videoFiles.unshift(videoItem);
		},
		onVideoFileUpdate(videoId, updates) {
			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			if (vindex > -1) {
				const videoItem = _.assign({}, this.videoFiles[vindex], updates);
				this.videoFiles = utils.updateArrayItem(
					this.videoFiles,
					videoItem,
					vindex
				);
			}
		},
		onVideoFileRemoved(videoId) {
			const vindex = _.findIndex(this.videoFiles, { id: videoId });
			this.videoFiles = utils.removeArrayItem(this.videoFiles, vindex);
		},
		onSchedulerSettingsUpdated(updatedConfig) {
			this.schedulerSettings = updatedConfig;
		},
		getVodSubExpiredDays() {
			let originalVodSub;
			let paidSub;
			let trialSub;
			let hasMaxVodItems = false;
			if (_.isArray(this.userSubscription)) {
				this.userSubscription.forEach((sub) => {
					if (sub.category === 'vod') this.emptyVodSub = false;
					if (sub.category === 'vod' && sub.package.baseCharge === 0) {
						trialSub = sub;
						this.isVodTrial = true;
					}
					if (sub.category === 'vod' && sub.package.baseCharge > 0 && sub.enabled) {
						originalVodSub = sub;
					}
					if (sub.category !== 'vod' && sub.package.definition.maxVodItems && parseInt(sub.package.definition.maxVodItems, 10) > 0) {
						paidSub = sub;
						if (sub.enabled === true) hasMaxVodItems = true;
					}
				});
			}
			if (_.isObject(this.userSubscription)) {
				this.userSubscription.addonSubscriptions.forEach((sub) => {
					if (sub.category === 'vod') this.emptyVodSub = false;
					if (sub.category === 'vod' && sub.package.baseCharge === 0) {
						trialSub = sub;
						this.isVodTrial = true;
					}
					if (sub.category === 'vod' && sub.package.baseCharge > 0 && sub.enabled) {
						originalVodSub = sub;
					}
					if (sub.category !== 'vod' && sub.package.definition.maxVodItems && parseInt(sub.package.definition.maxVodItems, 10) > 0) {
						paidSub = sub;
						if (sub.enabled === true) hasMaxVodItems = true;
					}
				});
			}
			if (trialSub) {
				this.vodTrialExpiredIn = moment(trialSub.cend).diff(moment(), 'day');
			}

			if (paidSub) {
				this.isVodPaid = true;
				let paidSubExpiredIn = moment(paidSub.cend).diff(moment(), 'day');

				// Compare with the previous expire day to get which is available
				if (trialSub && this.vodTrialExpiredIn > paidSubExpiredIn) {
					paidSubExpiredIn = this.vodTrialExpiredIn;
					this.isVodPaid = false;
				}

				this.vodTrialExpiredIn = paidSubExpiredIn;

				if (hasMaxVodItems) {
					this.isVodTrial = false;
					this.vodTrialExpiredIn = 1;
				}
			}

			if (originalVodSub) {
				this.isVodPaid = true;
				const originalVodSubExpiredIn = moment(originalVodSub.cend).diff(moment(), 'day');

				if (originalVodSubExpiredIn > this.vodTrialExpiredIn) {
					this.vodTrialExpiredIn = originalVodSubExpiredIn;
					this.isVodTrial = false;
				}
			}

			return this.vodTrialExpiredIn;
		},
		resizeTextarea: function (e) {
			let area = e.target;
			area.style.height = area.scrollHeight + 'px';
		},
		gotoUpgrade() {
			window.location.href = `/app/subscribe?category=live`;
		},
		preventEnter(e) {
			e.preventDefault();
		},
		checkActivems() {
			let hasActivems = false;
			const subscriptions = this.$store.state.User.subscriptions;
			subscriptions.map((s) => {
				if (s && s.enabled && s.package.category == 'restream') {
					hasActivems = true;
				}
			});
			return hasActivems;
		},
		showStreamToggleModal(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			if (!this.checkActivems() && this.stream.type == 'restream' && !this.stream.enabled) {
				// this.$root.$emit('bv::show::modal', 'discontinued-ms');
				this.$root.$emit('bv::show::modal', 'create-aio')
				return;
			}
			if (this.notAllowed && !this.stream.enabled) {
				this.$root.$emit('bv::show::modal', 'increase-limit');
			}

			if (this.activeTeam && this.checkTogglePermission()) return;
			if (!this.notAllowed || (this.notAllowed && this.stream.enabled)) {
				this.$root.$emit('bv::show::modal', 'modalToggleStream' + this.stream._id, '#btnShow');
			}
		},
	},
	watch: {
		'stream.enabled'() {
			if (!this.stream.enabled) {
				this.schedulerSettings = {}
			}
		},
		activeTeam() {
			this.loadVOD();
		}
	}
};

function composePlatformPushUrl(platform) {
	if (!platform) return;
	let pushUrl = platform.server;
	pushUrl = pushUrl.replace(/\/$/g, '');
	pushUrl += '/' + platform.key;
	return pushUrl;
}
</script>

<style scoped>
.vod_wrap .content {
	max-width: 100%;
	flex: 0 0 100%;
}

:deep(.content .page-title) {
	resize: none;
}

.no-outline {
	outline: none;
}

.page-title {
	max-width: calc (100% - 36px);
}

@media (max-width: 767px) {
	:deep(.vod-panes .dropdown) {
		width: 100%;
	}

	.vod-nav {
		margin-right: 48px;
	}
}
</style>
