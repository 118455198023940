<template>
	<div class="mt-2 mt-lg-n5">
		<div
			class="video-wrapper"
		>
			<div
				v-if="!stream.enabled"
				class="video-thumb placeholder"
			>
				<p class="text-center text-s-xl text-dark-7 text-500 mb-1">
					Disabled Stream
				</p>
				<small class="text-s-m text-dark-5">Please enable</small>
				<b-button
					size="md"
					variant="primary"
					class="mt-4"
					@click="showStreamToggleModal"
					:id="this.activeTeam && this.cannotToggleStream ? ('member-disabled_'+stream._id) : ''"
				>
					Enable Stream
				</b-button>
				<b-tooltip
					:target="'member-disabled_'+stream._id"
					placement="top"
					v-if="memberPermission"
					>
					<span class="text-dark-8 text-small">
						You don’t have permission. Contact the team owner at <a  class="text-dark-8 text-small" :href="'mailto:' + userEmail">{{userEmail}}</a> to gain access.</span>
				</b-tooltip>
			</div>
			<div v-else-if="!streamAlive" class="video-thumb placeholder">
				<p v-if="this.streamCountdownSecLeft"> 
					<!-- TODO MIGRATE - replace with Custom -->
					<!-- <VueCountdown 
						:time="this.streamCountdownSecLeft" 
						:emit-events="true" 
						@progress="onStreamCountdownTick" 
						@end="onStreamCountdownTick(null, true)">
						<template slot-scope="props">
							<div class="scheduler-countdown-content text-center">
								<div class="desc">Stream will go Live in</div>
								<p class="value-container">
									<span class="mr-1" v-show="props.days">{{ props.days | paddStart(2) }} <small>days</small></span> 
									<span class="mr-1" v-show="props.hours || props.days">{{ props.hours | paddStart(2) }} <small> hours </small></span>
									<span class="mr-1" v-show="props.minutes || props.hours || props.days">{{ props.minutes | paddStart(2) }}
										<small> mins </small></span>
									<span class="mr-1">{{ props.seconds | paddStart(2) }} <small> secs </small></span>
								</p>
							</div>
						</template>
					</VueCountdown> -->
				</p>
				<p class="m-0" v-else>Waiting for stream</p>
			</div>
			<stream-player
				v-else-if="streamPreviewSpecs.type === 'mse'"
				:stream="stream"
				:stream-url="streamPreviewSpecs.url"
				:media-pulse="mediaPulse"
				class="video-thumb"
			/>

			<stream-player-hls
				v-else-if="streamPreviewSpecs.type === 'hls'"
				:stream="stream"
				:stream-url="streamPreviewSpecs.url"
				:autoplay="true"
				:frameless="true"
				class="video-thumb"
			/>
		</div>
		<!-- stream/preview configurations -->
		<b-button class="d-flex justify-content-between" variant="sidebar-collapse" v-b-toggle.setup >
			<span>Stream Source Setup</span>
			<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 6L6 1L11 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</b-button>
		<b-collapse class="p-3 bg-dark-2" :visible='!mobile' id="setup">
			<b-row>
				<b-col>
					<div v-if="shouldShowDeploymentRegion" class="region mb-3">
						<StreamRegion :stream="stream" @switch-stream-region="switchStreamRegion" fullWidth show-alert />
					</div>

					<div v-else class="region">
						<b-img-lazy width="20" v-if="shouldShowDeploymentRegion" :src="regionFlag" :alt="stream.uiRegion.name" class="region-flag" />
						<i v-else class="fas fa-globe preview-pane-icon" />
						<span v-if="shouldShowDeploymentRegion">{{ stream.uiRegion.name }}</span>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-tabs v-if="(!hasPullSource) && (streamSourceType !== SourceTypes.Webcam)" class="tabs-light">
						<b-tab active>
							<template #title>
								RTMP
							</template>
							<b-row class="mt-3">
								<b-col>
									<label v-if="failoverIgestActive && this.hasFailoverIngestFeature"><strong>Primary RTMP</strong></label>
									<b-input-group size="md" v-if="shouldShowDeploymentRegion && streamSourceType === SourceTypes.Publish">
										<b-form-input readonly :value="getStreamPushUrl()" @click="clipboardCopy(getStreamPushUrl)"></b-form-input>
										<b-input-group-append>
											<b-button class="py-1 px-2" variant="icon" @click="clipboardCopy(getStreamPushUrl)">
												<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
													<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												</svg>
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<label class="mt-3">Streaming Key</label>
									<b-row>
										<b-col>
											<b-input-group size="md">
												<b-form-input v-if="streamKeyVisible" :value="failoverIngestToggleState ? streamKey.replace('?', '_primary?') : streamKey" @click="clipboardCopy((failoverIngestToggleState ? streamKey.replace('?', '_primary?') : streamKey))" readonly></b-form-input>
												<b-form-input v-if="!streamKeyVisible" :value="'xxxxxxxxxxxxxxxxx'" readonly></b-form-input>
												<b-input-group-append>
													<b-button class="py-1 px-2 d-inline-flex align-items-center justify-content-center position-relative" variant="icon" @click="toggleStreamKeyVisibility">
														<svg v-if="!streamKeyVisible" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 7C2.25 6.79345 2.34977 6.35431 2.65019 5.77493C2.94087 5.21433 3.39124 4.57815 4.01786 3.98002C5.26173 2.79268 7.20417 1.75 10 1.75C12.7958 1.75 14.7383 2.79268 15.9821 3.98002C16.6088 4.57815 17.0591 5.21433 17.3498 5.77493C17.6502 6.35431 17.75 6.79345 17.75 7C17.75 7.20655 17.6502 7.64569 17.3498 8.22507C17.0591 8.78567 16.6088 9.42185 15.9821 10.02C14.7383 11.2073 12.7958 12.25 10 12.25C7.20417 12.25 5.26173 11.2073 4.01786 10.02C3.39124 9.42185 2.94087 8.78567 2.65019 8.22507C2.34977 7.64569 2.25 7.20655 2.25 7ZM10 0.25C6.79583 0.25 4.48827 1.45732 2.98214 2.89498C2.23376 3.60935 1.68413 4.37942 1.31856 5.08445C0.96273 5.77069 0.75 6.45655 0.75 7C0.75 7.54345 0.96273 8.22931 1.31856 8.91555C1.68413 9.62058 2.23376 10.3907 2.98214 11.105C4.48827 12.5427 6.79583 13.75 10 13.75C13.2042 13.75 15.5117 12.5427 17.0179 11.105C17.7662 10.3907 18.3159 9.62058 18.6814 8.91555C19.0373 8.22931 19.25 7.54345 19.25 7C19.25 6.45655 19.0373 5.77069 18.6814 5.08445C18.3159 4.37942 17.7662 3.60935 17.0179 2.89498C15.5117 1.45732 13.2042 0.25 10 0.25ZM8.25 7C8.25 6.0335 9.0335 5.25 10 5.25C10.9665 5.25 11.75 6.0335 11.75 7C11.75 7.9665 10.9665 8.75 10 8.75C9.0335 8.75 8.25 7.9665 8.25 7ZM10 3.75C8.20507 3.75 6.75 5.20507 6.75 7C6.75 8.79493 8.20507 10.25 10 10.25C11.7949 10.25 13.25 8.79493 13.25 7C13.25 5.20507 11.7949 3.75 10 3.75Z" fill="currentColor"/>
														</svg>
														<span v-if="streamKeyVisible" class="show-counter position-absolute text-c-dark-7">({{  streamKeyVisibleTimeout / 1000 }})</span>
														<svg v-if="streamKeyVisible" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M2.99988 3L16.9999 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M8 4.17947C8.61797 4.06407 9.28407 4 10 4C16 4 18.5 8.5 18.5 10C18.5 10.6561 18.0217 11.8861 16.9815 13.0625M5 5.29808C2.57295 6.74711 1.5 9.01732 1.5 10C1.5 11.5 4 16 10 16C11.8596 16 13.3829 15.5678 14.5999 14.9265" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223" stroke="currentColor" stroke-width="1.5"/>
														</svg>
													</b-button>
													<b-button class="py-1 px-2 m-0" variant="icon" @click="clipboardCopy((failoverIngestToggleState ? streamKey.replace('?', '_primary?') : streamKey))">
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</b-button>
												</b-input-group-append>
											</b-input-group>
										</b-col>
										<b-col cols="2" class="flex-center">
											<span v-b-tooltip.hover.html id="live-password-disable-tooltip">
												<b-form-checkbox
													@change="togglePasswordCheck"
													v-model="isPasswordEnabled"
													:disabled="isAnyBackupStreamLive"
													class="backup_switch password-toggle"
													:class="{'inactive': isAnyBackupStreamLive}"
													switch>
												</b-form-checkbox>
											</span>
											<b-tooltip target="live-password-disable-tooltip" :delay="{ show: 250, hide: 80 }">
												<div class="text-dark-8 test-s-s" v-if="(isAnyBackupStreamLive && this.hasFailoverIngestFeature)">
													Turn off the stream to change this.
												</div>
												<div class="text-dark-8 test-s-s" v-else>
													If your encoder doesn't support passing query parameters, the password option can be disabled here.
												</div>
											</b-tooltip>
										</b-col>
									</b-row>
									<b-button @click="clipboardCopy(getStreamURLAndKey())" variant="outline-secondary" size="md" class="w-100 mt-3 d-sm-none">Copy Stream URL & Key as One URL</b-button>
									<div class="d-flex align-items-center justify-content-start mt-3">
										<!-- srt pull -->
										<b-button id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" class="ml-2" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'"
											@click="requestSRTPullUrl()"
										>
											<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
											<span v-else>Get SRT Pull</span>
										</b-button>
										<div class="ml-2"  tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover :title="rtmpPullErrorMessage">
											<b-button  disabled class="ml-2" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'">
												<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get SRT Pull</span>
											</b-button>
										</div>
									</div>
								</b-col>
							</b-row>
						</b-tab>
						<b-tab>
							<template #title>
								SRT	
							</template>
							<div>
								<b-row class="pt-3">
									<b-col>
										<b-row>
											<b-col>
												<b-input-group size="md" v-if="shouldShowDeploymentRegion && streamSourceType === SourceTypes.Publish">
													<b-form-input :value="streamKeySRT" @click="clipboardCopy(streamKeySRT)" readonly></b-form-input>
													<b-input-group-append>
														<b-button class="px-2 py-1" variant="icon" @click="clipboardCopy(streamKeySRT)">
															<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</b-button>
													</b-input-group-append>
												</b-input-group>
											</b-col>
											<b-col cols="2" class="flex-center">
												<span v-b-tooltip.hover.html id="live-password-disable-tooltip-srt">
													<b-form-checkbox
														@change="togglePasswordCheck"
														v-model="isPasswordEnabled"
														:disabled="isAnyBackupStreamLive"
														class="backup_switch password-toggle"
														:class="{'inactive': isAnyBackupStreamLive}"
														switch>
													</b-form-checkbox>
												</span>
												<b-tooltip target="live-password-disable-tooltip-srt" :delay="{ show: 250, hide: 80 }">
													<div class="text-dark-8 test-s-s" v-if="isAnyBackupStreamLive">
														Turn off the stream to change this.
													</div>
													<div class="text-dark-8 test-s-s" v-else>
														If your encoder doesn't support passing query parameters, the password option can be disabled here.
													</div>
												</b-tooltip>
											</b-col>


										</b-row>

										<!-- pull buttons -->
										<div class="d-flex align-items-center justify-content-start mt-3">
											<!-- srt pull -->
											<b-button id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" class="ml-2" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'"
												@click="requestSRTPullUrl()"
											>
												<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get SRT Pull</span>
											</b-button>
											<div class="ml-2"  tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover :title="rtmpPullErrorMessage">
												<b-button  disabled class="ml-2" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get SRT Pull</span>
												</b-button>
											</div>
										</div>
										<!-- SRT split -->
										<div class="split-box" v-if="showSRT">
											<label class="mt-3 mb-2 text-dark-8">Hostname</label>
											<b-input-group size="md text-dark-8">
												<b-form-input :value="SRTHostname" @click="clipboardCopy(SRTHostname)" readonly></b-form-input>
												<b-input-group-append>
													<b-button class="px-2 py-1" variant="icon" @click="clipboardCopy(SRTHostname)">
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<label class="mt-3 mb-2 text-dark-8">Port</label>
											<b-input-group size="md text-dark-8">
												<b-form-input :value="SRTPort" @click="clipboardCopy(SRTPort)" readonly></b-form-input>
												<b-input-group-append>
													<b-button class="px-2 py-1" variant="icon" @click="clipboardCopy(SRTPort)">
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</b-button>
												</b-input-group-append>
											</b-input-group>
											<label class="mt-3 mb-2 text-dark-8">Stream ID</label>
											<b-input-group size="md text-dark-8">
												<b-form-input :value="SRTID" @click="clipboardCopy(SRTID)" readonly></b-form-input>
												<b-input-group-append>
													<b-button class="px-2 py-1" variant="icon" @click="clipboardCopy(SRTID)">
														<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
														</svg>
													</b-button>
												</b-input-group-append>
											</b-input-group>
										</div>
											<div>
											<p class="text-dark-8 text-small text-center w-100 pt-3 mb-0 position-relative srt-split" @click="showSRT = !showSRT">
												<span class="d-inline-block px-2 position-relative dropdown-toggle" :class="{'toggled':showSRT}">{{ showSRT ? 'Hide ' : 'Show ' }} Separate Fields</span>
											</p>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<div class="d-flex align-items-center justify-content-center mt-3">
											<a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders" target="_blank" class="d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
											<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5"/>
												<path d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												<circle cx="8" cy="11.5" r="1" fill="currentColor"/>
												</svg>
											<span>Recommended settings</span>
										</a>
									</div>
									</b-col>
								</b-row>
							</div>
						</b-tab>

						<b-tab>
							<template #title>
								WHIP	
							</template>
							<div>
								<b-row class="pt-3">
									<b-col>
										<b-row>
											<b-col>
												<b-input-group size="md" v-if="shouldShowDeploymentRegion && streamSourceType === SourceTypes.Publish">
													<b-form-input :value="streamKeyWHIP" @click="clipboardCopy(streamKeyWHIP)" readonly></b-form-input>
													<b-input-group-append>
														<b-button class="px-2 py-1" variant="icon" @click="clipboardCopy(streamKeyWHIP)">
															<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
																<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
															</svg>
														</b-button>
													</b-input-group-append>
												</b-input-group>
											</b-col>

											<b-col cols="2" class="flex-center" v-if="stream.type === 'live'">
												<span v-b-tooltip.hover.html id="live-password-disable-tooltip-whip">
													<b-form-checkbox
														@change="togglePasswordCheck"
														v-model="isPasswordEnabled"
														:disabled="isAnyBackupStreamLive"
														class="backup_switch password-toggle"
														:class="{'inactive': isAnyBackupStreamLive}"
														switch>
													</b-form-checkbox>
												</span>
												<b-tooltip target="live-password-disable-tooltip-whip" :delay="{ show: 250, hide: 80 }">
													<div class="text-dark-8 test-s-s" v-if="isAnyBackupStreamLive">
														Turn off the stream to change this.
													</div>
													<div class="text-dark-8 test-s-s" v-else>
														If your encoder doesn't support passing query parameters, the password option can be disabled here.
													</div>
												</b-tooltip>
											</b-col>

										</b-row>

										<!-- pull buttons -->
										<div class="d-flex align-items-center justify-content-start mt-3">
											<b-button id="RTMP-pull" v-if="streamAlive && isRtmpPullEnabled"  size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'"
												@click="requestRTMPPullUrl()"
											>
												<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get RTMP Pull</span>
											</b-button>
											<div tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover :title="rtmpPullErrorMessage">
												<b-button  disabled class="mr-auto" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get RTMP Pull</span>
												</b-button>
											</div>

											<b-tooltip v-if="streamAlive" target="RTMP-pull">
												<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a target="blank" class="text-reset text-underline" href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s how</a>.</span>
											</b-tooltip>

											<!-- srt pull -->
											<b-button id="SRT-pull" v-if="streamAlive && isRtmpPullEnabled" class="ml-2" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'"
												@click="requestSRTPullUrl()"
											>
												<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
												<span v-else>Get SRT Pull</span>
											</b-button>
											<div class="ml-2"  tabindex="0" v-if="!streamAlive || !isRtmpPullEnabled" v-b-tooltip.hover :title="rtmpPullErrorMessage">
												<b-button  disabled class="ml-2" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'">
													<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
													<span v-else>Get SRT Pull</span>
												</b-button>
											</div>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<div class="d-flex align-items-center justify-content-center mt-3">
											<a href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders" target="_blank" class="d-inline-flex align-items-center justify-content-center btn btn-sm btn-link text-dark-7 px-0">
											<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="8" cy="8" r="7" stroke="currentColor" stroke-width="1.5"/>
												<path d="M6 6.25V6C6 4.89543 6.89543 4 8 4H8.08033C9.14054 4 10 4.86888 10 5.92909V5.92909C10 6.71975 9.50955 7.43392 8.76923 7.71154V7.71154C8.30653 7.88505 8 8.32738 8 8.82154V9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
												<circle cx="8" cy="11.5" r="1" fill="currentColor"/>
												</svg>
											<span>Recommended settings</span>
										</a>
									</div>
									</b-col>
								</b-row>
							</div>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
			
			<div v-if="hasPullSource">
				<b-row>
					<b-col>
						<label>Pull Source</label>
					</b-col>
					<b-col>
						<div v-if="stream.enabled && stream.pullUrl" class="text-right">
							<code v-if="streamAlive" class="platform-connect-status" style="color:#1d87d2;">connected</code>
							<code v-else class="platform-connect-status">connecting..</code>
						</div>
					</b-col>
				</b-row>
				<div class="input-container">
					<b-input v-model="streamPullUrl" size="md" class="mb-2" :class="{'error': !isPullSourceValid}" placeholder="specify source url" @blur="pullSourceValidation" @keypress="onPullUrlChange()" />
				</div>
			</div>

			<!-- <div v-else-if="streamSourceType === SourceTypes.Webcam" class="field-container" style="padding:0;" /> -->
			<div v-else-if="streamSourceType !== SourceTypes.Webcam " class="double-btn">
				<div v-if="activeIngestTypeTab === 'rtmp'">
					<div v-if="failoverIgestActive">	
						<b-button size="sm" variant="dark-6"  v-show="stream.type === 'restream'"
							@click="requestRTMPPullUrl()"
						>
							<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get RTMP Pull</span>
						</b-button>

					</div>
				</div>

			</div>
			<p v-if="hasPullSource" class="text-s-s" :class="isPullSourceValid ? 'text-dark-7' : 'color-danger'">
				<svg v-if="!isPullSourceValid" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M5.36963 3.79666C6.53114 1.6786 7.1119 0.619568 8.00006 0.619568C8.88823 0.619568 9.46898 1.6786 10.6305 3.79666L14.0639 10.0575C15.15 12.0381 15.6931 13.0285 15.2575 13.7642C14.8218 14.5 13.6923 14.5 11.4334 14.5H4.56671C2.30779 14.5 1.17833 14.5 0.742676 13.7642C0.307027 13.0285 0.850109 12.0381 1.93627 10.0575L5.36963 3.79666ZM8.00002 4.74998C8.41424 4.74998 8.75002 5.08577 8.75002 5.49998V7.99998C8.75002 8.41419 8.41424 8.74998 8.00002 8.74998C7.58581 8.74998 7.25002 8.41419 7.25002 7.99998V5.49998C7.25002 5.08577 7.58581 4.74998 8.00002 4.74998ZM8.00006 12C8.55235 12 9.00006 11.5523 9.00006 11C9.00006 10.4477 8.55235 9.99999 8.00006 9.99999C7.44778 9.99999 7.00006 10.4477 7.00006 11C7.00006 11.5523 7.44778 12 8.00006 12Z" fill="#E25858"/>
				</svg>
				Please make sure the Pull URL follows our <a target="_blank" href="https://docs.castr.com/en/articles/5268042-supported-protocols-for-pull-mode" class="text-underline text-dark-9 text-500">supported formats</a>
			</p>
			<div class="field-container">
				<div>
					<b-button size="sm" class="mr-2" v-if="hasPullSource" :disabled="!canSavePullUrl() || streamSourceTypeProcessing || !isPullSourceValid" variant="primary" @click="setStreamPullUrl">
						<Spinner  v-if="streamSourceTypeProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<span v-else>Save</span>
					</b-button>
					
					<b-button id="RTMP-pull2" v-if="hasPullSource && streamAlive && isRtmpPullEnabled" size="sm" variant="dark-6" v-show="streamSourceType !== SourceTypes.Webcam"
						@click="requestRTMPPullUrl()"
					>
						<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<span v-else>Get RTMP Pull</span>
					</b-button>

					<b-button id="SRT-pull2" class="ml-1" v-if="hasPullSource && streamAlive && isRtmpPullEnabled" size="sm" variant="dark-6" v-show="streamSourceType !== SourceTypes.Webcam"
						@click="requestSRTPullUrl()"
					>
						<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
						<span v-else>Get SRT Pull</span>
					</b-button>

					<b-tooltip v-if="hasPullSource && streamAlive" target="RTMP-pull2">
						<span class="text-dark-8 test-s-s">Click to get your RTMP pull URL. Need static URL? <a  target="blank" class="text-reset text-underline" href="https://docs.castr.io/en/articles/4851668-how-to-get-constant-rtmp-address-in-castr">Here’s how</a>.</span>
					</b-tooltip>

					<div class="mr-auto d-inline-block" tabindex="0" v-if="hasPullSource && (!streamAlive || !isRtmpPullEnabled)" v-b-tooltip.hover :title="rtmpPullErrorMessage">
						<b-button v-if="hasPullSource" disabled class="mr-auto" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'">
							<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get RTMP Pull</span>
						</b-button>
					</div>
					<div class="mr-auto d-inline-block ml-1" tabindex="0" v-if="hasPullSource && (!streamAlive || !isRtmpPullEnabled)" v-b-tooltip.hover :title="rtmpPullErrorMessage">
						<b-button v-if="hasPullSource" disabled class="mr-auto" size="sm" variant="dark-6" v-show="stream.type === 'restream' || stream.type === 'live'">
							<Spinner  v-if="rmptPullUrlProcessing" text="Retrieving data..." classes="text-dark-8 my-3" spinner-color="var(--c-dark-8)" spinner-size="15px" />
							<span v-else>Get SRT Pull</span>
						</b-button>
					</div>
					<div v-if="streamPullError && streamSourceType === SourceTypes.Pull" class="text-danger" style="margin-top:10px;">Source pull url is invalid</div>
				</div>
			</div>
		</b-collapse>
		<b-button class="d-flex justify-content-between" variant="sidebar-collapse" v-b-toggle.palyback >
			<span>Playback</span>
			<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 6L6 1L11 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</b-button>
		<b-collapse class="p-3 bg-dark-2" :visible='!mobile' id="palyback">
			<b-alert v-if="!isPasswordEnabled && stream.type === 'live'" class="d-inline-flex align-items-center w-100" show variant="warning">
				<svg class="mr-2" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M5.36963 4.29623C6.53114 2.17817 7.1119 1.11914 8.00006 1.11914C8.88823 1.11914 9.46898 2.17817 10.6305 4.29623L14.0639 10.5571C15.15 12.5377 15.6931 13.528 15.2575 14.2638C14.8218 14.9996 13.6923 14.9996 11.4334 14.9996H4.56671C2.30779 14.9996 1.17833 14.9996 0.742676 14.2638C0.307027 13.528 0.850109 12.5377 1.93627 10.5571L5.36963 4.29623ZM8.00002 5.24955C8.41424 5.24955 8.75002 5.58534 8.75002 5.99955V8.49955C8.75002 8.91377 8.41424 9.24955 8.00002 9.24955C7.58581 9.24955 7.25002 8.91377 7.25002 8.49955V5.99955C7.25002 5.58534 7.58581 5.24955 8.00002 5.24955ZM8.00006 12.4996C8.55235 12.4996 9.00006 12.0518 9.00006 11.4996C9.00006 10.9473 8.55235 10.4996 8.00006 10.4996C7.44778 10.4996 7.00006 10.9473 7.00006 11.4996C7.00006 12.0518 7.44778 12.4996 8.00006 12.4996Z" fill="currentColor"/>
				</svg>
				<div>Ingest is not secure.</div>	
			</b-alert>
			<div>
				<b-tabs class="tabs-light">
					<b-tab title="Normal" @click="switchPlaybackContentTab('full')" active></b-tab>
					<b-tab title="Audio" @click="switchPlaybackContentTab('audio')" ></b-tab>
				</b-tabs>
				<b-alert v-if="activePlaybackContentTab === 'audio'" show variant="secondary" class="text-dark-7 text-s-s d-flex mt-3 p-2">
					<svg class="mr-2 mt-1 flex-shrink-0" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M14 7.5C14 11.366 10.866 14.5 7 14.5C3.13401 14.5 0 11.366 0 7.5C0 3.63401 3.13401 0.5 7 0.5C10.866 0.5 14 3.63401 14 7.5ZM8 4.5C8 5.05228 7.55228 5.5 7 5.5C6.44772 5.5 6 5.05228 6 4.5C6 3.94772 6.44772 3.5 7 3.5C7.55228 3.5 8 3.94772 8 4.5ZM7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8V11C6.25 11.4142 6.58579 11.75 7 11.75C7.41421 11.75 7.75 11.4142 7.75 11V8Z" fill="currentColor"/>
					</svg>
					<div>
						<p class="m-0 text-dark-7 text-s-s">The below players will have only audio from your Livestream.</p>
					</div>
				</b-alert>

				<div class="mt-3" v-if="activePlaybackContentTab === 'audio' || activePlaybackContentTab === 'full'">
					<!-- <label>Iframe Widget</label>	 -->
						<b-form-radio-group v-model="iframe" class="mb-2">
							<b-form-radio value="responsive">Responsive</b-form-radio>
							<b-form-radio value="fixed">Fixed size</b-form-radio>
						</b-form-radio-group>
					<b-input-group size="md" class="mb-3">
						<b-form-input :value="streamIframeCode" @click="clipboardCopy(streamIframeCode)" readonly></b-form-input>
						<b-input-group-append>
							<b-button class="py-1 px-2" variant="icon" size="md" @click="clipboardCopy(streamIframeCode)">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</div>

				<div v-if="activePlaybackContentTab === 'audio' || activePlaybackContentTab === 'full'">
					<div class="d-inline-flex align-items-center justify-content-between w-100 mb-1">
						<label>Direct Player Link</label>
					</div>
					<b-input-group size="md" class="mb-3">
						<b-form-input :value="streamEmbedUrl" @click="clipboardCopy(streamEmbedUrl)" readonly></b-form-input>
						<b-input-group-append>
							<b-button class="py-1 px-2"  variant="icon" size="md" @click="clipboardCopy(streamEmbedUrl)">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M14.1667 6V6C15.4098 6 16.0314 6 16.5168 6.21614C17.0812 6.46742 17.5326 6.91877 17.7839 7.48316C18 7.96861 18 8.59018 18 9.83334V14C18 15.4001 18 16.1002 17.7275 16.635C17.4878 17.1054 17.1054 17.4878 16.635 17.7275C16.1002 18 15.4001 18 14 18H9.83334C8.59018 18 7.96861 18 7.48316 17.7839C6.91877 17.5326 6.46742 17.0812 6.21614 16.5168C6 16.0314 6 15.4098 6 14.1667V14.1667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2H10C11.4001 2 12.1002 2 12.635 2.27248C13.1054 2.51217 13.4878 2.89462 13.7275 3.36502C14 3.8998 14 4.59987 14 6V10C14 11.4001 14 12.1002 13.7275 12.635C13.4878 13.1054 13.1054 13.4878 12.635 13.7275C12.1002 14 11.4001 14 10 14H6C4.59987 14 3.8998 14 3.36502 13.7275C2.89462 13.4878 2.51217 13.1054 2.27248 12.635C2 12.1002 2 11.4001 2 10V6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</b-button>
							<b-button variant="icon" class="py-1 px-2" size="md" :href="streamEmbedUrl" target="_blank">
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8.5 4.5H7.3C5.61984 4.5 4.77976 4.5 4.13803 4.82698C3.57354 5.1146 3.1146 5.57354 2.82698 6.13803C2.5 6.77976 2.5 7.61984 2.5 9.3V12.7C2.5 14.3802 2.5 15.2202 2.82698 15.862C3.1146 16.4265 3.57354 16.8854 4.13803 17.173C4.77976 17.5 5.61984 17.5 7.3 17.5H10.7C12.3802 17.5 13.2202 17.5 13.862 17.173C14.4265 16.8854 14.8854 16.4265 15.173 15.862C15.5 15.2202 15.5 14.3802 15.5 12.7V11.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M10.5 9.5L17.5 2.5M17.5 2.5H12.5M17.5 2.5V7.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</div>
				<p class="text-dark-7 text-s-s">Maximum {{ maxSubSecondStreamViewers }} concurrent {{ maxSubSecondStreamViewers === 1 ? 'viewer' : 'viewers' }}. Need more? <b-link class="text-dark-8 text-underline" onclick="Intercom('showNewMessage', 'I want to talk about low latency solution')">Contact us</b-link></p>
			</div>
		</b-collapse>

		<b-modal size="sm" id="modal-up" centered hide-header hide-footer>
			<p class="mb-4 text-s-m text-center text-dark-9">Please go to the dashboard and create a new all-in-one stream to get started.</p>
			<div class="d-flex justify-content-end"><b-button variant="primary" size="md" class="" @click="$bvModal.hide('modal-up')">Got it</b-button></div>
		</b-modal>
		<confirm-modal
			modal-id="modal-set-publish-mode"
			message="Switching to Publish mode will disable your pulled stream"
			ok-text="Enable Publish Mode"
			cancel-text="Cancel"
			@modal-confirm="unsetStreamPullUrl"
		/>

		<confirm-modal
			modal-id="modal-set-publish-mode-webcam"
			message="Switching to Webcam mode will disable your pulled stream"
			ok-text="Disable Pulled Stream"
			cancel-text="Cancel"
			@modal-confirm="unsetStreamPullUrl(true)"
		/>

		<!-- <confirm-modal message="Attention: Multistream has been discontinued. Set up a new All-In-One Stream today for uninterrupted streaming with enhanced features, at no extra cost." modal-id="discontinued-ms"
				ok-text="Create All in One" cancel-text="Cancel"
				@modal-confirm="openCreatestream" /> -->

		<confirm-modal
			modal-id="modal-webcam-leave-navigation"
			message="Webcam will stop streaming if you navigate"
			ok-text="Leave Anyway"
			cancel-text="Keep Webcam"
			@modal-confirm="confirmWebcamLeave"
		/>

		<confirm-modal
			modal-id="modal-pull-hls-premium"
			message="http pull feature is only available in our paid subscriptions"
			ok-text="Upgrade now"
			cancel-text="No thanks"
			@modal-confirm="navigatePaymentsPage"
		/>
		<confirm-modal
			modal-id="modal-failoverIngest-subscription"
			message="Backup ingest feature not available in your subscription"
			ok-text="Upgrade now"
			cancel-text="No thanks"
			@modal-confirm="navigatePaymentsPage"
		/>

		<confirm-modal
			modal-id="modal-disable-password-mobile-dialog"
			message="If your encoder doesn't support passing query parameters, the password option can be disabled here."
			ok-text="Confirm"
			cancel-text="Cancel"
			@modal-confirm="confirmDisablePasswordMobile"
		/>

		<alert-modal
			modal-id="alert-mixer-pull"
			message="Mixer pull is not available in this region. Please use any regions in the US and it will not impact the quality of the stream"
			ok-text="Got it"
		/>

		<alert-modal
			modal-id="alert-disable-pull"
			message="Choose a specific region to use this mode."
			ok-text="Got it"
		/>

		<prompt-modal
			modal-id="modal-mixer-username"
			message="Enter your Mixer username"
			message2="Note: If you are pulling, please disable push to mixer"
			ok-text="Grab Mixer Pull Url"
			cancel-text="Cancel"
			error-message="No FTL broadcasts found"
			@modal-prompt="onMixerUsername"
		/>

		<confirm-modal
			modal-id="modal-backup-toggle-warning"
			message=""
			body="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast"
			ok-text="Ok, I will  update"
			cancel-text="No, don’t change it"
			:confirmCancelEvent="false"
			@modal-confirm="onBackupModalToggleConfirm"
			@modal-cancel="onBackupModalToggleCancel"
		/>

		<confirm-modal
			modal-id="modal-pass-toggle-warning"
			message=""
			body="Adjusting this setting will alter your current stream key. Please ensure you update your streaming software with the new key to avoid any disruptions in your broadcast"
			ok-text="Ok, I will  update"
			cancel-text="No, don’t change it"
			:confirmCancelEvent="false"
			@modal-confirm="togglePasswordCheckProced"
			@modal-cancel="changePasstoggle"
		/>

	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex'
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import IntegrationService from '@/services/IntegrationService';
import StreamPlayer from '@/components/ui/StreamPlayer.vue';
import StreamRegion from '@/components/ui/StreamRegion.vue';
import StreamPlayerHls from '@/components/ui/StreamPlayer.hls.vue';
import WebcamPlayer from '@/components/ui/WebcamPlayer.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ExpansionPanel from '@/components/ExpansionPanel';
import AddStreamModal from '@/components/modals/AddStreamModal.vue';
import Vue from 'vue';

const SourceTypes = {
	Pull: 'pull',
	Publish: 'publish',
	Webcam: 'webcam'
};

export default {
	name: 'SubSecondPreviewPane',
	components: {
		Spinner,
		ConfirmModal,
		AlertModal,
		StreamPlayer,
		StreamPlayerHls,
		AddStreamModal,
		WebcamPlayer,
		PromptModal,
		ExpansionPanel,
		StreamRegion,
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			}
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		},
		streamAlive: {
			type: Boolean
		},
		backupStreamAlive: {
			type: Boolean,
			default() { return false; }
		},
		isRtmpPullEnabled: {
			type: Boolean,
			default() { return true; }
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.streamSourceType === SourceTypes.Webcam && this.webcamPushReady) {
			this.$root.$emit('bv::show::modal', 'modal-webcam-leave-navigation');
			this.webcamRouteLeaveNavigationCallback = () => next();
			return;
		}
		next();
	},
	data() {
		return {
			notAllowed: false,
			SourceTypes,
			processing: true,
			processingMessage: null,
			activeTab: 'encoder',
			rmptPullUrlProcessing: false,
			srtPullUrlProcessing: false,
			hlsUrl: null,
			streamSourceType: null,
			streamSourceTypeModel: null,
			streamPreviewSpecs: {},
			streamPullUrl: null,
			streamPullError: false,
			streamPullSourceChunksCount: 0,
			pullSourceWorking: true,
			pullSourceStatusTimeoutCtrl: -1,
			webcamPushReady: false,
			showError: false,
			streamSourceTypeProcessing: null,
			streamId: null,
			streamFps: null,

			streamKeyVisible: false,
			streamKeyVisibleTimeout: 0,
			streamKeyVisibleTimeoutCtrl: null,

			streamKeyVisible2: false,
			streamKeyVisibleTimeout2: 0,
			streamKeyVisibleTimeoutCtrl2: null,

			windowWidth: window.innerWidth,

			windowHeight: 0,
			webcamRouteLeaveNavigationCallback: null,
			activeIngestTypeTab: 'rtmp',
			failoverIngestToggleState: false,
			failoverIngestToggleProcessing: false,
			lowLatencyPlaybackStage: false,
			lowLatencyStreamToggleProcessing: false,

			activePlaybackContentTab: 'full',
			regions: [],
			selectedRegion: null,
			switchRegionProcessing: false,
			showRegionDropdownMenu: false,
			autoDetectDisabled: false,
			autoDetectDisabledClass: { 'auto-detect-disabled': false },
			componentLoaded: false,
			showSRT: false,
			streamCountdownSecLeft: null,
			streamAbrToggleState: null,
			iframe: 'responsive',
			isPullSourceValid: true,
			llHls: false,
			isPasswordEnabled: true,
		};
	},
	computed: {
		...mapGetters({
			maxSubSecondStreamViewers: "User/maxSubSecondStreamViewers"
		}),
		hasLLHls() {
			// TODO(remove): hardcode access to LL hls feature for user with premuin sub
			// LL hls should be blocked for current sub for all other clients
			if (this.stream.user === '653686e34d8f1722c0b3c9a4') {
				return true
			}
			return this.$store.state.User.aggregatedDefinition.live.llHls ?? false
		},
		userEmail() {
			return this.$store.state.User.email;
		},
		isAnyBackupStreamLive() {
			return this.streamAlive || this.backupStreamAlive
		},
		mobile() {
			if (this.windowWidth >767) {
				return false
			} else {
				return true
			}
		},
		selectedRegionName() {
			if (!this.selectedRegion) {
				return '';
			}
			const name = this.selectedRegion.name;
			// if (name && name.length > 15) {
			// 	return name.split('(').pop().replace(')', '');
			// }
			return this.selectedRegion.name;
		},
		contextualRegionList() {
			const stype = this.stream.type;
			if (stype === 'scheduled') {
				return [];
			}
			return stype
				? this.regions.filter(r => r.platforms.indexOf('restream') > -1)
				: [];
		},
		failoverIgestActive() {
			return this.failoverIngestToggleState === true && this.streamSourceTypeModel === 'publish' && this.stream.type === 'live';
		},
		streamKey() {
			let pushKey = this.stream.key;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				pushKey += '?password=' + this.stream.config.password;
			}

			return pushKey;
		},
		streamKey2() {
			let pushKey = `${this.stream.key}_backup`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				pushKey += '?password=' + this.stream.config.password;
			}

			return pushKey;
		},
		srtIngestPointForSelectedRegion() {
			  switch (this.selectedRegion?.identifier) {
				  case 'au':
				  case 'sg':
					  return 'au.castr.io'
			  
				  default:
					  return 'srt-ingest.castr.io'
			  }
		},
		srtRegionIngestDestination() {
			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				return 'fifa'
			}

			// hack for studio@jackshoot.com
			if (this.stream.user === '5da088ba2564bd064957e474' && this.stream.uiRegion._id === '62e2a0e108cb9dc2bcfb17d8') {
				return 'kr'
			}

			if (this.stream.user === '5ba39c5e688503562c8097b4' && this.stream.uiRegion._id === '63f3624d8ae252ca28ed79d2') {
				return 'ae'
			}
			const liveRegionRel = {
				'us-east-1': 'cg',
				'us-east-3': 'ny',
				'us-east-2': 'mi',
				'us-west-2': 'se',
				'us-west-1': 'la',
				'us-central-1': 'da',
				'na-east-1': 'qc',
				'sa-east-1': 'br',
				'eu-west-1': 'uk',
				'af-south-1': 'southafrica',
				'eu-central-1': 'fr',
				'ru-west-1': 'ru',
				'ap-southeast-1': 'sg',
				'ap-south-1': 'in',
				'ap-southeast-2': 'au',
				'madrid': 'madrid',
				'paris': 'paris',
				'milan': 'milan',
				'stockholm': 'stockholm',
				'mexico': 'mexico',
				'hongkong': 'hongkong',
				'colombia': 'bogota',
				'tokyo': 'tokyo'
			}

			return liveRegionRel[this.stream.uiRegion?.code] ? 
					liveRegionRel[this.stream.uiRegion?.code]: 'str-ingest'
		},
		streamKeySRT() {
			let streamURL = `srt://${this.srtRegionIngestDestination}.castr.io:9998?pkt_size=1316&streamid=#!::r=${this.stream.key},m=publish`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				streamURL = `srt://${this.srtRegionIngestDestination}.castr.io:9998?pkt_size=1316&streamid=#!::r=${this.stream.key},password=${this.stream.config.password},m=publish`
			}

			return streamURL;
		},
		streamKeyWHIP() {
			let hostname = _.get(this.stream, 'uiRegion.config.failover.backupIngest');
			if (!hostname) {
				hostname = _.get(this.stream, 'uiRegion.config.failover.primaryIngest');
			}
			if (!hostname) {
				hostname = this.stream.uiRegion.hostname
			}
			let streamURL = `https://${hostname}/${this.stream.key}/whip`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				streamURL = `https://${hostname}/${this.stream.key}/whip?password=${this.stream.config.password}`
			}

			return streamURL;
		},
		SRTHostname() {
			let streamHost = ` srt://${this.srtRegionIngestDestination}.castr.io`;
			return streamHost;
		},
		SRTPort() {
			let streamPort = `9998`;
			return streamPort;
		},
		SRTID() {
			let streamIDSrt = `#!::r=${this.stream.key},m=publish`;
			if (this.stream.config && this.stream.config.password && this.isPasswordEnabled) {
				streamIDSrt = `#!::r=${this.stream.key},password=${this.stream.config.password},m=publish`
			}
			return streamIDSrt;
		},
		shouldShowDeploymentRegion() {
			return this.streamType !== 'ipcam';
		},
		streamType() {
			return this.stream.type;
		},
		hasPullSource() {
			return this.streamSourceType === SourceTypes.Pull;
		},
		regionFlag() {
			const identifier = this.stream.uiRegion.identifier || '';
			return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
		},
		streamIframeCode() {
			const embedUrl = this.streamEmbedUrl;
			let htmlCode = "";
			if (this.iframe === "responsive") {
				htmlCode =  `<iframe src="${embedUrl}" width="100%" style="aspect-ratio: 16/9; min-height: 340px;" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
			} else {
				htmlCode =  `<iframe src="${embedUrl}" width="590" height="431" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
			}

			return htmlCode;
		},
		streamEmbedUrl() {
			let embedUrl = `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/${this.stream.key}`;
			if (this.dvrEmbedEnabled) {
				embedUrl += '?playlist=1';
			}

			if (this.activePlaybackContentTab === 'audio') {
				// if stream source is webcam for only audio we need point player to 
				// pick correct track audio number(#2) from audio source
				let webcamSrc = '';
				if (this.stream.type === 'live' && this.streamSourceType === 'webcam') {
					webcamSrc = '&webcam=true';
				}
				let onlyAudio = `?onlyAudio=true${webcamSrc}`;
				if (embedUrl.includes('?')) {
					onlyAudio = `&onlyAudio=true${webcamSrc}`;
				}

				embedUrl += onlyAudio;
			}

			if (this.activePlaybackContentTab === 'll-hls') {
				let ll = `?ll=true`;
				if (embedUrl.includes('?')) {
					ll = `&ll=true`;
				}

				embedUrl += ll;
			}

			if (this.activePlaybackContentTab === 'ull') {
				embedUrl += '/realtime';
			}

			return embedUrl;
		},
		activeTeam(){
			return this.$store.state.User.activatedTeam;
		},
		memberPermission(){
			return this.activeTeam && this.$store.state.User.memberPermission;
		},
		cannotToggleStream() {
			if(!this.activeTeam) return false;
			let permission = this.memberPermission && (
					((this.stream.type === 'live')  && !this.memberPermission.liveStreamToggle) || 
					((this.stream.type === 'restream') && !this.memberPermission.recordedStreamToggle) || 
					((this.stream.type === 'scheduled') && !this.memberPermission.preRecordedStreamToggle));
			return permission;
		},
		subscription() {
			return this.$store.state.User.subscriptions;
		},
		baseSubscription() {
			const baseSub = _.find(this.subscription, { category: 'live' }) || {};
			return baseSub;
		},
		baseCharge() {
			if(this.componentLoaded === true && this.baseSubscription) {
				return this.baseSubscription.package.baseCharge;
			}
		},
		allowHls() {
			if ((this.baseCharge > 750 && this.baseSubscription.package.cycleAge >= 365) || (this.baseCharge > 100 && this.baseSubscription.package.cycleAge < 365)) {
				return true
			} else return false
		},
		isTrialSubscription() {
			const subs = this.subscription.map(sub => _.assign({ category: 'restream' }, sub))
			const baseSub = _.find(subs, { category: this.stream.type }) || {};
			return /trial/gi.test(baseSub.package.name) || false;
		},
		linkedCpCode() {
			const baseSub = this.baseSubscription;
			const cpcode = _.get(baseSub, 'definitionOverride.cdnDeploymentId');
			return cpcode;
		},
		hasFailoverIngestFeature() {
			const baseSub = this.baseSubscription;
			return !!_.get(baseSub, 'package.definition.failoverIngest');
		},
		failoverPrimaryIngest() {
			let ingest = _.get(this.stream, 'uiRegion.config.failover.primaryIngest');
			if (!ingest) {
				// ingest = this.stream.uiRegion.hostname.replace('.castr.io', '-primary.castr.io');
				ingest = this.stream.uiRegion.hostname
			}
			return ingest;
		},
		failoverBackupIngest() {
			if (!this.hasFailoverIngestFeature) {
				return 'xxxxxxxxxxxxxxxxx';
			}
			let ingest = _.get(this.stream, 'uiRegion.config.failover.backupIngest');
			if (!ingest) {
				// ingest = this.stream.uiRegion.hostname.replace('.castr.io', '-backup.castr.io');
				ingest = this.stream.uiRegion.hostname
			}
			return ingest;
		},
		rtmpPullErrorMessage() {
			return !this.isRtmpPullEnabled ? "This feature is not available for your plan. Please upgrade to use it." : "This can only be used when stream is live"
		}
	},
	watch: {
		async streamAbrToggleState() {
			await this.setStreamHlsUrl();
  		},
		async mediaPulse() {
			this.onMediaPulseChanged();
		},
		streamSourceTypeModel() {
			if (this.streamSourceType === SourceTypes.Pull && this.stream.uiRegion.hostname === 'live.castr.io') {
				this.streamSourceType = SourceTypes.Publish;
				this.streamSourceTypeModel = SourceTypes.Publish;
				this.$root.$emit('bv::show::modal', 'alert-disable-pull');
			}
		},
	},
	async mounted() {
		if(localStorage.getItem('notAllowed'+this.stream.type)){
			this.notAllowed = (localStorage.getItem('notAllowed'+this.stream.type) == 'true');
		}
		this.isPasswordEnabled = !Boolean(this.stream?.config?.isPasswordDisabled);
		await this.$store.dispatch('User/getinfoOwner', null, { root: true });
		this.failoverIngestToggleState = this.stream.failoverIngest;
		this.lowLatencyPlaybackStage = this.stream.lowLatencyPlayback;
		await this.setupStream();
		await this.setStreamHlsUrl();
		this.selectRegion(this.stream.uiRegion);
		
		if (this.stream.uiRegion.hostname === 'live.castr.io') {
			this.failoverIngestToggleProcessing = true;
		} else this.failoverIngestToggleProcessing = false;

		this.selectRegion(this.stream.uiRegion);
		this.componentLoaded = true;
		//TODO delete after move to component
		this.regions = await StreamService.getAvailableRegions('list');
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}

		if (this.stream.type === 'live') {
			const meta = await StreamService.getStreamMetadata(this.stream._id);
			if (meta.countdownDate) {
				this.streamCountdownSecLeft = Math.max(new Date(meta.countdownDate) - Date.now(), 0);
			}
			this.llHls = meta.llHls || false
		}

		
	},
	methods: {
		togglePasswordCheck() {
			this.$root.$emit(
					'bv::show::modal',
					'modal-pass-toggle-warning'
				);
		
		},
		togglePasswordCheckProced(){
			if(this.mobile) {
				this.showTogglePasswordDialog();
			} else {
				this.togglePassword()
			}
		},
		confirmDisablePasswordMobile () {
			this.isPasswordEnabled = !this.isPasswordEnabled;
			this.togglePassword();
		},
		showTogglePasswordDialog() {
			// undo the change done by v-model
			this.isPasswordEnabled = !this.isPasswordEnabled;
			this.$root.$emit('bv::show::modal', 'modal-disable-password-mobile-dialog');
		},
		async togglePassword() {
			// this.isPasswordEnabled = !this.isPasswordEnabled

			// optimistic update
			await this.$nextTick();
			try {
				const res = await StreamService.updatePasswordToggle(this.stream._id, !this.isPasswordEnabled);
				if(!res.success) {
					this.onFailedToTogglePassword();
				}
			} catch {
				this.onFailedToTogglePassword();
			}
		},
		onFailedToTogglePassword() {
			this.isPasswordEnabled = !this.isPasswordEnabled;
			this.$notify({ group: 'error', text: 'Could not toggle the password.' });
			// revert
			
		},
		async saveLLHls() {
			try {
				await StreamService.saveStreamMetadata(this.stream._id, 'llHls', this.llHls);
				this.$notify({ group: 'success', text: 'Setting saved' })
			} catch (e) {
				this.llHls = !this.llHls;
				this.$notify({ group: 'error', text: 'could not save changes' });
			}
		},
		onStreamCountdownTick(last, isFinished) {
			if (isFinished) {
				this.streamCountdownSecLeft = null;
			}
		},
		toggleStatus(ev) {
			this.$parent.toggleStatus(ev);
		},
		toggleRegionDropdownMenu() {
			this.showRegionDropdownMenu = !this.showRegionDropdownMenu;
		},
		closeRegionDropDownMenu() {
			this.showRegionDropdownMenu = false;
		},
		async switchStreamRegion(region) {
			if(!region) {
				return;
			}

			this.switchRegionProcessing = true;
			try {
				await StreamService.switchStreamRegion(this.streamId, region._id);
                // this.closeRegionDropDownMenu();

				const selectedProps = _.pick(region, ['hostname', 'identifier', 'name']);
				const newRegion = _.assign({}, this.stream.region, selectedProps)
				this.$emit('stream-updated', { uiRegion: region });

				this.$notify({
					group: 'success', 
					text: `region changed to ${region.name}`
				});
			} catch(e) {
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not switch stream region'
				});
			}
			if (region.hostname === 'live.castr.io') {
				this.failoverIngestToggleProcessing = true;
			} else this.failoverIngestToggleProcessing = false;
			this.switchRegionProcessing = false;
		},
		getCountryFlag(region) {
			const identifier = _.get(region || {}, 'identifier', '');
			return `https://assets.castr.io/countryflags/${identifier}/flat/64.png`;
		},
		selectRegion(region) {
			this.selectedRegion = region;
		},
		switchIngestTypeTab(tab) {
			this.activeIngestTypeTab = tab;
		},	
		switchPlaybackContentTab(tab) {
				this.activePlaybackContentTab = tab;
		},
		async onBackupModalToggleCancel() {
			this.failoverIngestToggleState = !this.failoverIngestToggleState;
		},
		async toggleIngestFailoverStatus() {
			if (this.hasFailoverIngestFeature) {
				this.$root.$emit(
					'bv::show::modal',
					'modal-backup-toggle-warning'
				);
			}else{

			this.onBackupModalToggleConfirm()     
			}
			
		},
		async onBackupModalToggleConfirm() {
			// this.failoverIngestToggleState = !this.failoverIngestToggleState;

			if(!this.hasFailoverIngestFeature) {
				this.failoverIngestToggleState = false;
				return
			}

			this.failoverIngestToggleProcessing = true;
			try {
				await StreamService.toggleStreamFailoverIngest(this.streamId, this.failoverIngestToggleState);
			} catch (e) {
				this.failoverIngestToggleState = !this.failoverIngestToggleState;
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not toggle backup stream ingest'
				});
			}

			// TODO: remove here mutation of prop and emit event to parent component
			// backupMediaPulse must stop processing when backup ingest disabled
			this.stream.failoverIngest = this.failoverIngestToggleState;
			this.autoDetectDisabled = this.failoverIngestToggleState;
			this.autoDetectDisabledClass = { 'auto-detect-disabled': true }

			this.failoverIngestToggleProcessing = false;
			if (this.failoverIngestToggleState) window.Intercom('trackEvent', 'toggle-backup-on')
		},
		async toggleLowLatencyStreaming() {
			// this.lowLatencyPlaybackStage = !this.lowLatencyPlaybackStage;

			this.lowLatencyStreamToggleProcessing = true;
			try {
				await StreamService.toggleStreamLowLatencyStreaming(this.streamId, this.lowLatencyPlaybackStage);
			} catch (e) {
				this.lowLatencyPlaybackStage = !this.lowLatencyPlaybackStage;
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'Could not toggle low latency streaming'
				});
			}

			this.stream.lowLatencyPlayback = this.lowLatencyPlaybackStage;
			this.lowLatencyStreamToggleProcessing = false;
		},
		switchOptionsTab(tabName) {
			this.activeTab = tabName;
		},
		// openCreatestream(){
		// 	this.$bvModal.show('modal-add-stream');
		// 		this.$root.$emit(
		// 				'bv::show::modal',
		// 				'modal-add-stream'
		// 			);
		// 		this.$root.$emit('openAIO', true);
		// },
		async setupStream() {
			// get stream details
			const { stream } = this;
			this.streamId = this.stream._id;

			const hasPullUrl = stream.pullUrl;
			this.streamSourceTypeModel = hasPullUrl
				? SourceTypes.Pull
				: SourceTypes.Publish;

			this.onSourceTypeChange();
			if (this.streamType === 'ipcam') {
				this.streamSourceType = SourceTypes.Pull;
			}

			if (hasPullUrl) {
				this.streamPullUrl = stream.pullUrl;
			}
			if (stream.failoverIngest === true) {
				this.autoDetectDisabled = true;
				this.autoDetectDisabledClass = { 'auto-detect-disabled': true }
			}
		},
		async setStreamPreviewUrl() {
			try {
				this.streamPreviewSpecs = await StreamService.getStreamPreviewVideoUrl(
					this.stream._id
				);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: (e && e.message) || 'could not fetch stream preview sepcs'
				});
			}
		},
		async setStreamHlsUrl() {
			try {
				const hlsUrlRes = await StreamService.getStreamHlsUrl(this.stream._id);
				if (!hlsUrlRes || !hlsUrlRes.hlsUrl) {
					throw new Error();
				}
				this.hlsUrl = hlsUrlRes.hlsUrl;
			} catch (e) {
				// do nothing
			}
		},
		async onMediaPulseChanged() {
			if (this.mediaPulse.isWowza === true) {
				this.streamPreviewSpecs.type = 'hls';
				this.streamPreviewSpecs.url = `https://wowza-stage.castr.io/static/${this.stream.key}/playlist_sfm4s.m3u8`;
			}

			if (_.isEmpty(this.streamPreviewSpecs)) {
				this.streamPreviewSpecs.type = 'mse';
				this.streamPreviewSpecs.url = `wss://wowza-stage.castr.io/${this.stream.key}/mse_ld?tracks=v1a1`;
			}
		},
		clipboardCopy(text) {
			try {
				if (text instanceof Function) text = text();

				this.$copyText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) {}
		},
		navigatePaymentsPage() {
			const category = this.stream.type;
			window.location.href = `/app/subscribe?category=${category}`;
		},
		pullSourceValidation() {
			const regexp = new RegExp("^(?!.*@.*@)(http|https|tshttp|tshttps|mpegts|hls|hlss|rtsp|rtsp2|m4s|m4f|file|fake|rtmp|srt|timeshift|mixer|shoutcast|shoutcasts)://", "i");
			const regexp2 = new RegExp("(?:\uD81A[\uDF40-\uDF43]|\uD81B[\uDF93-\uDF9F\uDFE0]|[\u00B0\u00B2\u00B3\u00B9\u02AF\u0670\u0711\u2121\u213B\u2207\u29B5\uFC5B-\uFC5D\uFC63\uFC90\uFCD9\u2070\u2071\u2074-\u208E\u2090-\u209C\u0345\u0656\u17D2\u1D62-\u1D6A\u2A27\u2C7C\u02B0-\u02C1\u02C6-\u02D1\u02E0-\u02E4\u02EC\u02EE\u0374\u037A\u0559\u0640\u06E5\u06E6\u07F4\u07F5\u07FA\u081A\u0824\u0828\u0971\u0E46\u0EC6\u10FC\u17D7\u1843\u1AA7\u1C78-\u1C7D\u1D2C-\u1D6A\u1D78\u1D9B-\u1DBF\u2071\u207F\u2090-\u209C\u2C7C\u2C7D\u2D6F\u2E2F\u3005\u3031-\u3035\u303B\u309D\u309E\u30FC-\u30FE\uA015\uA4F8-\uA4FD\uA60C\uA67F\uA69C\uA69D\uA717-\uA71F\uA770\uA788\uA7F8\uA7F9\uA9CF\uA9E6\uAA70\uAADD\uAAF3\uAAF4\uAB5C-\uAB5F\uFF70\uFF9E\uFF9F\u00AA\u00BA\u2071\u207F])")
			const pullSource = this.streamPullUrl.replace(/\s/g, '')
			this.streamPullUrl = pullSource;
			const isValid = regexp.test(pullSource) && !regexp2.test(pullSource) && !pullSource.includes('^')
			this.isPullSourceValid = isValid
		},
		onPullUrlChange() {
			this.pullSourceValidation;
			this.streamPullError = false;
		},
		isMixerPullAuthorized() {
			const exlcudedRegions = ['br'];
			const curRegion = this.stream.region.identifier;

			let bypassed = true;
			for (let i = 0; i < exlcudedRegions.length; i++) {
				if (curRegion === exlcudedRegions[i]) {
					bypassed = false;
					break;
				}
			}

			return bypassed;
		},
		canSavePullUrl() {

			let canSave = false;
			// check for valid input
			const { streamPullUrl } = this;

			if (streamPullUrl) {
				// check if pull remained same
				if (streamPullUrl !== this.stream.pullUrl) canSave = true;
			}

			return canSave;
		},
		onWebcamAuthorized() {
			// check if streaming pulling mode is active
			if (this.stream.pullUrl) {
				this.$root.$emit(
					'bv::show::modal',
					'modal-set-publish-mode-webcam'
				);
			}
		},
		onWebcamStopped() {
			this.webcamPushReady = false;
		},
		onWebcamStarted() {
			this.webcamPushReady = true;
		},
		confirmWebcamLeave() {
			if (this.webcamRouteLeaveNavigationCallback) {
				this.webcamRouteLeaveNavigationCallback();
			}
		},
		async onSourceTypeChange() {
			if (
				this.streamSourceType === SourceTypes.Webcam &&
						this.webcamPushReady
			) {
				setTimeout(() => {
					this.streamSourceTypeModel = this.streamSourceType;
				}, 100);

				this.$root.$emit(
					'bv::show::modal',
					'modal-webcam-leave-navigation'
				);
				this.webcamRouteLeaveNavigationCallback = () => {
					this.streamSourceType = this.streamSourceTypeModel;
				};
				return;
			}

			this.streamSourceType = this.streamSourceTypeModel;

			// check if new mode is `publish`
			if (this.streamSourceType === SourceTypes.Publish) {
				// check if operational mode is `pull`
				const hadPullUrl = this.stream.pullUrl;
				// if (hadPullUrl) this.unsetStreamPullUrl();
				if (hadPullUrl) this.requestPublishPrompt();
			}
		},
		requestPublishPrompt(preventSourceRestore) {
			this.$root.$emit('bv::show::modal', 'modal-set-publish-mode');

			if (preventSourceRestore) return;
			setTimeout(() => {
				this.streamSourceType = SourceTypes.Pull;
				this.streamSourceTypeModel = SourceTypes.Pull;
			});
		},
		requestMixerUsername() {
			// const res = await IntegrationService.getMixerFTLUrl('tidy')
			if (!this.isMixerPullAuthorized()) {
				this.$root.$emit('bv::show::modal', 'alert-mixer-pull');
				return;
			}

			this.$root.$emit('bv::show::modal', 'modal-mixer-username');
		},
		async onMixerUsername(mixerUsername, ackCB) {
			const res = await IntegrationService.getMixerFTLUrl(mixerUsername);
			ackCB(!res.mixerPullURL);

			const { mixerPullURL } = res;
			this.streamPullUrl = mixerPullURL;
		},
		setPullSourceStatus() {
			const status = this.streamAlive;
			this.pullSourceWorking = status;

			if (status) {
				const t = setTimeout(this.setPullSourceStatus, 5000);
				this.pullSourceStatusTimeoutCtrl = t;
			}
		},
		parseURL(srcURL) {
			let url;
			try {
	            url = new URL(srcURL);
			} catch (_) {
                return false;  
			}
			return url
		},
		async setStreamPullUrl() {
			this.streamPullError = false;

			const pullSource = this.streamPullUrl.replace(/\s/g, '');
			
			this.streamPullUrl = pullSource;

			if (isMixerFTLSource(pullSource) && !this.isMixerPullAuthorized()) {
				this.$root.$emit('bv::show::modal', 'alert-mixer-pull');
				return;
			}

			if (this.isTrialSubscription) {
				const url = this.parseURL(pullSource)
				if (url.protocol === "http:" || url.protocol === "https:") {
					this.$root.$emit('bv::show::modal', 'modal-pull-hls-premium');
					return;
				}
			}

			// swtich source mode to specified pull url
			this.streamSourceTypeProcessing = true;

			try {
				await StreamService.setStreamPullUrl(this.streamId, pullSource);
				// this.stream.pullUrl = pullSource;

				this.$emit('stream-updated', { pullUrl: pullSource });

				this.$notify({ group: 'success', text: 'stream pull url saved' });
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not save stream pull url'
				});
			}

			this.streamSourceTypeProcessing = false;
		},
		async unsetStreamPullUrl(preventSourceRestore) {
			this.streamSourceTypeProcessing = true;

			try {
				await StreamService.unsetStreamPullUrl(this.streamId);
				// this.stream.pullUrl = null;

				this.$emit('stream-updated', { pullUrl: null });

				this.$notify({ group: 'success', text: 'Publish mode activated' });

				if (!preventSourceRestore) {
					// change tab to publish
					this.streamSourceType = SourceTypes.Publish;
					this.streamSourceTypeModel = SourceTypes.Publish;
				}
			} catch (e) {
				if (!preventSourceRestore) {
					this.streamSourceType = SourceTypes.Pull;
					this.streamSourceTypeModel = SourceTypes.Pull;
				}

				this.$notify({
					group: 'error',
					text: 'could not switch to Publish mode'
				});
			}

			this.streamSourceTypeProcessing = false;
		},
		async requestRTMPPullUrl() {
			// check if RTMP pull is allowed
			if(!this.isRtmpPullEnabled) return

			// try copy to clipboard
			const rtmpPullUrl = this.getStreamPullUrl();
			try {
				this.$copyText(rtmpPullUrl);
				this.onStreamKeyCopied();
			} catch (e) {}
		},
		async requestSRTPullUrl() {
			// try copy to clipboard
			let server = this.streamKeySRT;
			let hostId = this.mediaPulse && this.mediaPulse.hostId;

			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				hostId = 'fifa'
			}

			if (hostId) {
				const mappedHostname = `${hostId}.castr.io`;
				server = 'srt://'+ mappedHostname + ':9999'
				server += `?streamid=#!::r=${this.stream.key},m=request`;
			}

			try {
				this.$copyText(server);
				this.onStreamKeyCopied();
			} catch (e) {}
		},
		toggleStreamKeyVisibility() {
			window.clearInterval(this.streamKeyVisibleTimeoutCtrl);
			const newState = !this.streamKeyVisible;
			this.streamKeyVisible = newState;

			if (newState) {
				const timeout = 1000;
				this.streamKeyVisibleTimeout = 9000;
				this.streamKeyVisibleTimeoutCtrl = setInterval(() => {
					this.streamKeyVisibleTimeout -= timeout;
					if (!this.streamKeyVisibleTimeout)
						this.toggleStreamKeyVisibility();
				}, timeout);

				// track event
				window.trackEvent(
					`Viewed Stream key in stream ${this.stream.name}`
				);
			}
		},
		toggleStreamKeyVisibility2() {
			window.clearInterval(this.streamKeyVisibleTimeoutCtrl2);
			const newState = !this.streamKeyVisible2;
			this.streamKeyVisible2 = newState;

			if (newState) {
				const timeout = 1000;
				this.streamKeyVisibleTimeout2 = 9000;
				this.streamKeyVisibleTimeoutCtrl2 = setInterval(() => {
					this.streamKeyVisibleTimeout2 -= timeout;
					if (!this.streamKeyVisibleTimeout2)
						this.toggleStreamKeyVisibility2();
				}, timeout);

				// track event
				window.trackEvent(
					`Viewed Stream key in stream ${this.stream.name}`
				);
			}
		},
		onStreamKeyCopied() {
			this.$notify({ group: 'info', text: 'Copied to clipboard' });
			// track event
			window.trackEvent(`Copied RTMP pull for stream ${this.stream.name}`);
		},
		getTrackType(track) {
			if (!track) return;

			let type;
			if (/^a/gi.test(track.id)) type = 'audio';
			else if (/^v/gi.test(track.id)) type = 'video';

			return type;
		},
		getStreamPushUrl(backupIngest) {
			const { uiRegion: region } = this.stream;
			let hostname = region.hostname;

			if (this.failoverIngestToggleState && !backupIngest && this.hasFailoverIngestFeature) {
				hostname = this.failoverPrimaryIngest;
			}
			
			if (this.failoverIngestToggleState && backupIngest) {
				hostname = this.failoverBackupIngest;	
			}

			let pushUrl = `rtmp://${hostname}`;
			if (region.rtmpPort !== 1935) {
				pushUrl += ':' + region.rtmpPort;
			}

			// @todo
			let prefixPath = _.get(region, 'config.prefixPath');
			prefixPath = prefixPath || '/static';
			prefixPath = _.replace(prefixPath, /^\/\//gi, '/');

			pushUrl += prefixPath;


			if (!this.hasFailoverIngestFeature && backupIngest) {
				pushUrl = 'xxxxxxxxxxxxxx';
			}

			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				pushUrl = 'rtmp://fifa.castr.io/static'
			}

			return pushUrl;
		},
		getStreamPullUrl() {
			let server = this.getStreamPushUrl();
			// override hostname with region stream is being received
			let hostId = this.mediaPulse && this.mediaPulse.hostId;

			if (this.stream.user === '62c4b3398b2e840b11244bfd') {
				hostId = 'fifa'
			}

			if (hostId) {
				const mappedHostname = `${hostId}.castr.io`;
				const defaultHostname =  this.stream.uiRegion.hostname;
				server = server.replace(defaultHostname, mappedHostname);
				if(this.failoverIngestToggleState && this.hasFailoverIngestFeature) {
					server = `rtmp://${mappedHostname}/static/`
				}
			}

			if (!/\/$/gi.test(server)) {
				server += '/';
			}

			const pullUrl = server + this.stream.key;
			return pullUrl;
		},
		getAutoDetectDisabledClass(region) {
			if (this.autoDetectDisabled && region.hostname === 'live.castr.io') {
				return this.autoDetectDisabledClass;
			}
		},
		gotoUpgrade(){
			window.location.href = `/app/subscribe?category=live`;
		},
		getStreamURLAndKey() {
				let server = this.getStreamPushUrl();
				let key = this.streamKey;
				const UrlAndKey = server + '/' + key;

				return UrlAndKey;
		},
		checkActivems(){
			let hasActivems = false;
			this.subscription.map((s)=>{
				if(s && s.enabled && s.package.category == 'restream'){
					hasActivems = true;
				}
			});
			return hasActivems;
		},
		changePasstoggle(){
			this.isPasswordEnabled = !this.isPasswordEnabled;
		},
    showStreamToggleModal(ev) {
			ev.preventDefault();
			ev.stopPropagation();
			if(!this.checkActivems() && this.stream.type == 'restream' && !this.stream.enabled){
			// this.$root.$emit('bv::show::modal', 'discontinued-ms');
			this.$root.$emit('bv::show::modal', 'create-aio')
			return;
			}
			if(this.notAllowed && !this.stream.enabled){
				this.$root.$emit('bv::show::modal', 'increase-limit');
			}
			if(this.cannotToggleStream) return;
			// if (this.stream.enabled){
			if ((!this.notAllowed || (this.notAllowed && this.stream.enabled))){
				this.$root.$emit('bv::show::modal', 'modalToggleStream' + this.stream._id , '#btnShow')
			}
		},
	}
};

function isMixerFTLSource(pullUrl) {
	// return /^https?\:\/\/(www\.)?mixer\.com/gi.test(pullUrl)
	return /^https?\:\/\/((\w+)\.)?mixer\.com/gi.test(pullUrl);
}
</script>

<style>
.password-toggle .custom-control-label::after {
	background-image: url('~@/assets/icons/unlocked.svg');
}

.password-toggle .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url('~@/assets/icons/lock.svg');
}

</style>

<style scoped>
.flex-center {
	display: flex;
    justify-content: center;
    align-items: center;
}

.video-thumb, .video-wrapper {
  width: 100%;
  min-height: 236px;
  background-color: #000;
  position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px 8px 0 0;
}
.player-overlay {
	border-radius: 0 0 6px 6px;
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
}
:deep(.player-overlay .position-absolute) {
	width: 100%;
	text-align: center;
}
:deep(.player-overlay .bg-blured) {
	background-color: rgba(30, 39, 69, 0.95);
	margin: -1rem;
	width: calc(100% + 2rem);
}
.b-overlay {
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);	
}
.btn-sidebar-collapse {
	padding: 7px 16px;
}
.btn-sidebar-collapse:active, .btn-sidebar-collapse:hover {
	color: #fff;
}
.btn-sidebar-collapse.not-collapsed svg {
	transform: translateY(-50%) rotate(0deg);
	transition: all .3s ease-in-out;
}
.btn-sidebar-collapse.collapsed svg {
	transform: translateY(0%) rotate(180deg);
	transition: all .3s ease-in-out;
}
.show-counter {
	font-size: var(--font-s-s);
	left: -10px;
}
.region-name {
	font-weight: 400;
	color: #fff;
}
.auto-detect-disabled {
	pointer-events: none;
	opacity: 0.6;
}
.region-header .dropdown-header {
	white-space: normal;
}
:deep(.region .region-list) {
	color: var(--c-dark-9);
	height: 350px;
	overflow-y: auto;
}
:deep(.region .region-list .checkin-circle) {
	color: transparent;
}
:deep(.region .region-list li:hover), :deep(.region .region-list li:hover .checkin-circle) {
	color: #fff;
	background-color: var(--c-dark-4);
}
:deep(.region .region-list .checked), :deep(.region .region-list .checked .checkin-circle) {
	color: #fff;
	background-color: transparent;
}
:deep(.region .region-list li:first-child) {
	background-color: var(--c-dark-4);
	position: sticky;
	top:0;
}
:deep(.region .region-list li:last-child) {
	background-color: var(--c-dark-4);
	position: sticky;
	bottom:0;
}
.region-title {
	font-weight: 400;
	color: #fff;
}
:deep(.region .b-dropdown-form) {
	padding: .75rem 1.5rem;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.beta_backup {
	margin-right: 36px;
}
.backup_switch {
	margin-right: -15px;
}
.srt-split:after {content: "";width: 100%;height: 1px;background: var(--c-dark-4);position: absolute;left: 0;top: unset;bottom: 10px;}
.srt-split span {
    background: var(--c-dark-2);
    z-index: 9;
}

.srt-split .dropdown-toggle:after {
    transform: translateY(2px);
}
.srt-split {
    transform: translateY(6px);
	cursor: pointer;
}
.srt-split .dropdown-toggle.toggled:after {
    border-top: 0;
    border-bottom: 0.3em solid;
}
@supports not (backdrop-filter: blur(2px)) {
	:deep(* .blur-fix) {
		filter: blur(2px)
	}
}
@media (max-width: 767px) {
	.btn-sidebar-collapse {
		margin-bottom: 1px;
	}
}
</style>
