<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import IconBase from '../icon/IconBase.vue'
import IconCopy from '../icon/IconCopy.vue'
import IconExternalLink from '../icon/IconExternalLink.vue'
import FormButton from '../Atoms/FormButton.vue'
import StreamService from '@/services/StreamService'
import FormSwitch from '../Atoms/FormSwitch.vue'
import FormInput from '../Atoms/FormInput.vue'
import Alert from '../Atoms/Alert.vue'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  playerUrl: {
    type: String
  },
  streamMeta: {
    type: Object,
  }
})

const processing = ref(false)

const features = ref(
  {
    audioOnly: {
      status: false,
    },
    embedAutoplay: {
      status: true,
    },
    responsive: {
      status: true,
    },
    loop: {
      status: false,
    },
    muted: {
      status: false,
    },
    hiddenControl: {
      status: false,
    },
    hiddenVolume: {
      status: false,
    },
    playbackSpeed: {
      status: false
    }
  }
)

const getHlsLink = computed(() => {
  const user = store.state.User
  if (user.hlsForVod && user.hlsUrlForVod && user.hlsUrlForVod !== 'n/a') {
    return `https://${user.hlsUrlForVod}/videos/${props.vodFolder?.key}/${props.vodFile?.id}/index.m3u8`;
  }
  const host = "singlemusic-vod.akamaized.net";
  const url = `https://${host}/encoded/${props.vodFolder?.key}/${props.vodFile?.id}/index.m3u8`;
  return url;
})

const isPlaybackUrlAllowed = computed(() => {
  const subs = store.state.User.subscriptions || [];
  const active = subs.filter(x => x.enabled && ['vod', 'live'].includes(x.category));
  return active.some((sub) => {
    if (sub.definitionOverride && !_.isNil(sub.definitionOverride.mp4PlaybackUrl)) {
      return sub.definitionOverride.mp4PlaybackUrl;
    } else if (sub.package && !_.isNil(sub.package.definition.mp4PlaybackUrl)) {
      return sub.package.definition.mp4PlaybackUrl;
    } else {
      return false;
    }
  });
})

const playbackUrl = computed(() => `https://video-files.castr.net/${props.vodFolder?.key}/${props.vodFile?.id}`)

const hasHlsLink = computed(() => !!store.state.User?.hlsForVod)

const emit = defineEmits({
  'route-change': (id) => { return id },
  'video-update': (value) => { return value },
  'video-removed': (value) => { return value }
})

onMounted(async () => {
  getMeta()
})

watch(() => props.streamMeta, async () => {
  getMeta()
})
const getMeta = async () => {
  if (props.streamMeta?.videoMeta) {
    features.value.playbackSpeed.status = props.streamMeta?.videoMeta.playbackSpeed || false
    features.value.embedAutoplay.status = props.streamMeta?.videoMeta.embedAutoplay
  }
}

const toggleFeature = async (featureName) => {
  // await saveSetting(featureName, features.value[featureName].status, false, props.vodFile._id);
  try {
    await StreamService.saveStreamMetadata(route.params.streamId, featureName, features.value[featureName].status, props.vodFile._id);
    // clear stream meta cache in embed player
    StreamService.clearStreamMetadataCache(route.params.streamId);
    notify({ group: 'success', text: 'Setting saved' })
  } catch (e) {
    console.log(e);
    notify({ group: 'error', text: 'could not save changes' });
  }
}

const getEpisodeIframeSnippet = () => {
  const iframeSrc = props.playerUrl;
  let audioOnly = ''
  let loop = ''
  let muted = ''
  let hiddenControl = ''
  let hiddenVolume = ''
  features.value.audioOnly.status ? audioOnly = '&onlyAudio=true' : audioOnly = ''
  features.value.loop.status ? loop = '&loop=on' : loop = ''
  features.value.muted.status ? muted = '&muted=on' : muted = ''
  features.value.hiddenControl.status ? hiddenControl = '&controls=off' : hiddenControl = ''
  features.value.hiddenVolume.status ? hiddenVolume = '&hideSidebarVolume=true' : hiddenVolume = ''
  let queryString = audioOnly + loop + muted + hiddenControl + hiddenVolume
  if (queryString.length && !props.vodFolder.liveParent) {
    queryString = '?' + queryString.substring(1)
  }
  return `<iframe src="${iframeSrc + queryString}" ${features.value.responsive.status ? 'width="100%" style="aspect-ratio: 16/9; min-height: 340px;"' : 'width="590" height="431"'} frameborder="0" scrolling="no" ${features.value.embedAutoplay.status ? 'allow="autoplay"' : ''} allowfullscreen  webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`
}

const clipboardCopy = async (value) => {
  await navigator.clipboard.writeText(value);
  notify({ group: 'info', text: 'Copied to clipboard' });
}
</script>

<template>
  <section>
    <h3 class="text-lg font-mediun mt-6 !mb-2">Configure embed video</h3>
    <p class="text-surface-8 text-sm">Set settings and copy & paste the HTML code to your website to display the video.
    </p>
    <Spinner v-if="processing" text="Retrieving data..." classes="text-surface-8 my-8" spinner-color="var(--c-dark-8)"
      spinner-size="15px" />
    <ul v-if="!processing" class="flex flex-wrap gap-x-6 gap-y-2">
      <li>
        <FormSwitch size="sm" v-model="features.responsive.status" label="Responsive" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.embedAutoplay.status" @change="toggleFeature('embedAutoplay')" label="Autoplay" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.hiddenVolume.status" label="Hide Audio Section" />
      </li>
      <li v-if="vodFolder?.liveParent">
        <FormSwitch size="sm" v-model="features.audioOnly.status" label="Audio Only" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.loop.status" label="Loop" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.muted.status" label="Muted" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.hiddenControl.status" label="Hide Controls" />
      </li>
      <li>
        <FormSwitch size="sm" v-model="features.playbackSpeed.status" @change="toggleFeature('playbackSpeed')" label="Playback speeds" />
      </li>
    </ul>
    <textarea class="bg-surface-3 w-full rounded-md p-2 text-sm !mb-2 !mt-4 block" rows="3"
      @click="clipboardCopy(getEpisodeIframeSnippet)" :value="getEpisodeIframeSnippet()"></textarea>
    <div class="flex gap-x-2 mb-6">
      <FormButton @click="clipboardCopy(getEpisodeIframeSnippet())" size="sm" type="secondary">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-copy />
        </icon-base>
        Copy to Clipboard
      </FormButton>
    </div>
    <div class="flex items-end relative !mb-2">
      <FormInput customClasses="!pr-14" readonly label="Playback URL" @click="clipboardCopy(playerUrl)"
        :modelValue="playerUrl" />
      <FormButton class="absolute bottom-1 right-6" @click="clipboardCopy(playerUrl)" size="sm" type="link" isIcon>
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-copy />
        </icon-base>
      </FormButton>
      <FormButton class="absolute bottom-1 right-0" target="_blank" :href="playerUrl" type="link" isIcon size="sm">
        <icon-base class="shrink-0" fill="transparent">
          <icon-external-link />
        </icon-base>
      </FormButton>
    </div>
    <div class="flex items-end relative !mb-2" v-if="hasHlsLink">
      <FormInput readonly customClasses="!pr-14" label="HLS Manifest" @click="clipboardCopy(getHlsLink)"
        :modelValue="getHlsLink" />
      <FormButton class="absolute bottom-1 right-0" @click="clipboardCopy(getHlsLink)" size="sm"  type="link" isIcon>
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-copy />
        </icon-base>
      </FormButton>
    </div>
    <template v-if="!vodFolder.liveParent">
      <hr class="my-6" />
      <div  class="flex items-end relative !mb-2 " v-if="isPlaybackUrlAllowed">
        <FormInput readonly customClasses="!pr-14" label="MP4 playback URL" @click="clipboardCopy(playbackUrl)"
          :modelValue="playbackUrl" />
        <FormButton class="absolute bottom-1 right-0" @click="clipboardCopy(playbackUrl)" size="sm" type="link" isIcon>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-copy />
          </icon-base>
        </FormButton>
      </div>
      <div v-else>
        <label>MP4 playback URL</label>
        <Alert>
          Only available in Ultra Plan.&nbsp;<a class="underline inline" target="_blank" href="/app/subscribe">Upgrade
            now</a>.
        </Alert>
      </div>
    </template>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
