<script setup>
import _ from 'lodash'
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useNotification } from "@kyvg/vue3-notification"
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import IconBase from '../icon/IconBase.vue'
import IconFile from '../icon/IconFile.vue'
import IconGlobe from '../icon/IconGlobe.vue'
import IconCross from '../icon/IconCross.vue'
import IconSparkles from '../icon/IconSparkles.vue'
import FormButton from '../Atoms/FormButton.vue'
import { bytes } from '../../filtersNew'
import { TooltipComponent as EjsTooltip } from "@syncfusion/ej2-vue-popups"
import MetricsService from '../../services/MetricsService'
import UserService from "@/services/UserService"
import StreamService from '@/services/StreamService'
import FormSwitch from '../Atoms/FormSwitch.vue'
import FormInput from '../Atoms/FormInput.vue'
import DropDownMenu from '../../components/Atoms/DropDownMenu.vue';
import DropDownMenuItem from '../../components/Atoms/DropDownMenuItem.vue';
import Alert from '../Atoms/Alert.vue'
import languages from '@/Utils/languages'

const { notify } = useNotification()
const store = useStore()
const instance = getCurrentInstance();
const root = instance.proxy.$root;
const route = useRoute()
const router = useRouter()

const selectedLang = ref({ code: '', name: 'Select Language', nativeName: '' })

const languagesDropdown = ref(null)

const isLoading = ref(false)

const isGenerateCaption = ref(false)

const captions = ref({
  status: false,
  isProcessing: false,
  path: '',
  language: '',
})

const uploadInput = ref(null)
const languageFilter = ref('')

const props = defineProps({
  vodFile: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  },
  vodFolder: {
    type: Object,
    required: true,
    validator(v) {
      return Object.prototype.hasOwnProperty.call(v, '_id');
    }
  }
})

const emit = defineEmits({
  'video-removed': (value) => { return value }
})

onMounted(async () => {
  getCaptions()
})

watch(() => route.params, async () => {
  getCaptions()
})

const getCaptions = async () => {
  //TODO Move this call in parent component to prevent double Meta call for components (Chaptes, Schedule, etc.)

  const vodMeta = await StreamService.getStreamPlaylistVideoMetaData(route.params.videoId);

  if (vodMeta?.captions) {
    captions.value = vodMeta?.captions

    if (vodMeta.captions.language) {
      selectedLang.value = languages.find(x => x.code === vodMeta.captions.language)
    }
    checkCaptionGenerationStatus();
  }
}

const checkCaptionGenerationStatus = async () => {
  if (!captions.value.isProcessing) {
    updateCaption({ isProcessing: false });
    isGenerateCaption.value = false;
    return;
  }

  isGenerateCaption.value = true;
  try {
    let videoId = route.params.videoId;
    if (props.vodFile.replaceDetails && props.vodFile.replaceDetails.videoId) {
      videoId = props.vodFile.replaceDetails.videoId;
    }
    const result = await StreamService.checkFileExists(route.params.streamId, videoId);
    if (result.exists) {
      notify({ group: 'success', text: 'Finished generating caption!' });
      updateCaption({ path: result.uri, isProcessing: false, language: '' });
      isGenerateCaption.value = false;
    }
    setTimeout(() => {
      checkCaptionGenerationStatus();
    }, 10000);
  } catch (err) {
    console.log(err);
  }
}

const onSelectLanguage = (lang) => {
  selectedLang.value = lang;
  languageFilter.value = ''
  languagesDropdown.value.toggleDropdown()
}
const languagesFiltered = computed(() => {
  return languages.filter(language => language.name.toLowerCase().includes(languageFilter.value.toLowerCase()))
})

const aiTooltipText = computed(() => captions.path
  ? 'Please remove uploaded file first.'
  : 'AI Generation is currently unable to process files that exceed 2GB in size or have a duration longer than 4 hours. To ensure the inclusion of captions, kindly upload the corresponding subtitles.'
)

const maxAiCaptionGenerationSizeReached = computed(() => (props.vodFile?.mediaInfo?.durationMins > 240) || (props.vodFile?.bytes / 1000000000) > 2)

const onClickUploadCaptions = () => {
  uploadInput.value.click()
}
const startUpload = async () => {
  captions.value.isProcessing = true;

  isLoading.value = true;
  const fdata = new FormData();
  fdata.append("file", uploadInput.value.files[0]);

  try {
    const result = await StreamService.uploadVttFile(fdata, route.params.streamId, route.params.videoId);
    updateCaption({ path: result.uri, isProcessing: false, language: selectedLang.value.code, });
    notify({ group: 'success', text: 'Successfully uploaded the caption file!' });
  } catch (err) {
    console.log(err);
    notify({ group: 'error', text: 'Failed to upload the caption file!' });
  }

  isLoading.value = false;
}

const updateCaption = async (payload) => {
  captions.value = {
    ...captions.value,
    ...payload,
  }
  await StreamService.updateStreamPlaylistVideoMetaData(route.params.streamId, route.params.videoId, { captions: captions.value })
}
const onClickAiGenerateCaptions = async () => {
  if (captions.value.isProcessing || captions.value.path) return;
  updateCaption({ isProcessing: true });
  isGenerateCaption.value = true;
  try {
    let videoId = route.params.videoId;
    if (props.vodFile.replaceDetails && props.vodFile.replaceDetails.videoId) {
      videoId = props.vodFile.replaceDetails.videoId;
    }
    const result = await StreamService.generateVttAi(route.params.streamId, videoId);
    if (result.success) {
      notify({ group: 'success', text: 'Caption generation has started!' });
      checkCaptionGenerationStatus();
    } else {
      notify({ group: 'error', text: 'Unable to start caption generation!' });
      updateCaption({ isProcessing: false });
      isGenerateCaption.value = false
    }
  } catch (e) {
    console.log(e);
    notify({ group: 'error', text: 'Unable to start caption generation!' });
    updateCaption({ isProcessing: false });
  }
}
const formatFilePath = (str) => {
  if (!captions.value.language) return 'Captions generated by AI';
  const x = str.split('/');
  if (!x.length) return '';
  return x[x.length - 1];
}
const deleteExistingCaptionFile = async () => {
  // this.featureProcessing.deleteCaptionFile = true;
  try {
    const result = await StreamService.deleteCaptionFile(route.params.streamId, route.params.videoId);
    if (result.success) {
      notify({ group: 'success', text: 'Successfully deleted existing caption file!' });
      updateCaption({ path: '', language: '' });
      selectedLang.value = { code: "", name: "Select Language", nativeName: "" };
    } else {
      notify({ group: 'error', text: 'Failed to delete caption!' });
    }
  } catch (err) {
    console.log(err);
    notify({ group: 'error', text: 'Failed to delete caption!' });
  }
  // this.featureProcessing.deleteCaptionFile = false;
}
</script>

<template>
  <section>
    <h3 class="text-lg font-mediun mt-6 !mb-2">Add captions to the video</h3>
    <p class="text-surface-8 text-sm !mb-2">You can upload .vtt or .srt files or try automatic generation to let
      computers do it for you.</p>
    <Alert class="!mb-4">
      <p>AI generates English captions only. For non-English videos, upload your file for better accuracy. <a
          class="underline text-sureface-8" target="_blank"
          href="https://docs.castr.com/en/articles/7916627-generate-captions-for-vod">Learn More</a>.</p>
    </Alert>
    <div class="flex gap-2 items-center flex-wrap">
      <div class="max-h-48 hidden"></div>
      <DropDownMenu ref="languagesDropdown" type="secondary" maxHeight="48">
        <template #toggle-button>
          <icon-base class="!mr-2" fill="none">
            <icon-globe />
          </icon-base>
          {{ selectedLang.name }}
          <icon-base class="!ml-1">
            <icon-chevron-up-down />
          </icon-base>
        </template>
        <template #menu-items>
          <DropDownMenuItem isCustom class="!p-2 hover:!bg-transarent">
            <FormInput v-model="languageFilter" />
          </DropDownMenuItem>
          <DropDownMenuItem v-for="(lang, index) in languagesFiltered" :key="index" @click="onSelectLanguage(lang)">
            <span class="capitalize">{{ lang.name }} - {{ lang.nativeName }}</span>
          </DropDownMenuItem>
        </template>
      </DropDownMenu>
      <ejs-tooltip v-if="!selectedLang.code" content="You need to select the language before uploading.">
        <FormButton type="secondary" disabled>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-file />
          </icon-base>
          Upload file
        </FormButton>
      </ejs-tooltip>
      <FormButton v-else type="secondary" @click="onClickUploadCaptions"
        :disabled="!!captions.path || captions.isProcessing" :isLoading="isLoading">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-file />
        </icon-base>
        Upload file
      </FormButton>
      <input class="!hidden" ref="uploadInput" @change="startUpload" type="file" :multiple="false" accept=".srt,.vtt">
      <ejs-tooltip v-if="captions.path || maxAiCaptionGenerationSizeReached" :content="aiTooltipText">
        <FormButton type="secondary" disabled>
          <icon-base class="mr-2 shrink-0" fill="transparent">
            <icon-sparkles />
          </icon-base>
          Generate Captions Using AI
        </FormButton>
      </ejs-tooltip>
      <FormButton v-else type="secondary" @click="onClickAiGenerateCaptions"
        :isLoading="isGenerateCaption"
        :disabled="maxAiCaptionGenerationSizeReached || !!captions.path || captions.isProcessing">
        <icon-base class="mr-2 shrink-0" fill="transparent">
          <icon-sparkles />
        </icon-base>
        Generate Captions Using AI
      </FormButton>
      <FormSwitch class="ml-auto" label="Enabled" labelPosition="left"
        @change="updateCaption({ status: !captions.status })" :modelValue="captions.status" />
    </div>
    <div class="mt-6 flex gap-x-2 items-center !border border-surface-3 rounded-md !p-2 text-surface-8"
      v-if="captions.path">
      <icon-base class="mr-2 shrink-0" fill="transparent">
        <icon-file />
      </icon-base>
      {{ formatFilePath(captions.path) }}
      <FormButton class="ml-auto" type="link" @click="deleteExistingCaptionFile">
        <icon-base class="shrink-0" fill="transparent">
          <icon-cross />
        </icon-base>
      </FormButton>
    </div>
  </section>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
