<script>
export default {
  compatConfig: {
    MODE: 3,
    COMPONENT_V_MODEL: false,
  }
}
</script>
<script setup>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useMatchMedia, useInnerWidth } from '@/utils'
import { useNotification } from "@kyvg/vue3-notification"
import StreamsWidgetLiveStreamTab from './StreamsWidgetLiveStreamTab.vue';
import StreamsWidgetVodTab from './StreamsWidgetVodTab.vue';
import StreamsWidgetBreaks from './StreamsWidgetBreaks.vue';
import FormButton from '../Atoms/FormButton.vue';
import IconBase from '../icon/IconBase.vue';
import IconPlus from '../icon/IconPlus.vue';
import IconCross from '../icon/IconCross.vue';

const props = defineProps({
  placement: {
    type: String,
    default: '#streams-widget-wrapper'
  }
})

const emit = defineEmits({
  'add-stream': (value) => { return value }
})

const isDesktop = useMatchMedia('(min-width: 768px)');

const route = useRoute()
const router = useRouter()

const { notify } = useNotification()

const store = useStore()

const playoutConfig = computed(() => store.getters['Playout/playoutConfig'])

const isVisible = ref(false)

const changeRouteQuery = (routeQuery) => {
  if (route.query?.tab != routeQuery) router.push({ query: { tab: routeQuery } })
}
const addStream = (stream) =>{
  if (stream && !isDesktop.value) {
    notify({ group: 'success', text: `${stream.type === 'live' ? stream.name : stream.fileName} added` })
  } else if (!isDesktop.value) {
    notify({ group: 'success', text: `Break added` })
  }
  emit('add-stream', stream)
}

const computedWrapperClass = computed(() => {
  const basicClass = '!px-4 md:!px-0 md:!visible transition-transform duration-500 md:translate-y-0 fixed md:static bottom-0 left-0 z-20 bg-surface-2 md:bg-transparent w-full rounded-t-2xl !shadow-lg md:!shadow-none'
  const closeClass = 'ease-in translate-y-full'
  const openClass = 'ease-out translate-y-0'
  return `${basicClass} ${isVisible.value ? openClass : closeClass}`
})
const computedOverlayClass = computed(() => {
  const basicClass = 'bg-black/20 absolute z-10 h-full w-full top-0 left-0'
  const closeClass = '!invisible'
  const openClass = '!visible open'
  return `${basicClass} ${isVisible.value ? openClass : closeClass}`
})
const toggleView = () => {isVisible.value = !isVisible.value}

</script>

<template>
  <teleport :disabled="isDesktop" :to='props.placement'>
    <div :class="computedWrapperClass">
    <header class="flex md:hidden items-center justify-between bg-surface-3 rounded-t-2xl -mx-4 p-3">
      <h3>Select source</h3>
      <div class="md:hidden rounded-full bg-surface-4">
        <FormButton type="link" @click="toggleView" isIcon>
          <icon-base>
            <icon-cross />
          </icon-base>
        </FormButton>
      </div>
    </header>
    <section class="flex text-tiny space-x-4 border-b border-surface-3 overflow-x-auto max-w-full my-6">
      <button
        class="capitalize hover:text-white hover:no-underline py-1 border-b-2 flex items-center text-nowrap"
        @click="changeRouteQuery('livestream')"
        :class="route.query?.tab === 'livestream' ? '!border-surface-9 text-white' : 'text-surface-8 border-transparent'">
        Livestream
      </button>
      <button class="capitalize hover:text-white hover:no-underline py-1 border-b-2 flex items-center text-nowrap"
        @click="changeRouteQuery('vod')"
        :class="!route.query?.tab || route.query?.tab === 'vod' ? '!border-surface-9 text-white' : 'text-surface-8 border-transparent'">
        VOD Files
      </button>
    </section>
    <section>
      <StreamsWidgetLiveStreamTab @add-livestream="addStream" v-if="route.query?.tab === 'livestream'">
        <StreamsWidgetBreaks @add-break="addStream" class="mb-2" />
      </StreamsWidgetLiveStreamTab>
      <StreamsWidgetVodTab @add-vod="addStream" v-else>
        <StreamsWidgetBreaks @add-break="addStream" class="mb-2" />
      </StreamsWidgetVodTab>
    </section>
  </div>
  </teleport>
  <teleport to='body'>
    <div class="modal-overlay" :class="computedOverlayClass"></div>
  </teleport>
  <FormButton type="secondary" @click="toggleView" isIcon class="md:hidden">
    <icon-base>
      <icon-plus />
    </icon-base>
  </FormButton>
</template>

<style scoped></style>
