<template>
	<div class>
		<b-modal 
			id="modal-paywall-add-email" 
			ref="modalPaywallAddEmail" 
			:size="modalSize" 
			hide-header 
			hide-footer 
			centered
		>
			<b-row>
				<b-col>
					<h5>New email access grant</h5>
					<div class="mt-3">
						<label>Email</label>
						<b-input 
							size="md" 
							v-model="email" 
							class="mb-2" placeholder="Enter email" />
					</div>
					<div class="text-right w-100">
						<b-button variant="outline-secondary" class="mr-2" size="md" @click="dismiss">
							Cancel
						</b-button>
						<b-button 
							variant="primary" 
							@click="onConfirm" 
							size="md"
							:disabled="this.email === '' || processing ">
							{{ !processing ? 'Confirm' : 'Saving...' }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import ClipLoader from "@/components/ui/ClipLoader.vue";
import PaywallService from '@/services/PaywallService'

export default {
	name: 'addPaywallEmailAccess',
	components: {
        ClipLoader,
    },
	props: {
		paywall: {
			type: Number,
			required: false
		},
		modalSize: {
			type: String,
			default: 'sm',
			validator(v) {
				return ['xl', 'lg', 'md', 'sm'].some(size => size === v);
			}
		},
	},
	data() {
		return {
			processing: false,
            email: ''
		};
	},
	computed: {
	},
    async mounted() {},
	methods: {
		dismiss() {
			this.$refs.modalPaywallAddEmail.hide();
			this.email = ''
			setTimeout(() => { this.processing = false; }, 1000);
		},
		async onConfirm() {
			try {
				this.processing = true
				const resp = await PaywallService.addPaywallEmailAccess(this.paywall, this.email);
                this.$notify({ group: 'success', text: 'Email created' })
                this.$emit('email-created', resp)
                this.dismiss()
			} catch (err) {
				this.$notify({
					group: 'error',
					title: "Couldn't create paywall email access",
					text: err.message
				});
			}
			this.processing = false
		},
	},
};
</script>

<style>
</style>