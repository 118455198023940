
<template>
	<div class>
		<!-- Modal Component -->
		<!-- modal-class="modal-add-stream modal-fullscreen1 modal-fullscreen-right modal-platform" -->
		<b-modal @hidden="unselectForm" @shown="getMemberpermission" size="lg" hide-header hide-footer centered id="update-member" ref="UpdateMemberModal"
			>
			<!-- stream types -->
			<div>
				<b-row>
					<b-col>
						<h4 class="text-s-xl mb-1 text-500">Member's Role and Permissions</h4>
						<p class="text-s-l text-dark-8 font-normal mb-4">All Admins have full permissions. If you want to customize
						permissions, please change their role to Moderator.</p>
						<hr class="my-3" />
					</b-col>
				</b-row>
				<b-row>
					<b-col class="mb-3">
						<label class="text-s-s text-dark-8 font-normal mb-2">
							Role
						</label>
						<b-dropdown class="w-100 team_dropdown mr-3 team-dropdown" variant="dark-3" caret
							toggle-class="d-flex align-items-center justify-content-between font-weight-normal"
							menu-class="dropdown-menu_md">
							<template #button-content>
								<div class="d-flex align-items-center">
									<span class="ml-1 text-capitalize">{{ currentPermissions && currentPermissions.role }}</span>
								</div>
							</template>
							<b-dropdown-item @click="changeRole('admin')">
								<div class="ml-2 w-100 h-100">Admin</div>
							</b-dropdown-item>
							<b-dropdown-item @click="changeRole('moderator')">
								<div class="ml-2 w-100 h-100">Moderator</div>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
				</b-row>
				<template v-if="currentPermissions && currentPermissions.role == 'moderator'">
					<b-row class="mt-5 no-gutters-xs sticky-header">
						<b-col cols="8" md="4">

						</b-col>
						<b-col class="text-s-s text-dark-6 label-v text-md-center" cols="1"  md="2">
							View
						</b-col>
						<b-col class="text-s-s text-dark-6 d-md-flex justify-content-start justify-content-md-center align-items-md-center label-v text-lg-center" cols="1" md="2">
							Edit
							<svg class="ml-2 d-none d-md-block" v-b-tooltip.hover title="Include permissions to add new, edit information, download... (if applicable)" width="15" height="15" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8ZM9 5C9 5.55228 8.55229 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5ZM8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5V11.5C7.25 11.9142 7.58579 12.25 8 12.25C8.41421 12.25 8.75 11.9142 8.75 11.5V8.5Z" fill="#8794B5"/>
							</svg>
						</b-col>
						<b-col class="text-s-s text-dark-6 label-v text-md-center" cols="1"  md="2">
							Disable/Enable
						</b-col>
						<b-col class="text-s-s text-dark-6 label-v text-md-center" cols="1"  md="2">
							Delete
						</b-col>
					</b-row>
					<hr class="my-3" />
					<div v-for="(permissionList, index) in permissions" :key="index">
						<b-row class="no-gutters-xs">
							<b-col cols="8" md="4">
								<b-button v-if="permissionList.sections" variant="icon" class="collapse-b" v-b-toggle="('collapse' + index)">
									<svg class="text-dark-9" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L6.03033 4.96967C6.32322 5.26256 6.32322 5.73744 6.03033 6.03033L1.53033 10.5303C1.23744 10.8232 0.762563 10.8232 0.46967 10.5303C0.176777 10.2374 0.176777 9.76256 0.46967 9.46967L4.43934 5.5L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z"
											fill="currentColor" />
									</svg>
								</b-button>{{ permissionList.label }}
							</b-col>
							<b-col v-for="(option, index) in permissionList.parrentOptions" :key="index" cols="1" md="2" class="text-right text-md-center">
								<b-form-checkbox v-if="!option.hidden" v-model="option.value" @change="togglePermission(option, index)" :indeterminate="indeterminate(permissionList, option, index)">
								</b-form-checkbox>
							</b-col>
							<b-col cols="12">
								<b-collapse :id="('collapse' + index)" class="mt-2">
									<b-card body-class="px-0" class="" bg-variant="dark-1">
										<b-row class="no-gutters-xs" v-for="(section, index) in permissionList.sections" :key="index">
											<b-col class="pl-3 pl-md-5" cols="8" md="4">
											{{ section.label }}
											</b-col>
											<template v-for="(item, index) in section.items">
												<b-col cols="1" md="2" class="text-right text-md-center">
													<b-form-checkbox v-if="!item.hidden" v-model="item.value" @change="togglePermission(item, index)" >
													</b-form-checkbox>
												</b-col>
											</template>
											<b-col class="px-2 px-md-5" v-if="(index === 0)" cols="12">
												<hr class="my-3" />
											</b-col>
										</b-row>
									</b-card>
								</b-collapse>
							</b-col>
						</b-row>
						<hr class="my-3" />
					</div>
				</template>
				<b-row>
					<b-col  class="text-right mt-5">
						<b-button variant="outline-secondary" size="md" @click="unselectForm"
							class="text-center mr-2">
							Cancel
						</b-button>
						<b-button variant="primary" size="md" class="" @click="onConfirm()"
							:disabled="!this.allowSubmit || areAllPermissionsDisabled">
							{{ this.currentPermissions && (this.currentPermissions.role === 'moderator' && (this.currentPermissions.liveStreamDelete || this.currentPermissions.liveStreamManage || this.currentPermissions.liveStreamToggle || this.currentPermissions.hostingManage || this.currentPermissions.hostingDelete)) ? 'Next Step' : 'Update Permissions' }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import UserService from "@/services/UserService";
import ClipLoader from "@/components/ui/ClipLoader.vue";
import { mapGetters } from 'vuex';

export default {
	name: 'updateMemberModal',
	components: {
		ClipLoader
	},
	data() {
		return {
			processing: true,
			index: null,
			allowSubmit: false,
			currentPermissions: null,
			mid: null,
			permissions: [
				{
					name: 'restream',
					label: 'Multistream',
					status: true,
					disable: false,
					parrentOptions: [
						{
							hidden: true,
							value: false
						},
						{
							label: 'Multistream',
							name: "recordedStreamManage",
							value: true
						},
						{
							label: 'Multistream',
							name: "recordedStreamToggle",
							value: true
						},
						{
							label: 'Multistream',
							name: "recordedStreamDelete",
							value: true
						}
					],
					
				},
				{
					name: 'live',
					label: 'All in One Stream',
					status: true,
					disable: false,
					parrentOptions: [
						{
							hidden: true,
							value: false
						},
						{
							label: 'All in One Stream',
							name: 'liveStreamManage',
							value: true
						},
						{
							label: 'All in One Stream',
							name: 'liveStreamToggle',
							value: true
						},
						{
							label: 'All in One Stream',
							name: 'liveStreamDelete',
							value: true
						}
					],
					// 	{
					// 		label: 'Destinations',
					// 		name: 'destinations',
					// 		items: [
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 			{
					// 				label: 'Disable/enable Platform',
					// 				name: 'liveStreamPlatformToggle',
					// 				value: true
					// 			},
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 		]
					// 	},
					// ]
				},
				// transcoder
				// {
				// 	name: 'transcoder',
				// 	label: 'Transcoder',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'Transcoder',
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				// subsecond
				// {
				// 	name: 'subSecondStreams',
				// 	label: 'Sub Second Streams',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'Sub Second Streams',
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				{
					name: 'scheduled',
					label: 'Pre-recorded stream',
					status: true,
					disable: false,
					parrentOptions: [
						{
							hidden: true,
							value: false
						},
						{
							label: 'Pre-recorded stream',
							name: "preRecordedStreamManage",
							value: true
						},
						{
							label: 'Pre-recorded stream',
							name: "preRecordedStreamToggle",
							value: true
						},
						{
							label: 'Pre-recorded stream',
							name: "preRecordedStreamDelete",
							value: true
						}
					],
					// sections: [
					// 	{
					// 		label: 'Streams',
					// 		name: 'streams',
					// 		items: [
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 			{
					// 				label: 'Add/manage streams',
					// 				name: 'preRecordedStreamManage',
					// 				value: true
					// 			},
					// 			{
					// 				label: 'Disable/enable streams',
					// 				name: 'preRecordedStreamToggle',
					// 				value: true
					// 			},

					// 			{
					// 				label: 'Delete streams',
					// 				name: 'preRecordedStreamDelete',
					// 				value: true
					// 			},
					// 		]
					// 	},
					// 	{
					// 		label: 'Destinations',
					// 		name: 'destinations',
					// 		items: [
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 			{
					// 				label: 'Disable/enable Platform',
					// 				name: 'preRecordedStreamPlatformToggle',
					// 				value: true
					// 			},
					// 			{
					// 				hidden: true,
					// 				value: false
					// 			},
					// 		]
					// 	},
					// ]
				},
				// highlights
				// {
				// 	name: 'highlights',
				// 	label: 'Highlights',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'Highlights',
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				{
					name: 'maxVodItems',
					label: 'Video Hosting',
					status: true,
					disable: false,
					parrentOptions: [
						{
							hidden: true,
							value: false
						},
						{
							name: 'hostingManage',
							label: 'Add/manage folders & files',
							value: true
						},
						{
							hidden: true,
							value: false
						},
						{
							label: 'Delete folders/files',
							name: 'hostingDelete',
							value: true
						}
					],
				},
				// dynamic player
				// {
				// 	name: 'dynamicPlayer',
				// 	label: 'Dynamic Player',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'Transcoder',
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				// tv playout
				// {
				// 	name: 'tvPlayout',
				// 	label: 'TV Playout',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'TV Playout',
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				// Playlist
				// {
				// 	name: 'playlist',
				// 	label: 'Playlist',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'Playlist',
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				// showcase
				// {
				// 	name: 'showcase',
				// 	label: 'Showcase',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			label: 'Showcase',
				// 			name: "editShowcase",
				// 			value: true
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 	],
				// },
				{
					label: 'Paywall',
					name: 'paywall',
					parrentOptions: [
						{
							name: 'paywall',
							value: true
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						},
					]
				},
				{
					label: 'Team',
					name: 'team',
					parrentOptions: [
						{
							name: 'teamView',
							value: true
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						},
					]
				},
				// {
				// 	name: 'sub-accounts',
				// 	label: 'Sub-accounts',
				// 	status: true,
				// 	disable: false,
				// 	parrentOptions: [
				// 		{
				// 			label: 'Sub-accounts',
				// 			value: false,
				// 			hidden: true,
				// 		},
				// 		{
				// 			label: 'Sub-accounts',
				// 			value: false,
				// 			hidden: true,
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		}
				// 	],
				// 	sections: [
				// 		{
				// 			label: 'Sub-accounts',
				// 			name: 'subAccounts',
				// 			items: [
				// 				{
				// 					label: 'hidden',
				// 					name: 'subAccountInfoView',
				// 					hidden: true,
				// 					value: false
				// 				},
				// 				{
				// 					label: 'View/create sub-account',
				// 					name: 'subAccountInfoCreate',
				// 					value: false,
				// 					hidden: true,
				// 				},
				// 				{
				// 					hidden: true,
				// 					value: false
				// 				},
				// 				{
				// 					hidden: true,
				// 					value: false
				// 				},
				// 			]
				// 		},
				// 		{
				// 			label: 'Streams of sub-accounts',
				// 			name: 'subAccountsStreams',
				// 			items: [
				// 				{
				// 					label: `View sub-account's stream`,
				// 					name: 'subAccountStreamView',
				// 					value: false,
				// 					hidden: true,
				// 				},
				// 				{
				// 					hidden: true,
				// 					value: false
				// 				},
				// 				{
				// 					hidden: true,
				// 					value: false
				// 				},
				// 				{
				// 					hidden: true,
				// 					value: false
				// 				},
				// 			]
				// 		},
				// 	]
				// },
				{
					label: 'Developers',
					name: 'developers',
					parrentOptions: [
						{
							name: 'developers',
							value: true
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						}
					],
				},
				// {
				// 	label: 'Refer and Earn',
				// 	name: 'referAndEarn',
				// 	parrentOptions: [
				// 		{
				// 			name: 'refer',
				// 			value: true,
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		},
				// 		{
				// 			hidden: true,
				// 			value: false
				// 		}
				// 	],
				// },
				{
					label: 'Account (view only)',
					name: 'account',
					parrentOptions: [
						{
							name: 'accountView',
							value: true
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						},
						{
							hidden: true,
							value: false
						}
					],
				},
			],
			userSubscription: null,
		};
	},
	props: {
		teamId: {
			type: String,
		},
		member: {
			type: Object,
			default: null
		},
	},
	computed: {
		...mapGetters({
			baseSubscription: 'User/baseSubscription',
			addonSubscriptions: 'User/addonSubscriptions'
		}),
		subscriptions() {
			return this.$store.state.User.subscriptions;
		},
		activeTeam() {
			return this.$store.state.User.activatedTeam;
		},
		userloaded() {
			return this.$store.state.User.userloaded;
		},
		areAllPermissionsDisabled() {
			const sectionsPermissions = this.permissions.filter(permission => permission.sections)
			const parrentPermissions = this.permissions.filter(permission => !permission.sections)
			const isSectionsPermissionsOff = sectionsPermissions.every(permission => permission.sections.every(section => section.items.every( item => item.value === false)))
			const isParrentPermissionsOff = parrentPermissions.every(permission => permission.parrentOptions.every(option => option.value === false))
			return isSectionsPermissionsOff && isParrentPermissionsOff
			// return sectionsPermissions.every(permission => permission.sections.every(section => section.items.every( item => item.value === false)))
		}
	},
	async mounted() {
		// this.$refs.UpdateMemberModal.$on('hide', this.onDismiss);
		this.$refs.UpdateMemberModal.$on('shown', this.onInit);
		// let usersubs = await SubscriptionService.getUserSubscriptions(true, this.activeTeam);
		// this.userSubscription = usersubs;
		// await this.getMemberpermission();
		// this.checkOptions();
	},
	watch: {
		// member() {
		// 	this.getMemberpermission();
		// }
		// permissions: {
		// 	handler: function(permissions) {
		// 		permissions.map(setCheckboxes)
		// 		function setCheckboxes(item) {if (item.sections) {
		// 				const section1 = item.sections[0].items
		// 				const section2 = item.sections[1].items
		// 				// console.log('permissions[6]');
		// 				// console.log(permissions[7]);
		// 				// console.log(section2);
		// 				section1.map((element) => {
		// 					// console.log(section1.indexOf(item))
		// 					if (element.value === false && section2[section1.indexOf(element)].value === false && !section2[section1.indexOf(element)].hidden) {
		// 						item.parrentOptions[section1.indexOf(element)].value = false
		// 					}
		// 					if (element.value === true && section2[section1.indexOf(element)].value === true && !section2[section1.indexOf(element)].hidden) {
		// 						item.parrentOptions[section1.indexOf(element)].value = true
		// 					}
		// 					if (element.value === true && section2[section1.indexOf(element)].hidden) {
		// 						item.parrentOptions[section1.indexOf(element)].value = true
		// 					}
		// 					if (element.value === false && section2[section1.indexOf(element)].hidden) {
		// 						item.parrentOptions[section1.indexOf(element)].value = false
		// 					}
		// 				})
		// 				// permissions[6].parrentOptions[0] = permissions[6].sections[1].items[0]
		// 			}
		// 		}
		// 	},
		// 	deep: true
		// },
		baseSubscription(newPerm) {
			if(!newPerm) return;
			setTimeout(() => {
				this.checkOptions();
			}, 100)
		}
	},
	methods: {
		checkOptions() {
			this.userSubscription = { subscription: this.baseSubscription, addonSubscriptions: this.addonSubscriptions};
			let filterredCat = ['others', 'paywall', 'team', 'developers', 'referAndEarn', 'account'];
			if (this.userSubscription.subscription && this.userSubscription.subscription.enabled) {
				let packCat = _.get(this.userSubscription.subscription, ['package', 'category']);
				let vod = _.get(this.userSubscription.subscription, ['package', 'definition', 'maxVodItems']);
				if (packCat && (filterredCat.indexOf(packCat) == -1)) {
					filterredCat.push(packCat);
				}
				if (vod && (filterredCat.indexOf('maxVodItems') == -1) && (vod > 0)) {
					filterredCat.push('maxVodItems');
				}
			}
			const catCounteraddon = _.map(this.userSubscription.addonSubscriptions, function (single) {
				if (single.enabled) {
					let packCat = _.get(single, ['package', 'category']);
					let vod = _.get(single, ['package', 'definition', 'maxVodItems']);
					if (packCat && (filterredCat.indexOf(packCat) == -1)) {
						filterredCat.push(packCat);
						if(packCat === 'live') {
							const highlights = _.get(single, ['package', 'definition', 'maxHighlightCount']);
							const transcoders = _.get(single, ['package', 'definition', 'transcoder']);
							const playout = _.get(single, ['package', 'definition', 'maxTvPlayoutItems']);
							const playlist = _.get(single, ['package', 'definition', 'maxPlaylistCount']);
							const subsecond = _.get(single, ['package', 'definition', 'maxSubSecondStreams']);

							if(highlights) {
								filterredCat.push('highlights');
							}
							if(subsecond) {
								filterredCat.push('subSecondStreams');
							}
							if(playout) {
								filterredCat.push('tvPlayout');
							}
							if(playlist) {
								filterredCat.push('playlist');
							}
							if(transcoders) {
								filterredCat.push('transcoder')
							}
							// if(dynamicPlayer) {
								filterredCat.push('dynamicPlayer');
							// }
							filterredCat.push('highlights');
							filterredCat.push('showcase');

						}
					}
					if (vod && (filterredCat.indexOf('maxVodItems') == -1) && (vod > 0)) {
						filterredCat.push('maxVodItems');
					}
				}
			});
			const setPermissioncat = _.map(this.permissions, function (single) {
				let name = single.name;
				let isAllowed = filterredCat.indexOf(name);
				if ((isAllowed === -1)) {
					single.disable = true;
					single.status = false;
					(single?.parameters || []).map(p => {
						p.value = false;
					});
				}
			});
			
			this.permissions = this.permissions.filter(x => filterredCat.includes(x.name));
			this.processing = false;
		},
		checkStatus(index) {
			if (index != null) {
				this.$nextTick(() => {
					this.permissions[index].status = this.permissions[index].parameters.some(element => element.value === true);
					this.checkCurrentPermission();
				});
			} else {
				const setPermissions = this.permissions.map(p => {
					p.status = p.parameters.some(element => element.value === true);
				});
			}
		},
		async onInit() {
			// this.processing = true;
			this.checkOptions();
		},
		unselectForm() {
			this.dismiss();
			this.currentPermissions = null;
		},
		onDismiss() {
			// this.resetForm();
			setTimeout(() => {
				this.$parent.processing = false;
			}, 1000);
		},
		dismiss() {
			this.$refs.UpdateMemberModal.hide();
			this.processing = false;
			this.onDismiss();
		},
		resetForm() {
			this.role = this.member && this.member.role;
		},
		async onConfirm() {
			this.currentPermissions.liveStreamPlatformToggle = this.currentPermissions.liveStreamManage;
			if (this.currentPermissions && (this.currentPermissions.role === 'moderator' && (this.currentPermissions.liveStreamDelete || this.currentPermissions.liveStreamManage || this.currentPermissions.liveStreamToggle || this.currentPermissions.hostingManage || this.currentPermissions.hostingDelete))) {
					this.$root.$emit('bv::show::modal', 'modalChooseStreamForModerator')
					this.$emit('teamMemberInvitationInfo', {
						defaultPermissions: this.currentPermissions,
						action: 'update'
					})
					this.$emit('update-permissions', this.currentPermissions)
					this.$refs['UpdateMemberModal'].hide()
			} else {
				if (this.member && (this.member.status == 'pending')) {
					await UserService.updateInvitedpermission(this.mid, this.currentPermissions);
				} else {
					await UserService.updateMemberpermission(this.teamId, this.mid, this.currentPermissions);
					window.location.reload();
				}
				this.dismiss();
				this.$notify({ group: 'success', text: `${this.member?.name}'s permissions were successfully updated` });
				this.$emit('update-permissions', this.currentPermissions)
			}
		},
		changeRole(newrole) {
			if (newrole == 'admin') {
				this.currentPermissions.role = 'admin';
				this.showPermission = false;
			} else {
				this.showPermission = true;
				this.currentPermissions.role = 'moderator';
			}
			this.allowSubmit = true;
		},
		parentboxClick(value, index) {
			const setPermissions = this.permissions[index].parameters.map(p => {
				p.value = value;
			});
			this.checkCurrentPermission();
		},
		checkCurrentPermission() {
			const checkAccess = this.permissions.filter(p => {
				const pms = p.parameters.filter(pm => {
					return (pm.value === true);
				});
				return (pms.length > 0);
			});
			this.allowSubmit = (checkAccess.length > 0);
		},
		async getMemberpermission() {
			if (this.member) {
				let mid = this.member._id;
				let mpermission = null;
				if (this.member && (this.member.status == 'pending')) {
					mid = this.member.id;
					const ipermission = await UserService.getInvitedpermission(mid);
					mpermission = ipermission.permissions;
					mpermission.role = ipermission.role;
					mpermission.id = ipermission.id;
					mpermission.team = ipermission.team;
				} else {
					mpermission = await UserService.getMemberpermission(this.teamId, mid);
				}
				this.mid = mid;
				this.currentPermissions = mpermission;
				this.role = mpermission.role;
				if (this.role == 'moderator') {
					this.allowSubmit = false;
					this.showPermission = true;
				} else {
					this.showPermission = false;
				}
				
				for (const [permission, value] of Object.entries(mpermission)) {
					this.permissions.map(setUserPermissions)
					function setUserPermissions(item) {
						if (item.sections) {
							item.sections.map(setSectionPermissions)
							function setSectionPermissions(sectionItem) {
								const sectionOption = sectionItem.items.find(option => option.name === permission)
								if (sectionOption) {
									sectionItem.items.find(option => option.name === permission).value = value
								}
							}
						} else {
							const option = item.parrentOptions.find(option => option.name === permission)
							if (option) {
								item.parrentOptions.find(option => option.name === permission).value = value
							}
						}
					}
 				}
			}
		},
		togglePermission(permission, index) {			
			if(permission.name) {
				let currentPermissions = this.currentPermissions.hasOwnProperty(permission.name)
				if (currentPermissions) {
					this.currentPermissions[permission.name] = !this.currentPermissions[permission.name]
				}
				if(permission.name === 'subAccountInfoCreate') {
					this.currentPermissions.subAccountInfoView = !permission.value
					this.permissions[7].sections[0].items[0].value = !permission.value
				}
			} else {
				let chosenPermission = this.permissions.find(item => 
					item.label === permission.label
				)
				// for (const [permissionName, value] of Object.entries(chosenPermission.sections)) {
				// 	if(!value.items[index].hidden) {
				// 		value.items[index].value = !permission.value
				// 		this.currentPermissions[value.items[index].name] = !permission.value
				// 	}
				// }
				if(permission.label === 'Sub-accounts' && index === 1) {
					this.currentPermissions.subAccountInfoView = !this.currentPermissions.subAccountInfoView
				}
			}
			this.allowSubmit = true
		},
		indeterminate(permissionList, permission, index) {
			const hasSections = permissionList.sections
			if (hasSections) {
				const section1 = permissionList.sections[0].items[index]
				const section2 = permissionList.sections[1].items[index]
				if (!section1.hidden && !section2.hidden && section1.value != section2.value) {
					return true
				} else return false
			} else return false
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
hr {
	border-top-color: var(--c-dark-4);
}
@media (max-width: 991px) {
	.sticky-header {
		background-color: var(--c-dark-2);
		position: sticky;
		top: 0;
		z-index: 1;
	}
}
@media (max-width: 767px) {
	.label-v {
    display: flex;
		align-items: flex-end;
  }
}
</style>
