<template>
	<div class="view-wrapper">
		<Spinner v-if="loadingStatus" text="Retrieving data..." classes="text-dark-8 mt-5" spinner-color="var(--c-dark-8)"
			spinner-size="15px" />
		<div v-else>
			<div v-if="exportingDvrArchivesPaginated.length > 0" class="video-list mb-3">
				<div class="video-item-header">
					<div class="flex flex-wrap">
						<div class="md:w-10/12">
							<h5 class="text-s-xl">
								EXPORTING FILES IN PROGRESS
							</h5>
							<p class="text-tiny text-dark-7">
								After exporting is done, you’ll see your VOD in VOD section below and in <a href="/app/vods">Video
									Hosting</a>.
							</p>
						</div>
					</div>
					<header class="text-tiny text-surface-6 font-medium flex flex-wrap py-2 border-b border-surface-2">
						<h4 class="w-4/12 text-dark-6 text-500">
							Recorded Clips
						</h4>
						<h4 class="w-2/12 text-dark-6 text-500">
							Duration
						</h4>
						<h4 class="w-3/12 text-dark-6 text-500">
							Recorded at
						</h4>
					</header>
				</div>
				<div v-for="video in this.exportingDvrArchivesPaginated" :key="`${video.from}-${video.duration}`"
					class="px-md-3">
					<dvr-video-list-item :stream="stream" :video="video" @request-dvr-archive-export="exportDvrArchive" />
				</div>
				<b-pagination v-if="exportingDvrArchivesPaginated.length" v-model="exportingDvrAPageIndex"
					:total-rows="exportingDvrArchives.length" :per-page="10" aria-controls="my-table"
					page-class="li-page bg-dark-2 p-1" ellipsis-class="li-page bg-dark-2 p-1" first-class="li-first p-1"
					prev-class="li-prev p-1" next-class="li-next p-1" last-class="li-last p-1" class="mt-3"></b-pagination>
			</div>

			<div class="video-list mb-3">
				<div class="flex flex-wrap">
					<div class="flex justify-between items-center w-full flex-wrap md:flex-nowrap md:flex-wrap mb-3">
						<h3 class="text-xl leading-none">
							Cloud Recording
						</h3>
						<ejs-tooltip  class="ml-2 mr-auto" v-if="(this.mediaPulse && this.mediaPulse.alive) && streamDvrEnabled"
							content="You cannot toggle this while stream is live.">
							<FormSwitch :modelValue="this.mediaPulse && this.mediaPulse.alive" disabled />
						</ejs-tooltip>
						<FormSwitch class="ml-2 mr-auto" v-else label-position="left" label-styles="text-sm text-surface-9"
							@change="toggleStreamDvrArchive"
							:disabled="(this.mediaPulse && this.mediaPulse.alive) && streamDvrEnabled" v-model="streamDvrEnabled" />
							<div class="mt-[1rem] md:m-0 py-2 px-3 text-tiny shrink flex rounded-md bg-surface-2 text-surface-8">
								<icon-base class="mr-2 shrink-0" fill="none">
									<icon-info-small />
								</icon-base>
								<p class="mb-0">
									To ensure stable recording, follow our <a target="_blank" class="text-reset text-underline"
										href="https://docs.castr.com/en/articles/4825093-recommended-settings-for-encoders">recommended
										encoder settings</a>.</p>
							</div>
					</div>
					<p v-if="streamDvrEnabled" class="text-sm text-surface-8 mb-3">
						All your streams are recorded and kept here for 3 days. You can download or convert your recorded streams
						to VOD (video on demand) and share with your users easily. All saved records are located in <a
							href="/app/vods">Video Hosting</a>.
					</p>
					<p v-else class="text-s-s text-dark-8 mb-3">
						Auto-recording is disabled. Your streams will not be recorded.
					</p>
				</div>
				<header v-if="tempDvrArchivesPaginated.length > 0" class="text-tiny text-surface-6 font-medium flex flex-wrap py-2 border-b border-surface-2">
					<h4 class="w-4/12">
						Recorded Clips
					</h4>
					<h4 class="w-3/12 md:w-2/12">
						Duration
					</h4>
					<h4 class="w-3/12 md:w-2/12">
						Recorded at
					</h4>
				</header>
				<div v-for="video in tempDvrArchivesPaginated" :key="`${video.from}-${video.duration}`" class="border-b border-surface-2">
					<dvr-video-list-item :stream="stream" :video="video" class="video-item_tempo"
						@request-dvr-archive-delete="requestDvrArchiveDelete" @reqeust-video-preview="requestVideoPreview"
						@request-dvr-archive-export="exportDvrArchive" />
				</div>
				<b-pagination v-if="tempDvrArchivesPaginated.length" v-model="tempDvrPageIndex"
					:total-rows="tempDvrArchives.length" :per-page="10" aria-controls="my-table"
					page-class="li-page bg-dark-2 p-1" ellipsis-class="li-page bg-dark-2 p-1" first-class="li-first p-1"
					prev-class="li-prev p-1" next-class="li-next p-1" last-class="li-last p-1" class="mt-3"></b-pagination>
			</div>
			<div v-if="permanentDvrArchivesPaginated.length > 0" class="video-list">
				<div class="flex justify-between items-center w-full flex-nowrap md:flex-wrap mb-3">
						<h3 class="text-xl leading-none">
							VOD (Video On Demand)
						</h3>
					</div>
					<p class="text-sm text-surface-8 mb-3">
					 Access your files in <a href="/app/vods">Video Hosting</a> for complete settings. Deleting recorded clips here will also remove them from Video Hosting.
					
					</p>
					<header class="text-tiny text-surface-6 font-medium flex flex-wrap py-2 border-b border-surface-2">
						<h4 class="w-4/12">
							Recorded Clips
						</h4>
						<h4 class="w-3/12 md:w-2/12">
							Duration
						</h4>
						<h4 class="w-3/12 md:w-2/12">
							Recorded at
						</h4>
					</header>
				<div v-for="video in permanentDvrArchivesPaginated" :key="`${video.from}-${video.duration}`" class="">
					<dvr-video-list-item :stream="stream" :video="video" @request-dvr-archive-delete="requestDvrArchiveDelete" />
				</div>
				<b-pagination v-if="permanentDvrArchivesPaginated.length" v-model="permanentDvrPaginationIndex"
					:total-rows="permanentDvrArchives.length" :per-page="10" aria-controls="my-table"
					page-class="li-page bg-dark-2 p-1" ellipsis-class="li-page bg-dark-2 p-1" first-class="li-first p-1"
					prev-class="li-prev p-1" next-class="li-next p-1" last-class="li-last p-1" class="mt-3"></b-pagination>
			</div>
		</div>

		<confirm-modal modal-id="modal-confirm-2" modal-type="danger" message="Do you want to delete this recorded file?"
			@modal-confirm="deleteDvrArchive" />

		<confirm-modal modal-id="modal-confirm-3"
			message="Are you sure you want to delete this file? You won't be able to download it anymore"
			@modal-confirm="deleteDvrArchive" />

		<alert-modal
			:message="exceedStorageErrorMsg || 'You are exceeding your VOD storage limit. Please upgrade to continue'"
			modal-id="alert-dvr-storage-exceeding" ok-text="Ok" />

		<dvr-archive-video-preview-modal v-if="selectedVideo" :stream="stream" :video="selectedVideo"
			@request-dvr-archive-export-custom-range="exportDvrArchive" />
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import prettyMilliseconds from 'pretty-ms';
import Spinner from "@/components/ui/Spinner.vue";
import StreamService from '@/services/StreamService';
import MetricsService from '@/services/MetricsService';
import VodVideoModal from '@/components/modals/VODVideoModal.vue';
import DvrArchiveVideoPreviewModal from '@/components/modals/DvrArchiveVideoPreviewModal.vue';
import AlertModal from '@/components/modals/AlertModal.vue';
import { mapGetters } from 'vuex';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import DvrVideoListItem from '@/components/ui/DVRVideoListItem.vue';
import { timeAgo, bytes } from '@/filtersNew'
import FormSwitch from '../../components/Atoms/FormSwitch.vue';
import IconInfoSmall from '@/components/icon/IconInfoSmall.vue';
import IconBase from '@/components/icon/IconBase.vue'
import { TooltipComponent } from "@syncfusion/ej2-vue-popups";

export default {
	name: 'StreamsManageClipEpisodes',
	components: {
		Spinner,
		VodVideoModal,
		DvrArchiveVideoPreviewModal,
		ConfirmModal,
		DvrVideoListItem,
		AlertModal,
		FormSwitch,
		IconBase,
		IconInfoSmall,
		"ejs-tooltip": TooltipComponent,
	},
	props: {
		stream: {
			type: Object,
			required: true,
			validator(v) {
				return Object.prototype.hasOwnProperty.call(v, '_id');
			},
		},
		mediaPulse: {
			type: Object,
			default() { return {}; }
		}
	},
	data() {
		return {
			processing: true,
			vodEpisodes: [],

			exportingDvrArchives: [],
			permanentDvrArchives: [],
			tempDvrArchives: [],

			selectedVideo: null,
			deletedVideo: null,

			mediaPulseTicks: 0,
			mediaPulseAliveTicks: 0,
			hasSeenNewFeature: false,

			usedVodStorage: 0,
			usedDvrStorage: 0,

			exceedStorageErrorMsg: null,
			storagePullTimer: null,

			streamDvrEnabled: null,
			permanentDvrPaginationIndex: 1,
			tempDvrPageIndex: 1,
			exportingDvrAPageIndex: 1,
		};
	},
	computed: {
		...mapGetters({
			loadingStatus: "Ui/loadingStatus",
			vodStreams: "Streams/vodStreams"
		}),
		isLive() {
			return _.find(this.tempDvrArchives, { live: true });
		},
		vodStorageLimitByte() {
			return parseFloat(this.$store.state.User.maxVodStorage) * (1000 ** 3)
		},
		permanentDvrArchivesPaginated() {
			return this.permanentDvrArchives.slice((this.permanentDvrPaginationIndex - 1) * 10, (this.permanentDvrPaginationIndex - 1) * 10 + 10)
		},
		tempDvrArchivesPaginated() {
			return this.tempDvrArchives.slice((this.tempDvrPageIndex - 1) * 10, (this.tempDvrPageIndex - 1) * 10 + 10)
		},

		exportingDvrArchivesPaginated() {
			return this.exportingDvrArchives.slice((this.exportingDvrAPageIndex - 1) * 10, (this.exportingDvrAPageIndex - 1) * 10 + 10)
		},
	},
	watch: {
		async mediaPulse() {
			this.mediaPulseTicks = this.mediaPulseTicks + 1;
			if (this.mediaPulse && this.mediaPulse.alive) {
				this.mediaPulseAliveTicks = this.mediaPulseAliveTicks + 1;
				if (!this.isLive && this.mediaPulseAliveTicks > 2) {
					await this.setup();
					this.mediaPulseTicks = 0;
					this.mediaPulseAliveTicks = 0;
					if (this.tempDvrArchives[0]) {
						this.tempDvrArchives[0].live = true;
						this.tempDvrArchives[0].blocking = false;
					}
				}

				const len = this.tempDvrArchives.length
				if (len > 1) {
					this.sortTempDvrArchives();
				}
			}

			const description = this.mediaPulse.description || ''
			if (description.indexOf('stream not foun') > -1) {
				if (this.mediaPulseTicks > 0 && this.tempDvrArchives[0]) {
					this.tempDvrArchives[0].blocking = false;
				}

				if (this.isLive) {
					this.mediaPulseTicks = 0;
					this.mediaPulseAliveTicks = 0;
					await this.setup();
				}
			}
		}
	},
	async mounted() {
		FormSwitch.compatConfig = { MODE: 3 }
		this.$store.dispatch('Ui/setLoadingStatus', true);
		this.hasSeenNewFeature = (localStorage.getItem('clips-feature', null, Number) === 1);
		this.streamDvrEnabled = this.stream.metadata.dvrArchive;

		await this.setup();
		await this.initDvrStorageUsage();
		await this.initVodStorageUsage();


		this.storagePullTimer = setInterval(() => {
			this.initDvrStorageUsage();
			this.initVodStorageUsage();
		}, 5000);

		_.forEach(this.exportingDvrArchives, (video) => {
			this.startStatusTimer(video);
		});
	},
	methods: {
		async toggleStreamDvrArchive() {
			try {
				await StreamService.saveStreamMetadata(this.stream._id, 'dvrArchive', this.streamDvrEnabled);
				this.$emit('dvr-recording-toggle', this.streamDvrEnabled);
				this.$notify({ group: 'success', text: 'Setting saved' })
			} catch (e) {
				this.$notify({ group: 'error', text: 'Stream DVR toggle failed' });
			}
		},
		async initDvrStorageUsage() {
			let bytes = 0;
			try {
				const resp = await MetricsService.getUserDvrStorage();
				bytes = resp.bytes || 0;
			} catch (e) {
				console.error('initDvrStorageUsage', e);
			}
			this.usedDvrStorage = bytes;
		},
		async initVodStorageUsage() {
			let bytes = 0;
			try {
				const streams = this.vodStreams;
				streams.forEach((item) => {
					// count only vod stremas without pseudo vod streams(initDvrStorageUsage) 
					if (item.type === 'vod' && !item.liveParent) {
						bytes += item.totalBytes;
					}
				});
			} catch (e) {
				console.error('initVodStorageUsage', e);
			}
			this.usedVodStorage = bytes;
		},
		async setup() {
			// this.$store.dispatch('Ui/setLoadingStatus', true);
			// this.processing = true;

			try {
				this.vodEpisodes = await StreamService.getStreamDvrArchives(
					this.stream._id
				);
			} catch (e) {
				this.$notify({
					group: 'error',
					text: 'could not retreive saved vod(s)'
				});
			}
			this.vodEpisodes = _.map(this.vodEpisodes, vodEpisode => {
				const ep = _.assign({}, vodEpisode, {
					startTimeDate: new Date(vodEpisode.startTime),
					endTimeDate: new Date(vodEpisode.endTime)
				});

				ep.fileName = `${timeAgo(
					ep.startTimeDate
				)}`;
				ep.fileName = ep.fileName.replace('a few seconds ago', 'a seconds ago');
				ep.creationTime = ep.startTimeDate;

				// ep.embedUrl = this.getEpisodeEmbedUrl(ep);
				// ep.iframeSnippet = this.getEpisodeIframeSnippet(ep);

				ep.live = false;
				ep.expanded = false;
				ep.streamType = this.stream.type;
				ep.blocking = false;
				ep.sizeRaw = ep.size
				ep.size = ep.size ? bytes(ep.size, false, 2) : '--';

				ep.exportProcessing = false;
				ep.exportStatusTimer = null;

				return ep;
			});

			this.permanentDvrArchives = _.filter(this.vodEpisodes, { exportStatus: 'EXPORTED' }) || []
			this.tempDvrArchives = _.filter(this.vodEpisodes, (el) => {
				return el.exportStatus === 'NOT_EXPORTED' && (Math.round(new Date().getTime() / 1000) - el.from) < 86400 * 4;
			}) || []
			this.exportingDvrArchives = _.filter(this.vodEpisodes, (i) => { return i.exportStatus === 'EXPORTING' || i.exportStatus === 'FAILED' }) || []

			this.sortTempDvrArchives();
			this.sortPermanentDvrArchives();

			if (this.tempDvrArchives.length > 0 && this.stream.enabled) {
				console.log('blocking= true 1st elem')
				this.tempDvrArchives[0].blocking = true;
			}
			// this.processing = false;
			this.$store.dispatch('Ui/setLoadingStatus', false);
		},
		async exportDvrArchive(video) {
			const currentUsage = this.usedDvrStorage + this.usedVodStorage;
			if (currentUsage > this.vodStorageLimitByte) {
				this.exceedStorageErrorMsg = `Your VOD storage capacity cannot exceed ${(this.vodStorageLimitByte / 1000 ** 3)}GB. Upgrade your plan to continue`;
				this.$root.$emit('bv::show::modal', 'alert-dvr-storage-exceeding');
				return;
			}

			const again = video.again;
			video.exportProcessing = true;

			let name = `${moment(video.creationTime).format('DD/MM/YYYY')}_full`

			try {
				const archiveObj = {
					streamId: this.stream._id, from: video.from,
					duration: video.duration, server: video.server,
					abr: video.abr, namedHls: video.namedHls
				};
				if (video.customRange) {
					archiveObj.from = video.customRange.from;
					archiveObj.duration = video.customRange.duration;

					const dur = prettyMilliseconds(video.customRange.duration * 1000).replace(' ', '_');
					name = `${moment(video.creationTime).format('DD/MM/YYYY')}_range_${dur}`
				}

				if (video.filterTrack) {
					archiveObj.filterTrack = video.filterTrack
				}

				archiveObj.name = name;
				await StreamService.exportStreamDvrArchive(archiveObj);
			} catch (err) {
				console.log('exportDvrArchive', err);
				if (!err.toString().includes('timeout of')) {
					const errText = _.get(err, 'response.data.message')
					this.$notify({
						group: 'error',
						text: errText || 'Couldn\'t start exporting process. try again later'
					});
					video.exportProcessing = false;
					return
				}
			}

			let newVideo = video
			if (!again) {
				newVideo = _.cloneDeep(video);
				newVideo.name = name;
				if (video.customRange) {
					newVideo.from = video.customRange.from;
					newVideo.duration = video.customRange.duration;
					delete video.customRange;
				}
				this.moveExporting(newVideo)
			}

			newVideo.exportStatus = 'EXPORTING';
			this.startStatusTimer(newVideo);
			video.exportProcessing = false;
		},
		startStatusTimer(video) {
			video.exportStatusTimer = setInterval(async () => {
				const q = {
					streamId: this.stream._id, from: video.from,
					duration: video.duration, abr: video.abr, namedHls: video.namedHls
				};
				if (video.customRange) {
					q.from = video.customRange.from;
					q.duration = video.customRange.duration;
				}
				try {
					const resp = await StreamService.getStreamDvrExportStatus(q);
					if (resp.success) {
						clearInterval(video.exportStatusTimer);

						video.exportStatus = 'EXPORTED';
						video.sizeRaw = resp.size
						video.size = bytes(resp.size, false, 2)

						this.moveExported(video)
						return
					}

					if (!resp.success && resp.reason === 'not found') {
						console.log('not found');
					}

					if (!resp.success && resp.reason === 'export failed') {
						video.exportStatus = 'FAILED';
						clearInterval(video.exportStatusTimer);
					}
				} catch (err) {
					console.log('getStreamDvrExportStatus', err);
				}
			}, 3000)
		},
		requestDvrArchiveDelete(video) {
			this.deletedVideo = video;
			if (video.exportStatus === 'NOT_EXPORTED') {
				this.$root.$emit('bv::show::modal', 'modal-confirm-3');
				return
			}

			this.$root.$emit('bv::show::modal', 'modal-confirm-2');
			return
		},
		async deleteDvrArchive() {
			const video = this.deletedVideo;

			if (video.exportStatus === 'NOT_EXPORTED') {
				this.deleteTempDvrArchive(video);
				return
			}

			const q = {
				streamId: this.stream._id,
				from: video.from,
				duration: video.duration,
				abr: video.abr,
				namedHls: video.namedHls
			};
			try {
				await StreamService.deleteStreamDvrArchive(q);
				_.remove(this.permanentDvrArchives, (item) => {
					return item.duration === video.duration && item.from === video.from
				});
				this.sortPermanentDvrArchives();

				console.log(`delete archive range=${video.from}-${video.duration}`, video);
			} catch (err) {
				this.$notify({
					group: 'error',
					text: 'Couldn\'t process delete request. try again later'
				});
			}
		},
		async deleteTempDvrArchive(video) {
			console.log('deleteTempDvrArchive', video)
			const q = {
				streamId: this.stream._id,
				from: video.from,
				duration: video.duration,
				abr: video.abr,
				storageServer: video.server,
				namedHls: video.namedHls
			};
			try {
				await StreamService.deleteStreamTempDvrArchive(q);
				_.remove(this.tempDvrArchives, (item) => {
					return item.duration === video.duration && item.from === video.from
				});
				this.sortTempDvrArchives();
				console.log(`delete tmp dvr archive range=${video.from}-${video.duration}`, video);
			} catch (err) {
				this.$notify({
					group: 'error',
					text: 'Couldn\'t process delete request. try again later'
				});
			}
		},
		sortPermanentDvrArchives() {
			this.permanentDvrArchives.sort(function compare(a, b) {
				var dateA = new Date(a.from);
				var dateB = new Date(b.from);
				return dateB - dateA;
			});
		},
		sortTempDvrArchives() {
			this.tempDvrArchives.sort(function compare(a, b) {
				var dateA = new Date(a.from);
				var dateB = new Date(b.from);
				return dateB - dateA;
			});
		},
		async requestVideoPreview(video) {
			this.selectedVideo = video;
			setTimeout(() => {
				this.$root.$emit('bv::show::modal', 'modal-dvr-archive-video-preview');
			}, 100);
		},
		markSeenNewFeature() {
			localStorage.setItem('clips-feature', 1);
			this.hasSeenNewFeature = true;
		},
		clipboardCopy(text) {
			try {
				if (text instanceof Function) text = text();

				this.$copyText(text);
				this.$notify({ group: 'info', text: 'Copied to clipboard' });
			} catch (e) { }
		},
		async moveExporting(video) {
			this.exportingDvrArchives.push(video)
			return
		},
		async moveExported(video) {
			_.remove(this.exportingDvrArchives, (item) =>
				item.duration === video.duration && item.from === video.from
			);
			this.permanentDvrArchives.push(video);
			this.sortPermanentDvrArchives();
			return
		},
		beforeDestroy() {
			clearInterval(this.storagePullTimer);
			_.forEach(this.vodEpisodes, (video) => {
				console.log(`beforeDestroy range=${video.from}-${video.duration}`, this.video);
				clearInterval(video.exportStatusTimer);
			});
		}
	}
};
</script>
<style scoped>


:deep(.video-item_tempo .collapse),
:deep(.video-item_tempo .collapsing) {
	display: none !important;
}

</style>
