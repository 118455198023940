import _ from 'lodash';
import RequestError from './RequestError';
import amProxyUrl from './AmProxyUrl';
import api from '@/api';

const streamPreviewThumbUrlHash = {};

/**
 * @typedef RequestConfig
 * @prop {string} path
 * @prop {string} method
 * @prop {Object} [params]
 * @prop {any} [data]
 */

export default {
	getAccessTokenByServiceCred,
	getUserStreams,
	getStream,
	getSubAccountStreams,
	getPlatformUsage,
	getStreamPreviewThumbUrl,
	getStreamPreviewVideoUrl,
	getStreamWebcamPublishConfig,
	getStreamDvrRanges,
	getStreamDvrEpisodes,
	getStreamRecordedFiles,
	deleteStreamRecordedFile,
	getStreamRecordingEmbedUrl,
	getStreamViewership,
	getSubSecondStreamViewers,
	addStream,
	addLiveStream,
	addCamStream,
	addScheduledStream,
	addVODChannel,
	setStreamName,

	// password toggle
	updatePasswordToggle,
	resetStreamPassword,
	
	toggleStream,
	enableStreamDvr,
	disableStreamDvr,
	setStreamPullUrl,
	unsetStreamPullUrl,
	deleteStream,
	addStreamPlatform,
	toggleStreamPlatform,
	setStreamPlatformName,
	setStreamPlatformAddress,
	deleteStreamPlatform,
	getAvailableRegions,
	getStreamMetadata,
	saveStreamMetadata,
	clearStreamMetadataCache,
	enableStreamABR,
	disableStreamABR,
	uploadStreamPoster,
	saveStreamWebhookEndpoints,
	deleteStreamWebhookEndpoints,

	getStreamScheduleSettings,
	saveStreamScheduleSettings,

	requestVodMediaUploadUrl,
	requestVodMediaReplaceUploadUrl,
	uploadVodMediaToBucket,
	updateVodMediaUploadState,
	onCompleteVodReplacement,
	deleteVodMedia,

	uploadStreamPlaylistVideo,
	deleteStreamPlaylistVideoFile,
	getStreamPlaylist,
	updateStreamPlaylistVideoFilename,
	saveStreamPlaylistVideo,
	moveStreamPlaylistVideo,
	updateOrderPlaylistVideo,
	togleStreamPlaylistVideoStatus,
	removeStreamPlaylistVideo,

	getStreamHlsUrl,

	getStreamRecordedClips,

	getStreamDvrArchives,
	getStreamDvrExportStatus,
	deleteStreamDvrArchive,
	exportStreamDvrArchive,
	setStreamDvrArchiveName,
	deleteStreamTempDvrArchive,

	toggleStreamFailoverIngest,
	toggleStreamLowLatencyStreaming,
	switchStreamRegion,

	// vod tags
	getAllTags,
	createNewTags,
	deleteTagById,
	updateTagById,
	updateTagsOfVideoFile,
	updateTagsOfArchivedVideoFile,

	searchForVideoFiles,

	// alerts
	setStreamNotification,
	getStreamNotifications,
	removeStreamNotification,
	
	getSubAccountStreams,

	// chapters
	getVideoChapters,
	addVideoChapters,
	deleteVideoChapter,
	updateVideoChaper,

	getSubAccounts,

	uploadVttFile,
	checkFileExists,
	generateVttAi,
	deleteCaptionFile,
	updateStreamPlaylistVideoMetaData,
	getStreamPlaylistVideoMetaData,
	clearVodMetadata,

	// clips
	getVodClips,
	addVodClip,
	deleteVodClip,
	setVodClipName
};

function setVodClipName(streamId, clipId, name) {
	return makeRequest({
		path: `/streams/${streamId}/clips/${clipId}`,
		method: 'put',
		data: { name }
	});
}

function getVodClips(streamId, videoId) {
	return makeRequest(`/streams/${streamId}/clips/${videoId}`);
}

function addVodClip(streamId, videoId, data) {
	return makeRequest({
		path: `/streams/${streamId}/clips/${videoId}`,
		method: 'post',
		data: data
	});
}

function deleteVodClip(streamId, videoId, clipId) {
	return makeRequest({
		method: 'delete',
		path: `/streams/${streamId}/clips/${videoId}/${clipId}`
	});
}

function updateVideoChaper(streamId, chapterId, { chapter, startSec, endSec }) {
	return makeRequest({
		path: `/streams/${streamId}/chapter/${chapterId}`,
		method: 'put',
		data: { chapter, startSec, endSec }
	});
}

function deleteVideoChapter(streamId, videoId, chapterId) {
	return makeRequest({
		method: 'delete',
		path: `/streams/${streamId}/chapter/${videoId}/${chapterId}`
	});
}

function addVideoChapters(streamId, videoId, data) {
	return makeRequest({
		path: `/streams/${streamId}/chapters/${videoId}`,
		method: 'post',
		data: data
	});
}

 function getVideoChapters(streamId, videoId) {
	return makeRequest(`/streams/${streamId}/chapter/${videoId}`);
}

function removeStreamNotification(streamId, contactId) {
	const q = {
		path: `/streams/${streamId}/notification`,
		method: 'delete',
		params: {
			contactId
		}
	}
	return makeRequest(q);
 }

 function getStreamNotifications(streamId) {
	return makeRequest(`/streams/${streamId}/notification`);
}

function setStreamNotification(streamId, phone) {
	return makeRequest({
		path: `/streams/${streamId}/notification`,
		method: 'post',
		data: {
			phone
		}
	});
}

/**
 * @param {string} linkedServiceCreds
 */
 function getAccessTokenByServiceCred(linkedServiceCreds) {
	return makeRequest(`/integrations/${linkedServiceCreds}/linkedOAuthCreds`);
}

/**
 * @param {string} streamId
 * @param {string} newRegion
 */
function switchStreamRegion(streamId, newRegion, transcoder = false) {
	let path = `/streams/${streamId}/switchRegion`
	if (transcoder) {
		path = path + '?transcoder=true'
	}
	return makeRequest({
		path: path,
		method: 'put',
		data: { regionId: newRegion }
	});
}

/**
 * @param {string} streamId
 * @param {boolean} enabled
 */
function toggleStreamFailoverIngest(streamId, enabled) {
	return makeRequest({
		path: `/streams/${streamId}/failoverIngest/toggle`,
		method: 'put',
		data: { enabled: enabled }
	});
}

/**
 * @param {string} streamId
 * @param {boolean} lowLatencyPlayback
 */
 function toggleStreamLowLatencyStreaming(streamId, lowLatencyPlayback) {
	return makeRequest({
		path: `/streams/${streamId}/low_latency_playback/toggle`,
		method: 'put',
		data: { lowLatencyPlayback }
	});
}

/**
* @param {string} streamId
* @param {number} from
* @param {number} duration
* @param {string} name
*/
function setStreamDvrArchiveName({ streamId, from, duration, name }) {
	return makeRequest({
		path: `/streams/${streamId}/dvrArchiveName`,
		method: 'put',
		data: {
			from,
			duration,
			name
		}
	});
 }

/**
* @param {string} streamId
*/
function getStreamDvrArchives(streamId) {
	return makeRequest(`/streams/${streamId}/dvrArchives`);
}

/**
* @param {string} streamId
* @param {number} from
* @param {number} duration
* @param {string} server
* @param {boolean} abr
*/
function exportStreamDvrArchive({ streamId, from, duration, server, abr, name, namedHls, filterTrack }) {
   return makeRequest({
	   path: `/streams/${streamId}/exportDvrArchive`,
	   method: 'put',
	   timeout: 5000 * 1,	// 5 sec timeout, consider timeout like legit case
	   data: {
		   from,
		   duration,
		   server,
		   abr,
		   name,
		   namedHls,
			 filterTrack
	   }
   }, true, true);
}

/**
* @param {string} streamId
* @param {number} from
* @param {number} duration
* @param {boolean} abr
*/
function getStreamDvrExportStatus({ streamId, from, duration, abr, namedHls }) {
	const q = {
		path: `/streams/${streamId}/dvrArchiveExportStatus`,
		params: {
			from,
			duration,
			abr,
			namedHls
		}
	}
	return makeRequest(q);
 }

 async function getSubAccountStreams() {
	const config = {
		path: '/streams/sub_accounts'
	}
	let response = await makeRequest(config)
	return response
}

 /**
* @param {string} streamId
* @param {number} from
* @param {number} duration
* @param {boolean} abr
*/
function deleteStreamDvrArchive({ streamId, from, duration, abr, namedHls }) {
	const q = {
		path: `/streams/${streamId}/dvrArchive`,
		method: 'delete',
		params: {
			from,
			duration,
			abr,
			namedHls
		}
	}
	return makeRequest(q);
 }

/**
* @param {string} streamId
* @param {number} from
* @param {number} duration
* @param {boolean} abr
*/
function deleteStreamTempDvrArchive({ streamId, from, duration, storageServer, abr, namedHls }) {
	const q = {
		path: `/streams/${streamId}/tempDvrArchive`,
		method: 'delete',
		params: {
			from,
			duration,
			abr,
			storageServer,
			namedHls
		}
	}
	return makeRequest(q);
 }

function getUserStreams() {
	return makeRequest('/streams/mystreams');
}

/**
 * @param {string} streamId
 */
function getStream(streamId) {
	return makeRequest(`/streams/${streamId}`);
}

function getPlatformUsage(type) {
	return makeRequest(`/streams/${type}/platform_usage`);
}


/**
 * @param {string} streamId
 */
async function getStreamPreviewThumbUrl(streamId) {
	const uri = `/streams/${streamId}/preview/thumbUrl`;
	let thumbUrl = streamPreviewThumbUrlHash[streamId];
	if (thumbUrl) {
		return thumbUrl;
	}

	thumbUrl = await makeRequest(uri);
	if (thumbUrl) {
		streamPreviewThumbUrlHash[streamId] = thumbUrl;
	}

	return thumbUrl;
}

/**
 * @param {string} streamId
 */
function getStreamPreviewVideoUrl(streamId) {
	return null;
}

/**
 * @param {string} streamId
 */
function getStreamWebcamPublishConfig(streamId) {
	const uri = `/streams/${streamId}/webcam/publishUrl`;
	return makeRequest(uri);
}

/**
 * @param {string} streamId
 * @param {number} startTime
 * @param {number} endTime
 */
function getStreamDvrRanges(streamId, startTime, endTime) {
	let uri = `/streams/${streamId}/dvrRanges`;
	uri += `?start=${startTime}`;
	uri += `&end=${endTime}`;

	return makeRequest(uri);
}

/**
 * @param {string} streamId
 */
function getStreamDvrEpisodes(streamId) {
	return makeRequest(`/streams/${streamId}/dvrEpisodes`);
}

/**
 * @param {string} streamId
 */
function getStreamRecordedFiles(streamId) {
	return makeRequest(`/streams/${streamId}/records`);
}

function getStreamRecordedClips(streamId) {
	return makeRequest(`/streams/${streamId}/clipRecords`);
}

/**
 * @param {string} streamId
 * @param {string} episodeId
 */
function deleteStreamRecordedFile(streamId, episodeId) {
	return makeRequest({
		method: 'delete',
		path: `/streams/${streamId}/recaps/${episodeId}`
	});
}

/**
 * @param {string} streamId
 */
async function getStreamRecordingEmbedUrl(streamId) {
	const res = await makeRequest(`/streams/${streamId}/recordingEmbed`);
	return res ? res.url : null;
}

/**
 * @param {string} streamId
 */
function getStreamViewership(streamKey) {
	const uri = `/streams/${streamKey}/viewers`;
	return makeRequest(uri);
}

/**
 * @param {string} streamId
 */
function getSubSecondStreamViewers(streamKey) {
	const uri = `/streams/online_clients/${streamKey}?dashboard=1`;
	return makeRequest(uri);
}

/**
 * @param {string} name
 * @param {string} regionId
 * @param {string} [streamType]
 */
function addStream(name, regionId, streamType) {
	return makeRequest({
		path: '/streams/deploy',
		method: 'post',
		data: {
			stream: {
				name,
				region: regionId,
				type: streamType || 'restream'
			}
		}
	});
}

/**
 * @param {string} name
 * @param {string} regionId
 */
function addLiveStream(name, regionId) {
	return makeRequest({
		path: '/streams/deploy',
		method: 'post',
		data: {
			stream: {
				name,
				region: regionId,
				type: 'live'
			}
		}
	});
}

/**
 * @param {string} name
 * @param {string} regionId
 */
function addCamStream(name, regionId) {
	return makeRequest({
		path: '/streams/deploy',
		method: 'post',
		data: {
			stream: {
				name,
				region: regionId,
				type: 'ipcam'
			}
		}
	});
}

/**
 * @param {string} name
 * @param {string} regionId
 */
function addScheduledStream(name, regionId) {
	return makeRequest({
		path: '/streams/deploy',
		method: 'post',
		data: {
			stream: {
				name,
				region: regionId,
				type: 'scheduled'
			}
		}
	});
}

/**
 * @param {string} name
 * @param {string} regionId
 */
function addVODChannel(name, regionId) {
	return makeRequest({
		path: '/streams/deploy',
		method: 'post',
		data: {
			stream: {
				name,
				region: regionId,
				type: 'vod'
			}
		}
	});
}

/**
 * @param {string} streamId
 * @param {string} name
 */
function setStreamName(streamId, name) {
	return makeRequest({
		path: `/streams/${streamId}/name`,
		method: 'put',
		data: {
			name
		}
	});
}

/**
 * @param {string} streamId
 * @param {boolean} state
 */
function updatePasswordToggle(streamId, isPasswordDisabled) {
	return makeRequest({
		path: `/streams/${streamId}/togglePassword`,
		method: 'put',
		data: {
			isPasswordDisabled
		}
	});
}

/**
 * @param {string} streamId
 */
function resetStreamPassword(streamId) {
	return makeRequest({
		path: `/streams/${streamId}/resetPassword`,
		method: 'put'
	});
}

/**
 * @param {string} streamId
 */
function toggleStream(streamId) {
	return makeRequest({ path: `/streams/${streamId}/toggle`, method: 'put' });
}

/**
 * @param {string} streamId
 */
function enableStreamDvr(streamId) {
	return makeRequest({ path: `/streams/${streamId}/enableDvr`, method: 'put' });
}

/**
 * @param {string} streamId
 */
function disableStreamDvr(streamId) {
	return makeRequest({ path: `/streams/${streamId}/disableDvr`, method: 'put' });
}

/**
 * @param {string} streamId
 * @param {string} pulUrl
 */
function setStreamPullUrl(streamId, pullUrl) {
	return makeRequest({
		path: `/streams/${streamId}/pullurl/set`,
		method: 'put',
		data: {
			url: pullUrl
		}
	});
}

/**
 * @param {string} streamId
 */
function unsetStreamPullUrl(streamId) {
	return makeRequest({ path: `/streams/${streamId}/pullurl/unset`, method: 'put' });
}

/**
 * @param {string} streamId
 */
function deleteStream(streamId) {
	return makeRequest({ path: `/streams/${streamId}`, method: 'delete' });
}

/**
 * @param {string} streamId
 * @param {object} platformPayload
 * @param {string} platformPayload.template
 * @param {string} platformPayload.server
 * @param {string} platformPayload.key
 * @param {boolean} platformPayload.enabled
 * @param {boolean} platformPayload.linkedServiceCreds
 * @param {boolean} platformPayload.castrStream
 * @param {string} platformPayload.castrName
 */
function addStreamPlatform(streamId, platformPayload) {
	const config = {
		path: `/streams/${streamId}/platforms/add`,
		method: 'post',
		data: {
			platform: {
				template: platformPayload.template,
				server: platformPayload.server,
				key: platformPayload.key,
				enabled: platformPayload.enabled,
				preChecking: platformPayload.preChecking,
				castrStream: platformPayload.castrStream,
				castrName: platformPayload.castrName,
				region: platformPayload.region,
				streamObject: platformPayload.streamObject,
				mode: platformPayload.mode,
				port: platformPayload.port,
				latency: platformPayload.latency,
				passphrase: platformPayload.passphrase
			}
		}
	};

	if (platformPayload.linkedServiceCreds) {
		config.data.platform.linkedServiceCreds = platformPayload.linkedServiceCreds;
	}

	if (platformPayload.serviceMeta) {
		config.data.platform.serviceMeta = platformPayload.serviceMeta;
	}

	return makeRequest(config);
}

/**
 * @param {string} streamId
 * @param {string} platformId
 * @param {boolean} [forceState]
 */
function toggleStreamPlatform(streamId, platformId, forceState) {
	const config = {
		path: `/streams/${streamId}/platforms/${platformId}/toggle`,
		method: 'put'
	};

	if (forceState !== undefined) {
		config.data = {
			enabled: forceState
		};
	}

	return makeRequest(config);
}

/**
 * @param {string} streamId
 * @param {string} platformId
 * @param {string} name
 */
function setStreamPlatformName(streamId, platformId, name) {
	return makeRequest({
		path: `/streams/${streamId}/platforms/${platformId}/name`,
		method: 'put',
		data: {
			name
		}
	});
}

/**
 * @param {string} streamId
 * @param {string} platformId
 * @param {object} addressUpdates
 * @param {string} addressUpdates.server
 * @param {string} addressUpdates.key
 */
function setStreamPlatformAddress(streamId, platformId, addressUpdates) {
	return makeRequest({
		path: `/streams/${streamId}/platforms/${platformId}/address`,
		method: 'put',
		data: {
			platform: {
				server: addressUpdates.server,
				key: addressUpdates.key || '',
				port: parseInt(addressUpdates.port),
				latency: parseInt(addressUpdates.latency),
				passphrase: addressUpdates.passphrase
			}
		}
	});
}

/**
 * @param {string} streamId
 * @param {string} platformId
 */
function deleteStreamPlatform(streamId, platformId) {
	return makeRequest({ path: `/streams/${streamId}/platforms/${platformId}`, method: 'delete' });
}

/**
 * @param {string} streamId
 */
function getStreamMetadata(streamId, videoId = false) {
	return makeRequest(`/streams/${streamId}/metadata${videoId ? '?videoId=' + videoId : ''}`);
}

function uploadVttFile(fdata, streamId, vodFileName) {
	return makeRequest({
		path: `/streams/${streamId}/transcribe/${vodFileName}/upload`,
		method: 'post',
		data:  fdata ,
	});
}

function checkFileExists(streamId, vodFileName) {
	return makeRequest({
		path: `/streams/${streamId}/transcribe/${vodFileName}/status`,
		method: 'get',
	});
}

function deleteCaptionFile(streamId, vodFileName) {
	return makeRequest({
		path: `/streams/${streamId}/transcribe/${vodFileName}`,
		method: 'delete',
	});
}

function generateVttAi(streamId, vodFileName) {
	return makeRequest({
		path: `/streams/${streamId}/transcribe/${vodFileName}/generate`,
		method: 'post',
	});
}

/**
 * @param {string} streamId
 * @param {string} key
 * @param {string} value
 */
function saveStreamMetadata(streamId, key, value, videoId = false) {
	return makeRequest({
		path: `/streams/${streamId}/metadata/save${videoId ? '?videoId=' + videoId : ''}`,
		method: 'put',
		data: {
			updates: {
				key,
				value
			}
		}
	});
}

function saveStreamWebhookEndpoints(streamId, data) {
	return makeRequest({
		path: `/streams/${streamId}/webhook_endpoints`,
		method: 'put',
		data
	});
}

function deleteStreamWebhookEndpoints(streamId, data) {
	return makeRequest({
		path: `/streams/${streamId}/webhook_endpoints`,
		method: 'delete',
		data
	});
}

/**
 * @param {string} streamId
 */
function clearStreamMetadataCache(streamId, streamKey) {
	return makeRequest({
		// path: `https://player.castr.io/cache/clear?stream=${streamId}`,
		path: `${process.env.VUE_APP_PLAYER_APP_BASE_URL}/cache/clear?stream=${streamId}${streamKey ? ('&streamKey=' + streamKey) : ''}`,
		method: 'get'
	});
}

/**
 * @param {string} streamId
 */
function enableStreamABR(streamId) {
	return makeRequest({
		path: `/streams/${streamId}/abr/toggle`,
		method: 'put',
		data: { enabled: true }
	});
}

/**
 * @param {string} streamId
 */
function disableStreamABR(streamId) {
	return makeRequest({
		path: `/streams/${streamId}/abr/toggle`,
		method: 'put',
		data: { enabled: false }
	});
}

/**
 * @param {string} streamId
 * @param {FormData} fdata
 */
function uploadStreamPoster(streamId, fdata, isWatermark, videoId = false) {
	return makeRequest({
		path: `/streams/${streamId}/metadata/poster?${isWatermark ? 'type=watermark' : ''}${videoId ? '&videoId=' + videoId : ''}`,
		method: 'post',
		data: fdata,
		headers: {
			'content-type': 'multipart/form-data'
		}
	});
}

/**
 * @param {string} streamId
 */
function getStreamScheduleSettings(streamId) {
	return makeRequest(`/streams/${streamId}/schedular/config`);
}

/**
 * @param {string} streamId
 * @param {string} mode
 * @param {number} [datetime]
 */
function saveStreamScheduleSettings(streamId, mode, datetime) {
	if (!_.isNumber(datetime)) {
		datetime = datetime.getTime();
	}

	return makeRequest({
		path: `/streams/${streamId}/schedular/config`,
		method: 'post',
		data: {
			updates: {
				mode,
				datetime
			}
		}
	});
}

/**
 * @param {string} streamKey
 * @param {object} uploadReqPayload
 */
function requestVodMediaUploadUrl(streamKey, uploadReqPayload) {
	const pathname = `/vod/${streamKey}/videos/new`;
	const payload = {
		// metadata: fileInfo
		upload: uploadReqPayload
	};
	return makeVODApiRequest({
		path: pathname,
		method: 'post',
		data: payload
	});
}

/**
 * @param {string} streamKey
 * @param {string} videoId
 * @param {object} uploadReqPayload
 */
function requestVodMediaReplaceUploadUrl(streamKey, videoId, uploadReqPayload) {
	const pathname = `/vod/${streamKey}/videos/${videoId}/replace`;
	const payload = {
		// metadata: fileInfo
		upload: uploadReqPayload
	};
	return makeVODApiRequest({
		path: pathname,
		method: 'post',
		data: payload
	});
}

/**
 * @param {string} uploadUrl
 * @param {File} file
 * @param {Function} onBytesUploaded
 * @param {any} cancelToken
 */
function uploadVodMediaToBucket(uploadUrl, file, onBytesUploaded, cancelToken) {
	const fdata = new FormData();
	fdata.append('file', file);

	return makeRequest({
		path: uploadUrl,
		method: 'put',
		data: file,
		headers: {
			'Content-Type': file.type
		},
		onUploadProgress(progressEvent) {
			const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
			if (onBytesUploaded) {
				onBytesUploaded(percentCompleted);
			}
		},
		cancelToken,
		timeout: 0
	}, true);
}

/**
 * @param {string} mediaId
 * @param {string} streamId
 * @param {string} streamKey
 * @param {object} uploadItem
 */
function updateVodMediaUploadState(mediaId, streamId, streamKey, uploadItem) {
	const pathname = `/vod/${streamKey}/videos/${mediaId}/save`;
	const payload = {
		streamId,
		mediaInfo: uploadItem.mediaInfo,
		fileInfo: {
			fileName: uploadItem.file.name,
			size: uploadItem.file.size,
			precedence: uploadItem.precedence
		},
		encodingPresets: uploadItem.encodingPresets
	};

	return makeVODApiRequest({
		path: pathname,
		method: 'post',
		data: payload
	});
}

/**
 * @param {string} mediaId
 * @param {string} streamId
 * @param {string} streamKey
 * @param {object} uploadItem
 */
function onCompleteVodReplacement(originalVideoId, mediaId, streamId, streamKey, uploadItem) {
	
	const pathname = `/vod/${streamKey}/videos/${originalVideoId}/${mediaId}/replace-complete`;
	const payload = {
		streamId,
		mediaInfo: uploadItem.mediaInfo,
		fileInfo: {
			fileName: uploadItem.file.name,
			size: uploadItem.file.size,
			precedence: uploadItem.precedence
		},
		encodingPresets: uploadItem.encodingPresets
	};

	return makeVODApiRequest({
		path: pathname,
		method: 'post',
		data: payload
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 */
function clearVodMetadata(streamId, videoId, currFileId) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/${videoId}/clear-meta/${currFileId}`,
		method: 'delete'
	});
}

/**
 * @param {string} mediaId
 * @param {object} streamObj
 */
function deleteVodMedia(mediaId, streamObj) {
	const streamKey = streamObj.key;
	const pathname = `/vod/${streamKey}/videos/${mediaId}`;
	return makeVODApiRequest({
		path: pathname,
		method: 'delete',
		data: {
			// streamId: streamObj._id,
			stream: streamObj
		}
	});
}

/**
 * @param {string} streamId
 * @param {File} file
 */
function uploadStreamPlaylistVideo(streamId, streamKey, file, onBytesUploaded, cancelToken) {
	const pathname = `/playlist/${streamKey}/videos/add`;
	const fdata = new FormData();
	fdata.append('file', file);
	fdata.append('streamId', streamId);

	return makeVODApiRequest({
		path: pathname,
		method: 'post',
		data: fdata,
		headers: {
			'content-type': 'multipart/form-data'
		},
		onUploadProgress(progressEvent) {
			// let percentCompleted = Math.round((progressEvent.loaded * 100) /
			// progressEvent.total)
			const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total;
			if (onBytesUploaded) {
				onBytesUploaded(percentCompleted);
			}
		},
		cancelToken,
		timeout: 0
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 */
function deleteStreamPlaylistVideoFile(streamId, videoId) {
	const pathname = `/playlist/${streamId}/videos/${videoId}`;
	return makeVODApiRequest({
		path: pathname,
		method: 'delete'
	});
}

/**
 * @param {string} streamId
 * @param {object} videoMeta
 */
function getStreamPlaylist(streamId) {
	return makeRequest(`/streams/${streamId}/schedular/playlist`);
}

/**
 * @param {string} streamId
 * @param {object} videoMeta
 */
function saveStreamPlaylistVideo(streamId, videoMeta) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist`,
		method: 'post',
		data: { videoMeta }
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 */
function moveStreamPlaylistVideo(streamId, videoId1, videoId2) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/shift`,
		method: 'put',
		data: { updates: [videoId1, videoId2] }
	});
}

/**
 * @param {string} streamId
 * @param {array} videos
 */
function updateOrderPlaylistVideo(streamId, videos) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/update`,
		method: 'put',
		data: { updates: videos }
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 * @param {boolean} enabled
 */
function togleStreamPlaylistVideoStatus(streamId, videoId, enabled) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/${videoId}`,
		method: 'put',
		data: { updates: { enabled } }
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 * @param {[key: string]: any} data
 */
function updateStreamPlaylistVideoMetaData(streamId, videoId, data) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/${videoId}`,
		method: 'put',
		data: { updates: data }
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 * @param {[key: string]: any} data
 */
function getStreamPlaylistVideoMetaData(videoId) {
	return makeRequest({
		path: `/streams/schedular/playlist/${videoId}`,
		method: 'get',
	});
}

/**
 * @param {string} streamId
 * @param {string} videoId
 * @param {boolean} fileName
 */
function updateStreamPlaylistVideoFilename(streamId, videoId, fileName) {
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/${videoId}`,
		method: 'put',
		data: { updates: { fileName } }
	});
}

/**
 * @param {string} streamId
 */
function removeStreamPlaylistVideo(streamId, videoId, idInPayload) {
	if (idInPayload) {
		return makeRequest({
			path: `/streams/${streamId}/schedular/playlist`,
			data: { videoId },
			method: 'delete'
		});
	}
	return makeRequest({
		path: `/streams/${streamId}/schedular/playlist/${videoId}`,
		method: 'delete'
	});
}

/**
 * @param {string} streamId
 */
function getStreamHlsUrl(streamId) {
	return makeRequest({
		path: `/streams/${streamId}/hlsurl`,
		method: 'get'
	});
}

// let regions
const regionCache = {};
/**
 * @param {string} [category]
 */
async function getAvailableRegions(category) {
	category = category || 'list';

	if (regionCache[category]) {
		return regionCache[category];
	}

	let regions = [];
	// if (!regions) {
	if (!regionCache[category]) {
		regions = await makeRequest('/regions/' + category);
	}

	regionCache[category] = regions;

	return regions;
}

// VoD TAGS

/**
 * Get all tags created by user
 */
 function getAllTags() {
	return makeRequest({
		method: 'get',
		path: `/vod-tags`
	})
}

/**
 * Create new tags in the backend
 * @param {[{name: string, color: string}]} tagData Tag data
 */
 function createNewTags(tagData) {
	return makeRequest({
		method: 'post',
		path: '/vod-tags',
		data: tagData
	})
}

/**
 * Create new tags in the backend
 * @param {string} tagId Tag ID
 */
 function deleteTagById(tagId) {
	return makeRequest({
		method: 'delete',
		path: `/vod-tags/${tagId}`,
	})
}

/**
 * Update tag by id in the backend
 * @param {string} tagId Tag ID
 * @param {[{name: string, color: string}]} newData Tag data
 */
 function updateTagById(tagId, newData) {
	return makeRequest({
		method: 'put',
		path: `/vod-tags/${tagId}`,
		data: newData
	})
}

/**
 * Update tags of a video file
 * @param {string} videoId 
 * @param {string|[string]} tagId 
 */
function updateTagsOfVideoFile(videoId, tagIds) {
	return makeRequest({
		method: 'put',
		path: `/vod-tags/file/${videoId}`,
		data: tagIds
	})
}

/**
 * Update tags of an archived video file
 * @param {string} videoId 
 * @param {number} from 
 * @param {number} duration 
 * @param {string|[string]} tagId 
 */
 function updateTagsOfArchivedVideoFile(videoId, from, duration, tagIds) {
	return makeRequest({
		method: 'put',
		path: `/vod-tags/archive-file/${videoId}`,
		data: {tags: tagIds, from, duration}
	})
}


/**
 * Search for video files by name and tags
 * @param {string} searchText 
 * @param {[string]} tagIds 
 */
 function searchForVideoFiles(searchText, tagIds) {
	
	return makeRequest({
		method: 'get',
		path: `/streams/vod/search`,
		params: {
			search: searchText,
			tags: tagIds
		}
	})
}

/**
 */
 function getSubAccounts() {
	return makeRequest({
		path: '/streams/sub_accounts',
		method: 'get',
	});
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeRequest(reqConfig, preventProxy, throwReal) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		};
	}

	if (preventProxy !== true) {
		reqConfig = amProxyUrl(reqConfig, process.env.VUE_APP_VOD_API_BASE_URL);
	}

	reqConfig.url = reqConfig.path;

	let res;
	try {
		res = await api.request(reqConfig);
	} catch (err) {
		
		if(throwReal) {
			throw err;
		}
		const edata = _.get(err, 'response.data');
		throw new RequestError(edata);
	}

	return res && res.data;
}

/**
 * @param {RequestConfig|string} reqConfig
 */
async function makeVODApiRequest(reqConfig) {
	if (typeof reqConfig === 'string') {
		reqConfig = {
			path: reqConfig
		};
	}

	reqConfig.url = `${process.env.VUE_APP_VOD_API_BASE_URL}${reqConfig.path}`;

	let res;
	try {
		res = await api.request(reqConfig);
	} catch (err) {
		const edata = _.get(err, 'response.data');
		throw new RequestError(edata);
	}

	return res && res.data;
}
